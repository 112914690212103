import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { useResponsibleGamblingTimerTime } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingTimerTime',
    props: {
        value: {
            default: '00'
        },
        isMinutes: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { isActive, label } = useResponsibleGamblingTimerTime(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['responsible-gambling-timer-time']]: true,
                    [_ctx.$style['responsible-gambling-timer-time--active']]: _unref(isActive)
                })
            }, [
                _createTextVNode(_toDisplayString(_ctx.value) + " ", 1),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-timer-time__label'])
                }, _toDisplayString(_unref(label)), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingTimerTime'
                ]
            ]);
        };
    }
});
