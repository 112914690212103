import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { VIcon } from '@components/v-icon';
import { ResponsibleGamblingQuizResultsKind } from '../../enums';
import { useResponsibleGamblingQuizResult } from './composables';
import ResponsibleGamblingQuizProgress from '../ResponsibleGamblingQuizProgress/ResponsibleGamblingQuizProgress.vue';
import { ResponsibleGamblingQuizResultCategory } from './types';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingQuizResult',
    props: {
        category: {
            default: ResponsibleGamblingQuizResultCategory.TIME
        },
        kind: {
            default: ResponsibleGamblingQuizResultsKind.DEFAULT
        },
        of: {
            default: 0
        },
        value: {
            default: 0
        }
    },
    setup (__props) {
        const props = __props;
        const { iconProps, progressProps, title, description } = useResponsibleGamblingQuizResult(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['responsible-gambling-quiz-result'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-quiz-result__icon'])
                }, [
                    _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(iconProps))), null, 16)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-quiz-result__content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['responsible-gambling-quiz-result__title'])
                    }, _toDisplayString(_unref(title)), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['responsible-gambling-quiz-result__description'])
                    }, _toDisplayString(_unref(description)), 3),
                    _createVNode(ResponsibleGamblingQuizProgress, _normalizeProps(_guardReactiveProps(_unref(progressProps))), null, 16)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingQuizResult'
                ]
            ]);
        };
    }
});
