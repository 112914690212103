import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
import ResponsibleGamblingTimeInGameInitialStep from 'web/src/modules/profile/submodules/responsible-gambling/steps/ResponsibleGamblingTimeInGameInitialStep/ResponsibleGamblingTimeInGameInitialStep.vue';
import ResponsibleGamblingTimeInGameTimerStep from 'web/src/modules/profile/submodules/responsible-gambling/steps/ResponsibleGamblingTimeInGameTimerStep/ResponsibleGamblingTimeInGameTimerStep.vue';
import { useResponsibleGamblingTimeInGameRoutePage } from 'web/src/modules/profile/submodules/responsible-gambling/pages/ResponsibleGamblingTimeInGameRoutePage/composables';
import { InGameStepEnum } from 'web/src/modules/profile/submodules/responsible-gambling/store/enums';
import VPreloader from 'web/src/components/Preloader/VPreloader/VPreloader.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingTimeInGameRoutePage',
    setup (__props) {
        const { step, timerData, showTimerSet, resetTimer, handleChangeTimer, hideTimer, timeInGameLoadingState } = useResponsibleGamblingTimeInGameRoutePage();
        return (_ctx, _cache)=>_unref(timeInGameLoadingState) ? (_openBlock(), _createBlock(VPreloader, {
                key: 0
            })) : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style['time-in-game'])
            }, [
                _withDirectives(_createVNode(ResponsibleGamblingTimeInGameInitialStep, {
                    "timer-data": _unref(timerData),
                    onSet: _unref(showTimerSet),
                    onReset: _unref(resetTimer)
                }, null, 8, [
                    "timer-data",
                    "onSet",
                    "onReset"
                ]), [
                    [
                        _vShow,
                        _unref(step) === _unref(InGameStepEnum).INITIAL
                    ]
                ]),
                _unref(step) === _unref(InGameStepEnum).TIMER ? (_openBlock(), _createBlock(ResponsibleGamblingTimeInGameTimerStep, {
                    key: 0,
                    "timer-data": _unref(timerData),
                    onSet: _unref(handleChangeTimer),
                    onClose: _unref(hideTimer)
                }, null, 8, [
                    "timer-data",
                    "onSet",
                    "onClose"
                ])) : _createCommentVNode("", true)
            ], 2));
    }
});
