import { defineComponent as _defineComponent } from 'vue';
import { resolveDynamicComponent as _resolveDynamicComponent, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { computed, onBeforeMount, onBeforeUnmount } from 'vue';
import { CustomerVerificationType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config';
import CustomerVerificationSumSub from 'web/src/modules/profile/submodules/verification/components/SumSub/CustomerVerificationSumSub.vue';
import CustomerVerificationVideo from 'web/src/modules/profile/submodules/verification/components/Video/CustomerVerificationVideo.vue';
import CustomerVerificationInternal from 'web/src/modules/profile/submodules/verification/components/Internal/CustomerVerificationInternal.vue';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import CustomerVerificationDefault from 'web/src/modules/profile/submodules/verification/components/Default/CustomerVerificationDefault.vue';
import { VerificationPageType } from 'web/src/modules/profile/submodules/verification/enums';
import { useVerificationPage } from '../composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VerificationRoutePage',
    setup (__props) {
        const { beforeComponentMount, beforeComponentUnmount, onStartVerification, forcedVerificationType, isVideoVerificationEnabled, isSumSubComponent, isCustomerVerificationAsDefaultAvailable, startedVerification, isEkycRequestable } = useVerificationPage();
        const componentName = computed(()=>{
            if (startedVerification.value) switch(startedVerification.value){
                case VerificationPageType.DEFAULT:
                    return CustomerVerificationDefault;
                case VerificationPageType.EXTERNAL:
                    return CustomerVerificationSumSub;
                case VerificationPageType.INTERNAL:
                    return CustomerVerificationInternal;
                case VerificationPageType.VIDEO:
                    return CustomerVerificationVideo;
                default:
            }
            // do nothing
            if (forcedVerificationType.value === CustomerVerificationType.CUSTOMER_VERIFICATION_SERVICE) return CustomerVerificationSumSub;
            if (startedVerification.value && isEkycRequestable.value) return CustomerVerificationSumSub;
            if (isVideoVerificationEnabled.value) return CustomerVerificationVideo;
            if (isSumSubComponent.value) return CustomerVerificationSumSub;
            if (isCustomerVerificationAsDefaultAvailable.value) return CustomerVerificationDefault;
            return CustomerVerificationInternal;
        });
        onBeforeMount(beforeComponentMount);
        onBeforeUnmount(beforeComponentUnmount);
        useKeepAlive([
            RouteName.CMS_VERIFICATION_REQUIREMENTS
        ]);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(componentName.value), {
                onStartVerification: _unref(onStartVerification)
            }, null, 40, [
                "onStartVerification"
            ])), [
                [
                    _directive_auto_id,
                    'VerificationRoutePage'
                ]
            ]);
        };
    }
});
