import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { logger } from '@leon-hub/logging';
import { getCustomerVerifyData } from '@leon-hub/api-sdk';
import { isError, isString } from '@leon-hub/guards';
import useVerificationApi from 'web/src/modules/verification/services/api/useVerificationApi';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const useUploadDocumentsStore = defineStore('upload-documents', ()=>{
    const apiClient = useGraphqlClient();
    const { uploadDocument } = useVerificationApi();
    const result = ref([]);
    const verifyData = ref({
        documentReasons: [],
        supportedFormats: []
    });
    const maxFilesNumber = computed(()=>{
        if (verifyData.value.maxFilesNumber) return verifyData.value.maxFilesNumber.toString();
        return '';
    });
    const verifyReasons = computed(()=>verifyData.value.documentReasons.filter((reason)=>!!reason));
    const maxFileSizeByExtension = computed(()=>{
        if (verifyData.value.supportedFormats && verifyData.value.maxExtensionSize) {
            const map = new Map();
            const items = verifyData.value.maxExtensionSize.replace(/["{}]/g, '').split(',').map((element)=>element.split(':'));
            for (const item of items)map.set(item[0], item[1]);
            const defaultSizeValue = map.get('*');
            return verifyData.value.supportedFormats.map((item)=>{
                isString(item);
                if (map.has(item)) {
                    const value = map.get(item);
                    return {
                        name: item,
                        value
                    };
                }
                return {
                    name: item,
                    value: defaultSizeValue
                };
            });
        }
        return [];
    });
    async function uploadDocuments(param) {
        let { files, reason, comment } = param;
        const responses = [];
        for(let index = 0, { length } = files; index < length; index += 1){
            const file = files[index];
            try {
                // eslint-disable-next-line no-await-in-loop
                const response = await uploadDocument({
                    file,
                    reason,
                    comment
                });
                responses.push(response);
            } catch (error) {
                isError(error);
                logger.error('Failed to upload document', error);
                responses.push({
                    result: 'FAIL',
                    errorStatus: 'FETCH_ERROR',
                    msg: error.message
                });
            }
        }
        result.value = responses;
    }
    async function fetchVerifyData() {
        const response = await getCustomerVerifyData(apiClient, (node)=>node.queries.customer.getDocVerifyData, {});
        if (response.verifyData?.documentReasons) verifyData.value = response.verifyData;
    }
    function clearResultData() {
        result.value = [];
    }
    return {
        result,
        verifyData,
        maxFilesNumber,
        maxFileSizeByExtension,
        verifyReasons,
        fetchVerifyData,
        uploadDocuments,
        clearResultData
    };
});
export default useUploadDocumentsStore;
