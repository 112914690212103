import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import useResponsibleGamblingTestRoutePage from './composables/useResponsibleGamblingTestRoutePage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingTestRoutePage',
    setup (__props) {
        const { onClick } = useResponsibleGamblingTestRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['responsible-gambling-test'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-test__image'])
                }, [
                    _createElementVNode("img", {
                        src: require("web/src/modules/profile/submodules/responsible-gambling/images/li/test-bg.svg"),
                        alt: "",
                        role: "presentation"
                    }, null, 8, _hoisted_1)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-test__info-description'])
                }, [
                    _createVNode(VCmsContent, {
                        "cms-key": "WEB2_RESPONSIBLE_GAMBLING_TEST_INFO_PAGE",
                        silent: ""
                    })
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-test__button-container'])
                }, [
                    _createVNode(VButton, {
                        label: _ctx.$t('WEB2_RESPONSIBLE_GAMBLING_TAKE_TEST'),
                        "full-width": "",
                        onClick: _unref(onClick)
                    }, null, 8, [
                        "label",
                        "onClick"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingTestRoutePage'
                ]
            ]);
        };
    }
});
