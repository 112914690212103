import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef, defineAsyncComponent, useTemplateRef } from 'vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { LazyDefaultModal } from 'web/src/components/Modal/DefaultModal';
import ConfirmEmail from 'web/src/modules/verify-email/components/ConfirmEmail';
import { ModalSelector } from 'web/src/modules/core/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { VEmpty } from 'web/src/components/Empty';
import { ConfirmEmailStage } from 'web/src/modules/verify-email/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerSubscriptionsConfirmEmailModal',
    props: {
        showConfirmEmailModal: {
            type: Boolean
        },
        confirmEmailStage: {},
        email: {},
        sendingConfirmEmailLink: {
            type: Boolean
        },
        confirmDisabled: {
            type: Boolean
        },
        isSeparateModal: {
            type: Boolean
        }
    },
    emits: [
        "on-confirm-email-close",
        "on-confirm-email"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const SetEmailForm = defineAsyncComponent(()=>import('../SetEmailForm/SetEmailForm.vue'));
        // eslint-disable-next-line max-len
        const modalSelector = props.isSeparateModal ? ModalSelector.BODY : ModalSelector.DESKTOP_INNER_MODAL;
        let modalWith = ModalWidth.DEFAULT;
        "1";
        modalWith = ModalWidth.SMALL;
        const modalProps = toRef(()=>({
                width: props.isSeparateModal ? ModalWidth.MEDIUM_PLUS : modalWith,
                isCentered: false,
                isAlert: props.isSeparateModal,
                isOverlayCloseAllowed: false,
                isInnerModal: !props.isSeparateModal,
                isProfileLayout: true,
                isPaddingTopBig: props.isSeparateModal,
                isFullHeight: false,
                isFullHeightCentered: false
            }));
        const submitForm = useTemplateRef('submitForm');
        function onConfirmEmail() {
            emit('on-confirm-email');
            submitForm.value?.submit();
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                selector: _unref(modalSelector)
            }, {
                default: _withCtx(()=>[
                        _ctx.showConfirmEmailModal ? (_openBlock(), _createBlock(_unref(LazyDefaultModal), _mergeProps({
                            key: 0
                        }, modalProps.value, {
                            "data-test-id": "customer-subscriptions-confirm-email",
                            onClose: _cache[1] || (_cache[1] = ($event)=>emit('on-confirm-email-close'))
                        }), {
                            "inner-content": _withCtx(()=>[
                                    _createVNode(_unref(ConfirmEmail), {
                                        stage: _ctx.confirmEmailStage,
                                        email: _ctx.email,
                                        "is-pending": _ctx.sendingConfirmEmailLink,
                                        "confirm-disabled": _ctx.confirmDisabled,
                                        "is-logged-in": "",
                                        onClose: _cache[0] || (_cache[0] = ($event)=>emit('on-confirm-email-close')),
                                        onConfirm: onConfirmEmail
                                    }, _createSlots({
                                        _: 2
                                    }, [
                                        {
                                            name: "email-form",
                                            fn: _withCtx(()=>[
                                                    _createVNode(_unref(SetEmailForm), {
                                                        ref_key: "submitForm",
                                                        ref: submitForm
                                                    }, null, 512)
                                                ]),
                                            key: "0"
                                        }
                                    ]), 1032, [
                                        "stage",
                                        "email",
                                        "is-pending",
                                        "confirm-disabled"
                                    ])
                                ]),
                            _: 1
                        }, 16)) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "selector"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerSubscriptionsConfirmEmailModal'
                ]
            ]);
        };
    }
});
