import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "profile-sidebar-user"
};
import { ref, computed, toRef } from 'vue';
import { assert } from '@leon-hub/guards';
import ProfileInfo from 'web/src/modules/profile/components/ProfileInfo/ProfileInfo.vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { getEgsLoyaltyStore } from 'web/src/modules/core/components/AuthorizedAppController/composables/utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SidebarProfileInfoRouteComponent',
    setup (__props) {
        const siteConfigStore = useSiteConfigStore();
        let imageUrl = ref(void 0);
        let isEgsLoyaltyEnabled = ref(false);
        "1";
        {
            const egsLoyaltyStore = getEgsLoyaltyStore();
            const currentLevelData = toRef(egsLoyaltyStore, 'currentLevelData');
            imageUrl = computed(()=>currentLevelData.value?.imageUrl);
            isEgsLoyaltyEnabled = toRef(siteConfigStore, 'isEgsLoyaltyEnabled');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(ProfileInfo, {
                    "is-loyalty-enabled": _unref(isEgsLoyaltyEnabled),
                    "image-src": _unref(imageUrl)
                }, null, 8, [
                    "is-loyalty-enabled",
                    "image-src"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'SidebarProfileInfoRouteComponent'
                ]
            ]);
        };
    }
});
