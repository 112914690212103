import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ButtonHeight } from '@leon-hub/module-buttons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesCardButtonController',
    props: {
        buttons: {
            default: ()=>[]
        },
        hasColumns: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "profile-bonuses-card-buttons",
                    {
                        'profile-bonuses-card-buttons--has-columns': _ctx.hasColumns
                    }
                ])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, index)=>(_openBlock(), _createBlock(VButton, _mergeProps({
                        ref_for: true
                    }, button, {
                        key: `${index}`,
                        height: void 0,
                        onClick: ($event)=>emit('click', button.id)
                    }), null, 16, [
                        "height",
                        "onClick"
                    ]))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ProfileBonusesCardButtonController'
                ]
            ]);
        };
    }
});
