import { onMounted, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { getLocationOrigin } from '@leon-hub/service-locator-env';
import { CustomerVerificationStatus } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { IconName } from '@leon-hub/icons';
import isGeneralApiError from '@leon-hub/api/src/client/errors/isGeneralApiError';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useI18n, useI18nLocale } from 'web/src/modules/i18n/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import useVerificationStore from 'web/src/modules/verification/store/useVerificationStore';
export default function useCustomerVerificationSumSub(param) {
    let { initialTokenData } = param;
    const customerData = useCustomerDataStore();
    const router = useRouter();
    const { showDialog } = useDialogs();
    const { isLoggedIn } = useIsLoggedIn();
    const { $translate } = useI18n();
    const siteConfigStore = useSiteConfigStore();
    const verificationStore = useVerificationStore();
    const { lang } = useI18nLocale();
    const verificationStatus = toRef(customerData, 'verificationStatus');
    const isVerificationPurseCheck = toRef(customerData, 'isVerificationPurseCheck');
    const isEkycRequestable = toRef(customerData, 'isEkycRequestable');
    const email = toRef(customerData, 'email');
    const isSumSubWebSdkInProdMode = toRef(siteConfigStore, 'isSumSubWebSdkInProdMode');
    const sumSubUrl = toRef(siteConfigStore, 'sumSubUrl');
    const containerId = 'sumsub-websdk-container';
    onMounted(()=>{
        if (verificationStatus.value === CustomerVerificationStatus.FAILED) {
            router.back();
            const title = isVerificationPurseCheck.value ? $translate('WEB2_PURSE_CHECK_VERIFY_FAILED_TITLE').value : $translate('WEB2_VERIFICATION_VERIFY_FAILED_TITLE').value;
            const confirmMessage = isVerificationPurseCheck.value ? $translate('WEB2_PURSE_CHECK_VERIFY_FAILED_DESC').value : $translate('WEB2_VERIFICATION_VERIFY_FAILED_DESC').value;
            const buttonLabel = isVerificationPurseCheck.value ? $translate('WEB2_PURSE_CHECK_VERIFY_FAILED_BTN').value : $translate('WEB2_VERIFICATION_VERIFY_FAILED_BTN').value;
            showDialog({
                presetName: PresetName.CONFIRM,
                options: {
                    title,
                    confirmMessage,
                    width: ModalWidth.SMALL,
                    buttons: [
                        {
                            action: DialogAction.ADDITIONAL_BUTTON,
                            label: buttonLabel
                        },
                        {
                            action: DialogAction.MODAL_CLOSE,
                            label: $translate('JSP_PCL_FBOT_CLOSE').value
                        }
                    ],
                    dataTestId: 'verification-failed'
                }
            }).subscribe({
                [DialogAction.ADDITIONAL_BUTTON]: ()=>{
                    router.push({
                        name: RouteName.SUPPORT
                    });
                }
            });
            return;
        }
        if (isLoggedIn.value) launchSdk();
    });
    function showError(message) {
        showDialog({
            presetName: PresetName.ALERT_WARNING,
            options: {
                confirmMessage: message,
                buttons: [
                    {
                        kind: ButtonKind.BASE
                    }
                ],
                dataTestId: 'verification-error'
            }
        }).subscribe({
            [DialogAction.MODAL_CLOSE]: ()=>{
                router.back();
            }
        });
    }
    async function newAccessToken() {
        let isFirstCall = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        try {
            let tokenResult;
            tokenResult = isFirstCall && initialTokenData.value?.token ? initialTokenData.value : await verificationStore.getAccessToken(isEkycRequestable.value);
            if (isFirstCall && tokenResult.purseMask) {
                const purseMask = ref({
                    mask: tokenResult.purseMask
                });
                showDialog({
                    presetName: PresetName.ALERT_SUCCESS,
                    options: {
                        title: '',
                        iconName: IconName.ATTENTION_OUTLINE,
                        iconKind: JumbotronIconKind.DEFAULT,
                        confirmMessage: $translate('WEB2_VERIFY_PURSE_CHECK_SHOW_MASK', purseMask).value,
                        buttons: [
                            {
                                kind: ButtonKind.BASE
                            }
                        ],
                        dataTestId: 'verify-purse-check'
                    }
                });
            }
            return tokenResult.token;
        } catch (error) {
            if (isGeneralApiError(error)) throw error;
            if (error instanceof Error) showError(error.message);
        }
        return '';
    }
    async function launchSdk() {
        const accessToken = await newAccessToken(true);
        if (!accessToken) return;
        const snsWebSdk = (await import('@sumsub/websdk')).default;
        const snsWebSdkBuilder = snsWebSdk.init(accessToken, newAccessToken).withConf({
            lang: lang.value.toLowerCase(),
            email: email.value,
            uiConf: {
                customCss: `${getLocationOrigin()}/styles/sumsub.css?v=1.0.2`
            }
        });
        if (sumSubUrl.value) snsWebSdkBuilder.withBaseUrl(sumSubUrl.value);
        if (!isSumSubWebSdkInProdMode.value) snsWebSdkBuilder.onTestEnv();
        snsWebSdkBuilder.build().launch(`#${containerId}`);
    }
    return {
        containerId
    };
}
