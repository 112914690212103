import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "bonus-card-description-custom"
};
const _hoisted_2 = {
    class: "bonus-card-description-custom__title"
};
const _hoisted_3 = {
    class: "bonus-card-description-custom__text"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesCardDescriptionCustom',
    props: {
        descriptionTitle: {
            default: ''
        },
        descriptionText: {
            default: ''
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.descriptionTitle), 1),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.descriptionText), 1)
            ])), [
                [
                    _directive_auto_id,
                    'ProfileBonusesCardDescriptionCustom'
                ]
            ]);
        };
    }
});
