import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "alt",
    "src"
];
const _hoisted_2 = [
    "alt",
    "src"
];
const _hoisted_3 = [
    "alt",
    "src"
];
const _hoisted_4 = [
    "alt",
    "src"
];
const _hoisted_5 = [
    "alt",
    "src"
];
const _hoisted_6 = [
    "alt",
    "src"
];
const _hoisted_7 = [
    "alt",
    "src"
];
const _hoisted_8 = [
    "alt",
    "src"
];
const _hoisted_9 = [
    "alt",
    "src"
];
const _hoisted_10 = [
    "alt",
    "src"
];
const _hoisted_11 = [
    "alt",
    "src"
];
const _hoisted_12 = [
    "alt",
    "src"
];
const _hoisted_13 = [
    "alt",
    "src"
];
const _hoisted_14 = [
    "alt",
    "src"
];
const _hoisted_15 = [
    "alt",
    "src"
];
const _hoisted_16 = [
    "alt",
    "src"
];
const _hoisted_17 = [
    "src"
];
const _hoisted_18 = [
    "src"
];
const _hoisted_19 = [
    "src"
];
const _hoisted_20 = [
    "src"
];
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useCustomerHistoryDetailsCardStatistics } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryDetailsCardStatistics',
    props: {
        matchResult: {}
    },
    setup (__props) {
        const { isOpen, folder, toggleStatistics } = useCustomerHistoryDetailsCardStatistics();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['card-statistics']),
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(toggleStatistics) && _unref(toggleStatistics)(...args);
                })
            }, [
                _unref(isOpen) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['card-statistics__mask'])
                }, null, 2)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['card-statistics__wrapper'])
                }, [
                    _unref(isOpen) ? (_openBlock(), _createBlock(_unref(VIcon), {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['card-statistics__close']),
                        size: _unref(IconSize).SIZE_24
                    }, null, 8, [
                        "class",
                        "size"
                    ])) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['card-statistics__default'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['card-statistics__head'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['card-statistics__competitors'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['card-statistics__home'])
                                }, [
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_CORNERS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/corner_kicks.svg')
                                        }, null, 10, _hoisted_1),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.cornersScore?.home || ''), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_YELLOW_CARDS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/yellow_card.svg')
                                        }, null, 10, _hoisted_2),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.yellowCards?.home || ''), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_RED_CARDS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/red_card.svg')
                                        }, null, 10, _hoisted_3),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.redCards?.home || ''), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_YELLOW_RED_CARDS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/2_cards.svg')
                                        }, null, 10, _hoisted_4),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.yellowRedCards?.home || ''), 3)
                                    ], 2)
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['card-statistics__away'])
                                }, [
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_CORNERS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/corner_kicks.svg')
                                        }, null, 10, _hoisted_5),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.cornersScore?.away || ''), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_YELLOW_CARDS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/yellow_card.svg')
                                        }, null, 10, _hoisted_6),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.yellowCards?.away || ''), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_RED_CARDS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/red_card.svg')
                                        }, null, 10, _hoisted_7),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.redCards?.away || ''), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_YELLOW_RED_CARDS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/2_cards.svg')
                                        }, null, 10, _hoisted_8),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.yellowRedCards?.away || ''), 3)
                                    ], 2)
                                ], 2)
                            ], 2)
                        ], 2)
                    ], 2),
                    _unref(isOpen) ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['card-statistics__absolute'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['card-statistics__head'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['card-statistics__competitors'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['card-statistics__home'])
                                }, [
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_CORNERS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/corner_kicks.svg')
                                        }, null, 10, _hoisted_9),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.cornersScore?.home || ''), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_YELLOW_CARDS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/yellow_card.svg')
                                        }, null, 10, _hoisted_10),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.yellowCards?.home || ''), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_RED_CARDS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/red_card.svg')
                                        }, null, 10, _hoisted_11),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.redCards?.home || ''), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_YELLOW_RED_CARDS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/2_cards.svg')
                                        }, null, 10, _hoisted_12),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.yellowRedCards?.home || ''), 3)
                                    ], 2)
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['card-statistics__away'])
                                }, [
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_CORNERS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/corner_kicks.svg')
                                        }, null, 10, _hoisted_13),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.cornersScore?.away || ''), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_YELLOW_CARDS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/yellow_card.svg')
                                        }, null, 10, _hoisted_14),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.yellowCards?.away || ''), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_RED_CARDS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/red_card.svg')
                                        }, null, 10, _hoisted_15),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.redCards?.away || ''), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['card-statistics__column'])
                                    }, [
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__icon']),
                                            alt: _ctx.$t('WEB2_YELLOW_RED_CARDS'),
                                            src: require('web/src/assets/football-statistics/' + _unref(folder) + '/2_cards.svg')
                                        }, null, 10, _hoisted_16),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['card-statistics__number'])
                                        }, _toDisplayString(_ctx.matchResult.yellowRedCards?.away || ''), 3)
                                    ], 2)
                                ], 2)
                            ], 2)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['card-statistics__body'])
                        }, [
                            _createElementVNode("h2", {
                                class: _normalizeClass(_ctx.$style['card-statistics__title'])
                            }, _toDisplayString(_ctx.$t('WEB2_MATCH_STATISTICS')), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['card-statistics__description'])
                            }, [
                                _createElementVNode("img", {
                                    class: _normalizeClass({
                                        [_ctx.$style['card-statistics__icon']]: true,
                                        [_ctx.$style['card-statistics__icon--big']]: true
                                    }),
                                    alt: "icon",
                                    src: require('web/src/assets/football-statistics/' + _unref(folder) + '/corner_kicks.svg')
                                }, null, 10, _hoisted_17),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['card-statistics__label'])
                                }, _toDisplayString(_ctx.$t('WEB2_CORNERS')), 3)
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['card-statistics__description'])
                            }, [
                                _createElementVNode("img", {
                                    class: _normalizeClass({
                                        [_ctx.$style['card-statistics__icon']]: true,
                                        [_ctx.$style['card-statistics__icon--big']]: true
                                    }),
                                    alt: "icon",
                                    src: require('web/src/assets/football-statistics/' + _unref(folder) + '/yellow_card.svg')
                                }, null, 10, _hoisted_18),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['card-statistics__label'])
                                }, _toDisplayString(_ctx.$t('WEB2_YELLOW_CARDS')), 3)
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['card-statistics__description'])
                            }, [
                                _createElementVNode("img", {
                                    class: _normalizeClass({
                                        [_ctx.$style['card-statistics__icon']]: true,
                                        [_ctx.$style['card-statistics__icon--big']]: true
                                    }),
                                    alt: "icon",
                                    src: require('web/src/assets/football-statistics/' + _unref(folder) + '/red_card.svg')
                                }, null, 10, _hoisted_19),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['card-statistics__label'])
                                }, _toDisplayString(_ctx.$t('WEB2_RED_CARDS')), 3)
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['card-statistics__description'])
                            }, [
                                _createElementVNode("img", {
                                    class: _normalizeClass({
                                        [_ctx.$style['card-statistics__icon']]: true,
                                        [_ctx.$style['card-statistics__icon--big']]: true
                                    }),
                                    alt: "icon",
                                    src: require('web/src/assets/football-statistics/' + _unref(folder) + '/2_cards.svg')
                                }, null, 10, _hoisted_20),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['card-statistics__label'])
                                }, _toDisplayString(_ctx.$t('WEB2_YELLOW_RED_CARDS')), 3)
                            ], 2)
                        ], 2)
                    ], 2)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerHistoryDetailsCardStatistics'
                ]
            ]);
        };
    }
});
