import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList } from "vue";
import { onBeforeMount } from 'vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useResponsibleGamblingOptionsRoutePage } from './composables';
import ResponsibleGamblingDialogBox from '../../components/ResponsibleGamblingDialogBox/ResponsibleGamblingDialogBox.vue';
import ResponsibleGamblingOption from '../../components/ResponsibleGamblingOption/ResponsibleGamblingOption.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingOptionsRoutePage',
    setup (__props) {
        const { fullName, onButtonClick, beforeMount, responsibleGamblingItems } = useResponsibleGamblingOptionsRoutePage();
        onBeforeMount(beforeMount);
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['options-page'])
                }, [
                    (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['options-page__title'])
                        }, _toDisplayString(_ctx.$t('WEB2_RESPONSIBLE_GAMING_OPTIONS_MAIN_TITLE')), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['options-page__description'])
                        }, _toDisplayString(_ctx.$t('WEB2_RESPONSIBLE_GAMING_OPTIONS_MAIN_DESCRIPTION')), 3)
                    ], 64)),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['options-page__list'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(responsibleGamblingItems), (item)=>(_openBlock(), _createBlock(ResponsibleGamblingOption, {
                                key: item.id,
                                type: String(item.props?.type || ''),
                                "route-name": item.routeName
                            }, null, 8, [
                                "type",
                                "route-name"
                            ]))), 128))
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['options-page__button-container'])
                }, [
                    (_openBlock(), _createBlock(LButton, {
                        key: 1,
                        kind: _unref(ButtonKind).BASE,
                        label: _ctx.$t('WEB2_RESPONSIBLE_GAMING_OPTIONS_BACK_TO_GAME'),
                        "full-width": "",
                        class: _normalizeClass(_ctx.$style['options-page__button']),
                        onClick: _unref(onButtonClick)
                    }, null, 8, [
                        "kind",
                        "label",
                        "class",
                        "onClick"
                    ]))
                ], 2)
            ], 64));
    }
});
