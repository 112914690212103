import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "bonus-complex-content"
};
import { onBeforeMount } from 'vue';
import ProfileBonusesComplexController from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesComplexController/ProfileBonusesComplexController.vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useProfileBonusesComplexComponent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesComplexComponent',
    props: {
        bonusId: {},
        bonusCampaignType: {},
        campaignId: {}
    },
    setup (__props) {
        const props = __props;
        const { list, buttonProps, isSubmitButtonVisible, beforeMount, getBonusData, handleChange, handleSubmit } = useProfileBonusesComplexComponent(props);
        onBeforeMount(beforeMount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (item, index)=>(_openBlock(), _createBlock(ProfileBonusesComplexController, _mergeProps({
                        ref_for: true
                    }, _unref(getBonusData)(item), {
                        key: index,
                        index: index,
                        onClick: _unref(handleChange)
                    }), null, 16, [
                        "index",
                        "onClick"
                    ]))), 128)),
                _unref(isSubmitButtonVisible) ? (_openBlock(), _createBlock(VButton, _mergeProps({
                    key: 0
                }, _unref(buttonProps), {
                    onClick: _unref(handleSubmit)
                }), null, 16, [
                    "onClick"
                ])) : _createCommentVNode("", true),
                _createVNode(VHintBlock, {
                    "has-icon": false
                }, {
                    default: _withCtx(()=>[
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_BONUSES_COMPLEX_DESCRIPTION')), 1)
                        ]),
                    _: 1
                })
            ])), [
                [
                    _directive_auto_id,
                    'ProfileBonusesComplexComponent'
                ]
            ]);
        };
    }
});
