import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "personal"
};
import VAvatar from 'web/src/components/Avatar/VAvatar/VAvatar.vue';
import { VForm } from 'web/src/components/Form';
import { AvatarSize } from 'web/src/components/Avatar/VAvatar/enums';
import { VLoaderDelayed } from 'web/src/components/Loader';
import PersonalInfo from 'web/src/modules/profile/components/PersonalInfo/PersonalInfo.vue';
import SDescriptionBlock from 'web/src/modules/profile/components/SDescriptionBlock/SDescriptionBlock.vue';
import { getDefaultAvatarImage } from 'web/src/components/Avatar/utils';
import { CasinoLoyaltyProgramIconSizes } from 'web/src/modules/casino-loyalty-program/store/types';
import AvatarImage from 'web/src/modules/profile/submodules/achievements/components/AvatarImage/AvatarImage.vue';
import usePersonalDataPage from './composables/usePersonalDataPage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PersonalDataRoutePage',
    setup (__props) {
        AvatarSize.SIZE_68;
        const { isFormLoaded, profileSchema, customErrors, isSubmitPending, profile, isAvatarsEnabled, onSubmit } = usePersonalDataPage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createCommentVNode("", true),
                _createCommentVNode("", true),
                _unref(isFormLoaded) && !!_unref(profileSchema) ? (_openBlock(), _createBlock(_unref(VForm), _mergeProps({
                    key: 2
                }, _unref(profileSchema), {
                    class: "personal-form--disabled",
                    "custom-errors": _unref(customErrors),
                    "is-pending": _unref(isSubmitPending),
                    "focus-on-error": "",
                    onSubmit: _unref(onSubmit)
                }), null, 16, [
                    "custom-errors",
                    "is-pending",
                    "onSubmit"
                ])) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 3
                }))
            ])), [
                [
                    _directive_auto_id,
                    'PersonalDataRoutePage'
                ]
            ]);
        };
    }
});
