import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { useRouter } from 'vue-router';
import { ButtonKind } from '@leon-hub/module-buttons';
import RouteName from '@leon-hub/routing-config-names';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingExclusionInfoRoutePage',
    setup (__props) {
        const router = useRouter();
        function handleClose() {
            router.replace({
                name: RouteName.RESPONSIBLE_GAMBLING_EXCLUSION
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['rg-exclusion-info'])
            }, [
                _createElementVNode("img", {
                    class: "rg-exclusion-info__image",
                    src: require('web/src/modules/profile/submodules/responsible-gambling/pages/ResponsibleGamblingExclusionRoutePage/images/background.svg'),
                    alt: ""
                }, null, 8, _hoisted_1),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['rg-exclusion-info__content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['rg-exclusion-info__title'])
                    }, _toDisplayString(_ctx.$t('WEB2_RG_SELF_EXCLUSION_INFO_TITLE')), 3),
                    _createVNode(VCmsContent, {
                        class: _normalizeClass(_ctx.$style['rg-exclusion-info__description']),
                        "cms-key": "RG_SELF_EXCLUSION_INFO_DESCRIPTION",
                        silent: ""
                    }, null, 8, [
                        "class"
                    ]),
                    _createVNode(VButton, {
                        label: _ctx.$t('WEB2_RG_SELF_EXCLUSION_INFO_BUTTON'),
                        kind: _unref(ButtonKind).PRIMARY,
                        "full-width": "",
                        onClick: handleClose
                    }, null, 8, [
                        "label",
                        "kind"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingExclusionInfoRoutePage'
                ]
            ]);
        };
    }
});
