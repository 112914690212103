import getTermsLink from './getTermsLink';
export default function getLayoutProperties(props) {
    const { campaignName, stepNumber, stepCount } = props.bonus;
    return {
        stepNumber,
        stepCount,
        bonusTitle: campaignName,
        termsLink: getTermsLink(props)
    };
}
