import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { IconName, IconSize } from '@leon-hub/icons';
import { PinCodeRequiredExceptionCode, PinCodeState as PinCodeStateEnum } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config';
import { AnalyticsModalType, DialogAction, DialogComponent, PresetName } from 'web/src/modules/dialogs/enums';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useResponsibleGamblingStore } from 'web/src/modules/responsible-gambling/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import { FormControlType } from 'web/src/components/Form/enums';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { canNotSelfExcludeCode } from 'web/src/modules/responsible-gambling/constants';
import { useUserStore } from 'web/src/modules/user/store';
export default function useResponsibleGamblingExclusionRoutePage() {
    const siteConfigStore = useSiteConfigStore();
    const responsibleGamblingStore = useResponsibleGamblingStore();
    const I18nStore = useI18nStore();
    const pinCodeModule = usePinCodeStore();
    const userStore = useUserStore();
    const { $translate } = useI18n();
    const { showDialog, closeDialog } = useDialogs();
    const router = useRouter();
    const isLoading = ref(false);
    const isAllowedToDoSelfExclusion = ref(true);
    const isButtonDisabled = ref(true);
    const id = ref(null);
    const selectedMonths = ref(6);
    const periodLabels = ref({});
    const periods = toRef(siteConfigStore, 'selfExclusionOptions');
    const selfExclusion = toRef(responsibleGamblingStore, 'selfExclusion');
    const locale = toRef(I18nStore, 'locale');
    const periodTranslated = [];
    const isHasPinCode = computed(()=>!!pinCodeModule.status && pinCodeModule.status !== PinCodeStateEnum.RESET);
    const periodEnums = computed(()=>periods.value.reduce((accumulator, period)=>{
            if (period.months) accumulator.push(String(period.months));
            return accumulator;
        }, []));
    const isSinglePeriod = computed(()=>1 === periodEnums.value.length);
    const schema = computed(()=>({
            $schema: 'http://json-schema.org/draft-07/schema#',
            $id: 'http://example.com/schemas/registration-leon-ru.json',
            type: 'object',
            properties: {
                selectPeriod: {
                    type: 'string',
                    enum: periodEnums.value
                }
            }
        }));
    const uiSchema = computed(()=>({
            submitButton: null,
            fields: {
                selectPeriod: {
                    disabled: !!isSinglePeriod.value || void 0,
                    widget: FormControlType.Select,
                    labels: periodLabels.value,
                    title: $translate('WEB2_SELECT_EXCLUSION_PERIOD_PLACEHOLDER').value,
                    options: {
                        dropdownMinWidth: 280,
                        placeholder: $translate('WEB2_SELECT_EXCLUSION_PERIOD_PLACEHOLDER').value
                    },
                    default: isSinglePeriod.value ? periodEnums.value[0] : ''
                }
            }
        }));
    const periodLabelParameters = computed(()=>({
            count: String(selectedMonths.value)
        }));
    const periodLabel = computed(()=>LanguageMicroSettings.plural([
            $translate('WEB2_SELF_EXCLUSION_PERIOD_ONE', periodLabelParameters).value,
            $translate('WEB2_SELF_EXCLUSION_PERIOD_TWO', periodLabelParameters).value,
            $translate('WEB2_SELF_EXCLUSION_PERIOD_MANY', periodLabelParameters).value
        ], selectedMonths.value, locale.value));
    const formattedFrom = computed(()=>{
        if (null !== selfExclusion.value) return selfExclusion.value.from ? DateTime.formatTimeStamp(selfExclusion.value.from, TimeFormats.dayMonthYearHoursMinutes) : '';
        return '';
    });
    const formattedUntil = computed(()=>{
        if (null !== selfExclusion.value) return selfExclusion.value.until ? DateTime.formatTimeStamp(selfExclusion.value.until, TimeFormats.dayMonthYearHoursMinutes) : '';
        return '';
    });
    const confirmMessageParameters = computed(()=>({
            until: `${selectedMonths.value} ${periodLabel.value}`
        }));
    const confirmMessageSuccessParameters = computed(()=>({
            from: `<span class="self-exclusion__text--white">${formattedFrom.value}</span>`,
            until: `<span class="self-exclusion__text--white">${formattedUntil.value}</span>`
        }));
    async function beforeMount() {
        for (const item of periods.value){
            const newItem = {
                ...item,
                btrKey: $translate(item.btrKey).value
            };
            periodTranslated.push(newItem);
        }
        periodLabels.value = periodTranslated.reduce((accumulator, period)=>{
            if (period.months && period.btrKey) accumulator[String(period.months)] = `${period.months} ${period.btrKey}`;
            return accumulator;
        }, {});
        await responsibleGamblingStore.loadSelfExclusion();
        const { selfExclusionId, selfExclusionMonths } = responsibleGamblingStore;
        if (selfExclusionId && selfExclusionMonths) {
            id.value = selfExclusionId;
            selectedMonths.value = selfExclusionMonths;
            responsibleGamblingStore.setSelfExclusionId(null);
            responsibleGamblingStore.setSelfExclusionMonths(null);
            responsibleGamblingStore.setSelfExclusionRouteBack(null);
            await processSelfExclusion(id.value, selectedMonths.value);
        }
        if (isSinglePeriod.value) isButtonDisabled.value = false;
    }
    function onChange(data) {
        selectedMonths.value = Number(data.formData.selectPeriod);
        isButtonDisabled.value = false;
    }
    function processSelfExclusion(Id, months) {
        id.value = Id;
        isLoading.value = true;
        responsibleGamblingStore.setSelfExclusionRouteBack(RouteName.RESPONSIBLE_GAMBLING_EXCLUSION);
        return responsibleGamblingStore.doSelfExclusionFlex(months).then(async ()=>{
            id.value = null;
            await userStore.logout();
            showSuccessModal();
            closeDialog(Id);
        }).catch((error)=>{
            if (error.message === PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED) {
                responsibleGamblingStore.setSelfExclusionId(id.value);
                responsibleGamblingStore.setSelfExclusionMonths(months);
                closeDialog(id.value);
                router.replace({
                    name: RouteName.PIN_CODE_PROFILE
                });
            }
            if (error.message === canNotSelfExcludeCode) showSupportModal();
        }).finally(()=>{
            isLoading.value = false;
        });
    }
    function showConfirmModal() {
        const { subscribe, id: innerId } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                width: ModalWidth.SMALL,
                analyticsModalType: AnalyticsModalType.WARNING,
                confirmMessage: $translate('WEB2_EXCLUSION_CONFIRM', confirmMessageParameters).value,
                title: $translate('WEB2_EXCLUSION_CONFIRM_TITLE').value,
                iconSize: IconSize.SIZE_80,
                buttons: [
                    {
                        label: $translate('WEB2_EXCLUSION_CONFIRM_BUTTON').value,
                        kind: ButtonKind.PRIMARY
                    }
                ]
            }
        });
        subscribe({
            [DialogAction.CONFIRM]: ()=>{
                const isAllowedSelfExclusion = !!periods.value.find((item)=>item.months === selectedMonths.value)?.allowedSelfExclusion;
                if (!isAllowedSelfExclusion) {
                    showSupportModal();
                    return;
                }
                if (isHasPinCode.value) {
                    processSelfExclusion(innerId, selectedMonths.value);
                    isAllowedToDoSelfExclusion.value = false;
                } else showCountdownModal();
            },
            [DialogAction.MODAL_CLOSE]: ()=>{
                closeDialog(innerId);
            }
        });
    }
    function showCountdownModal() {
        const { id: innerId, subscribe } = showDialog({
            presetName: PresetName.ALERT_WARNING,
            options: {
                // 1 month restriction key: WEB2_FIN_DEPLIM_CONFIRM
                confirmMessage: $translate('WEB2_EXCLUSION_CONFIRM', confirmMessageParameters).value,
                title: $translate('WEB2_EXCLUSION_CONFIRM_TITLE').value,
                modalComponent: DialogComponent.CountDownModal,
                iconSize: IconSize.SIZE_60,
                buttons: [
                    {
                        label: $translate('JSPACC_SETT_CANCEL').value,
                        kind: ButtonKind.SECONDARY
                    }
                ]
            }
        });
        subscribe({
            [DialogAction.MODAL_CLOSE]: ()=>{
                closeDialog(innerId);
            },
            [DialogAction.MODAL_COUNTDOWN]: ()=>{
                processSelfExclusion(innerId, selectedMonths.value);
                isAllowedToDoSelfExclusion.value = false;
                return false;
            }
        });
    }
    function showSuccessModal() {
        const lButtonKind = ButtonKind.SECONDARY;
        showDialog({
            presetName: PresetName.ALERT_WARNING,
            options: {
                iconName: IconName.CHECK_OUTLINE,
                iconKind: JumbotronIconKind.SUCCESS,
                confirmMessage: $translate('WEB2_EXCLUSION_MODAL_SUCCESS_MESSAGE', confirmMessageSuccessParameters).value,
                title: $translate('WEB2_EXCLUSION_MODAL_SUCCESS_TITLE').value,
                iconSize: IconSize.SIZE_80,
                buttons: [
                    {
                        kind: lButtonKind
                    }
                ]
            }
        });
    }
    function handleSupportClick() {
        router.push({
            name: RouteName.SUPPORT
        });
    }
    function showSupportModal() {
        const { subscribe, id: innerId } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                width: ModalWidth.SMALL,
                confirmMessage: $translate('WEB2_SE_CAN_NOT_MODAL_TEXT').value,
                title: $translate('WEB2_SE_CAN_NOT_MODAL_TITLE').value,
                iconSize: IconSize.SIZE_96,
                buttons: [
                    {
                        action: DialogAction.CONFIRM,
                        iconName: IconName.SLOTT_SUPPORT,
                        label: $translate('WEB2_SE_CAN_NOT_MODAL_SUPPORT_BUTTON').value,
                        kind: ButtonKind.PRIMARY
                    },
                    {
                        label: $translate('WEB2_SE_CAN_NOT_MODAL_CANCEL_BUTTON').value,
                        kind: ButtonKind.TRANSPARENT
                    }
                ]
            }
        });
        subscribe({
            [DialogAction.CONFIRM]: ()=>{
                handleSupportClick();
            },
            [DialogAction.MODAL_CLOSE]: ()=>{
                closeDialog(innerId);
            }
        });
    }
    function onClickGoToGame() {
        router.push({
            name: RouteName.HOME
        });
    }
    return {
        selfExclusion,
        handleSupportClick,
        formattedFrom,
        formattedUntil,
        schema,
        uiSchema,
        isLoading,
        onChange,
        isButtonDisabled,
        showConfirmModal,
        beforeMount,
        onClickGoToGame
    };
}
