import { computed } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { getIconName, getCategoryTitle, getCategoryDescription } from './utils';
export default function useResponsibleGamblingQuizResult(props) {
    const iconName = computed(()=>getIconName(props.category));
    const title = computed(()=>getCategoryTitle(props.category));
    const description = computed(()=>getCategoryDescription(props.kind, props.category));
    const iconProps = computed(()=>({
            size: IconSize.SIZE_32,
            name: iconName.value
        }));
    const progressProps = computed(()=>({
            hasBottomLabel: true,
            kind: props.kind,
            noShadow: true,
            of: props.of,
            value: props.value
        }));
    return {
        description,
        iconProps,
        progressProps,
        title
    };
}
