import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    class: "bonus-card-description-column"
};
const _hoisted_2 = {
    class: "bonus-card-description-column__title"
};
const _hoisted_3 = {
    class: "bonus-card-description-column__value"
};
import { VIcon } from '@components/v-icon';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { useDataTestUnit } from 'web/src/testing/directives';
import { useProfileBonusesCardDescriptionColumn } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesCardDescriptionColumn',
    props: {
        title: {
            default: ''
        },
        value: {
            default: ''
        },
        badgeCount: {
            default: 0
        }
    },
    setup (__props) {
        const props = __props;
        const { vDataTestUnit } = useDataTestUnit();
        const { iconProps, badgeProps } = useProfileBonusesCardDescriptionColumn(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.value), 1),
                    _ctx.badgeCount > 1 ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _withDirectives(_createVNode(_unref(VIcon), _mergeProps(_unref(iconProps), {
                            class: "bonus-card-description-column__icon"
                        }), null, 16), [
                            [
                                _unref(vDataTestUnit),
                                {
                                    el: 'bonus-card-description-column-icon'
                                }
                            ]
                        ]),
                        _withDirectives(_createVNode(VBadge, _mergeProps(_unref(badgeProps), {
                            class: "bonus-card-description-column__badge"
                        }), null, 16), [
                            [
                                _unref(vDataTestUnit),
                                {
                                    el: 'bonus-card-description-column-badge'
                                }
                            ]
                        ])
                    ], 64)) : _createCommentVNode("", true)
                ])
            ])), [
                [
                    _directive_auto_id,
                    'ProfileBonusesCardDescriptionColumn'
                ]
            ]);
        };
    }
});
