import { ref } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ResponsibleGamblingQuizResultsKind } from '../../../../enums';
import { ResponsibleGamblingQuizResultCategory } from '../../types';
export default function getCategoryDescription(kind, category) {
    const { $translate } = useI18n();
    let resultStatusComputed;
    switch(kind){
        case ResponsibleGamblingQuizResultsKind.ACCEPTABLE:
            resultStatusComputed = $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_CATEGORY_STATUS_ACCEPTABLE');
            break;
        case ResponsibleGamblingQuizResultsKind.MODERATELY:
            resultStatusComputed = $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_CATEGORY_STATUS_MODERATELY');
            break;
        case ResponsibleGamblingQuizResultsKind.CRITICAL:
            resultStatusComputed = $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_CATEGORY_STATUS_CRITICAL');
            break;
        case ResponsibleGamblingQuizResultsKind.DEFAULT:
        default:
            resultStatusComputed = $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_CATEGORY_STATUS_NORMAL');
            break;
    }
    switch(category){
        case ResponsibleGamblingQuizResultCategory.TIME:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_CATEGORY_TIME_DESCRIPTION', ref({
                resultStatus: resultStatusComputed.value
            })).value;
        case ResponsibleGamblingQuizResultCategory.FINANCE:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_CATEGORY_FINANCE_DESCRIPTION', ref({
                resultStatus: resultStatusComputed.value
            })).value;
        case ResponsibleGamblingQuizResultCategory.RELATIONSHIPS:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_CATEGORY_RELATIONSHIP_DESCRIPTION', ref({
                resultStatus: resultStatusComputed.value
            })).value;
        case ResponsibleGamblingQuizResultCategory.HEALTH:
        default:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_CATEGORY_HEALTH_DESCRIPTION', ref({
                resultStatus: resultStatusComputed.value
            })).value;
    }
}
