import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import ProfileBonusesCardLayout from '../../components/ProfileBonusesCardLayout/ProfileBonusesCardLayout.vue';
import ProfileBonusesCardDescriptionCustom from '../../components/ProfileBonusesCardDescriptionCustom/ProfileBonusesCardDescriptionCustom.vue';
import ProfileBonusesCardButtonController from '../ProfileBonusesCardButtonController/ProfileBonusesCardButtonController.vue';
import { useProfileBonusesTransferController } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesTransferController',
    props: {
        bonus: {},
        activeButton: {}
    },
    emits: [
        "collect"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        function collect() {
            emit('collect', {
                bonus: props.bonus,
                balance: props.bonus.transferAmount || 0
            });
        }
        const { layoutProperties, transferAmount, bonusButtons, descriptionTitle } = useProfileBonusesTransferController(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ProfileBonusesCardLayout, _normalizeProps(_guardReactiveProps(_unref(layoutProperties))), {
                default: _withCtx(()=>[
                        _createVNode(ProfileBonusesCardDescriptionCustom, {
                            "description-title": _unref(descriptionTitle),
                            "description-text": _unref(transferAmount)
                        }, null, 8, [
                            "description-title",
                            "description-text"
                        ]),
                        _createVNode(ProfileBonusesCardButtonController, {
                            buttons: _unref(bonusButtons),
                            "has-columns": _unref(bonusButtons).length > 1,
                            onClick: collect
                        }, null, 8, [
                            "buttons",
                            "has-columns"
                        ])
                    ]),
                _: 1
            }, 16)), [
                [
                    _directive_auto_id,
                    'ProfileBonusesTransferController'
                ]
            ]);
        };
    }
});
