import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
import { useCustomerHistoryTopBarRouteComponent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryTopBarRouteComponent',
    setup (__props) {
        // eslint-disable-next-line unicorn/prefer-module,max-len
        const { title, sportEventId, showSuffix } = useCustomerHistoryTopBarRouteComponent();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: _unref(title)
            }, _createSlots({
                _: 2
            }, [
                void 0
            ]), 1032, [
                "title"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerHistoryTopBarRouteComponent'
                ]
            ]);
        };
    }
});
