import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { VScrollbar } from '@components/v-scrollbar';
import TheSidebarMenu from 'web/src/components/SidebarMenu/TheSidebarMenu/TheSidebarMenu.vue';
import { useProfileSidebar } from './composables/useProfileSidebar';
export default /*@__PURE__*/ _defineComponent({
    __name: 'UserProfileSidebarRouteComponent',
    setup (__props) {
        const { menuItems, handleClick } = useProfileSidebar();
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['user-profile-sidebar'])
            }, [
                _createVNode(_unref(VScrollbar), {
                    "flex-fill": "",
                    "test-el": "profile-menu"
                }, {
                    default: _withCtx(()=>[
                            _withDirectives(_createVNode(TheSidebarMenu, {
                                menu: _unref(menuItems),
                                "replace-routes": "",
                                class: _normalizeClass(_ctx.$style['user-profile-sidebar__menu']),
                                "no-margins": "",
                                onLevelClick: _unref(handleClick)
                            }, null, 8, [
                                "menu",
                                "class",
                                "onLevelClick"
                            ]), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'profile-sidebar'
                                    }
                                ]
                            ])
                        ]),
                    _: 1
                })
            ], 2)), [
                [
                    _directive_auto_id,
                    'UserProfileSidebarRouteComponent'
                ]
            ]);
        };
    }
});
