import { computed, toRef, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { normalizeError } from '@leon-hub/errors';
import { validationSchemaChangePassword } from '@leon-hub/api-sdk';
import { useUserStore } from 'web/src/modules/user/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { PresetName } from 'web/src/modules/dialogs/enums';
import { FormControlType, FormFieldTouchedStrategy } from 'web/src/components/Form/enums';
import { useFormsStore } from 'web/src/modules/forms/store';
import { useCasAuthStore } from 'web/src/modules/cas/store';
import { getPasswordErrorTitle } from 'web/src/modules/profile/pages/PasswordChangeRoutePage/utils';
export default function usePasswordChangePage() {
    useRouter();
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const customerDataStore = useCustomerDataStore();
    const formStore = useFormsStore();
    const userStore = useUserStore();
    const casAuth = useCasAuthStore();
    const passwordChangeForm = ref();
    let isSubmiting = false;
    const schema = toRef(formStore, 'changePasswordSchema');
    onBeforeMount(async ()=>{
        await formStore.fetchSchema(validationSchemaChangePassword);
    });
    const isFormReady = computed(()=>Boolean(schema.value));
    function resetForm() {
        passwordChangeForm.value?.reset();
    }
    const uiSchema = computed(()=>({
            submitButton: {
                label: $translate('JSPACC_SETT_SAVE').value
            },
            fields: {
                oldPassword: {
                    title: $translate('JSPACC_REG_OLD_PWD').value,
                    widget: FormControlType.Password,
                    options: {
                        autocomplete: 'current-password',
                        placeholder: $translate('WEB2_PASSWORD_INPUT_PLACEHOLDER').value,
                        hideValidation: true
                    }
                },
                newPassword: {
                    title: $translate('JSPACC_SETT_NEWPWD').value,
                    widget: FormControlType.PasswordValidator,
                    options: {
                        autocomplete: 'new-password',
                        placeholder: $translate('WEB2_NEW_PASSWORD_INPUT_PLACEHOLDER').value
                    },
                    touchedStrategy: FormFieldTouchedStrategy.Change
                }
            }
        }));
    async function onSubmit(data) {
        if (!data.errors && !isSubmiting) {
            isSubmiting = true;
            const { oldPassword, newPassword } = data.formData;
            const encodedOldPassword = encodeURIComponent(oldPassword);
            const encodedNewPassword = encodeURIComponent(newPassword);
            try {
                await userStore.changePassword({
                    oldPassword: encodedOldPassword,
                    newPassword: encodedNewPassword,
                    ts: null
                });
                isSubmiting = false;
                await showDialog({
                    presetName: PresetName.ALERT_SUCCESS,
                    options: {
                        title: $translate('WEB2_PROFILE_UPDATE_PASSWORD').value,
                        buttons: [
                            {
                                label: $translate('WEB2_PROFILE_UPDATE_PASSWORD_BUTTON').value
                            }
                        ],
                        dataTestId: 'profile-update-password'
                    }
                }).promise.then(async ()=>{
                    "1";
                    resetForm();
                    await casAuth.deleteSavedLogins({
                        username: customerDataStore.login
                    });
                });
            } catch (rawError) {
                const error = normalizeError(rawError);
                const title = getPasswordErrorTitle(rawError);
                isSubmiting = false;
                await showDialog({
                    presetName: PresetName.ALERT_WARNING,
                    options: {
                        title: $translate(title).value,
                        confirmMessage: error.message,
                        dataTestId: 'profile-update-password-error'
                    }
                }).promise.then(()=>{
                    resetForm();
                });
            }
        }
    }
    return {
        schema,
        uiSchema,
        isFormReady,
        passwordChangeForm,
        onSubmit
    };
}
