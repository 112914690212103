import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
import { onMounted } from 'vue';
import { VForm } from 'web/src/components/Form';
import { VLoaderDelayed } from 'web/src/components/Loader';
import CustomerVerificationSumSub from 'web/src/modules/profile/submodules/verification/components/SumSub/CustomerVerificationSumSub.vue';
import { VerificationPageType } from 'web/src/modules/profile/submodules/verification/enums';
import { useCustomerVerificationDefault } from './composables/useCustomerVerificationDefault';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerVerificationDefault',
    emits: [
        "start-verification"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { schema, uiSchema, isLoadingRequest, isLoadingReasons, verificationTokenData, onSubmit, onChange } = useCustomerVerificationDefault();
        onMounted(()=>{
            emit('start-verification', {
                type: VerificationPageType.DEFAULT
            });
        });
        return (_ctx, _cache)=>_unref(verificationTokenData) ? (_openBlock(), _createBlock(CustomerVerificationSumSub, {
                key: 0,
                "initial-token-data": _unref(verificationTokenData)
            }, null, 8, [
                "initial-token-data"
            ])) : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style['verify-page'])
            }, [
                _unref(isLoadingReasons) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                })) : (_openBlock(), _createBlock(_unref(VForm), {
                    key: 1,
                    ref: "form",
                    schema: _unref(schema),
                    "ui-schema": _unref(uiSchema),
                    "is-pending": _unref(isLoadingRequest),
                    onInput: _unref(onChange),
                    onSubmit: _unref(onSubmit),
                    onChange: _unref(onChange)
                }, null, 8, [
                    "schema",
                    "ui-schema",
                    "is-pending",
                    "onInput",
                    "onSubmit",
                    "onChange"
                ]))
            ], 2));
    }
});
