import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
const _hoisted_2 = {
    key: 1
};
const _hoisted_3 = {
    key: 1
};
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import CustomerHistoryListItemDetails from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemDetails/CustomerHistoryListItemDetails.vue';
import CustomerHistoryList from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryList/CustomerHistoryList.vue';
import { defaultCurrency } from 'web/src/modules/money/consts';
import { useCustomerHistoryDetailsPayment } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryDetailsPayment',
    props: {
        transaction: {},
        currency: {
            default: defaultCurrency
        }
    },
    emits: [
        "id-click",
        "withdrawal-cancel"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isWithdrawPending, dataRows } = useCustomerHistoryDetailsPayment(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['history-details'])
            }, [
                _unref(isWithdrawPending) || !!_ctx.transaction.errorCode ? (_openBlock(), _createBlock(VHintBlock, {
                    key: 0,
                    icon: _unref(isWithdrawPending) ? _unref(IconName).TIMER_SAND : _unref(IconName).ATTENTION_OUTLINE,
                    class: _normalizeClass(_ctx.$style['history-details__hint'])
                }, {
                    title: _withCtx(()=>[
                            _unref(isWithdrawPending) ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('WEB2_TRANSACTION_WITHDRAWAL_PENDING_TITLE')), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('WEB2_TRANSACTION_HISTORY_CANCELED_PAYMENT')), 1))
                        ]),
                    default: _withCtx(()=>[
                            _unref(isWithdrawPending) ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_TRANSACTION_WITHDRAWAL_PENDING')), 1)
                            ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.transaction.errorMessage), 1)
                            ], 64))
                        ]),
                    _: 1
                }, 8, [
                    "icon",
                    "class"
                ])) : _createCommentVNode("", true),
                _createVNode(CustomerHistoryList, {
                    class: _normalizeClass(_ctx.$style['history-details__list'])
                }, {
                    default: _withCtx(()=>[
                            _createVNode(CustomerHistoryListItemDetails, {
                                hover: "",
                                label: _ctx.$t('WEB2_TRANSACTION_HISTORY_TRANSACTION_ID'),
                                value: _ctx.transaction.objectId,
                                onClick: _cache[0] || (_cache[0] = ($event)=>emit('id-click'))
                            }, {
                                icon: _withCtx(()=>[
                                        _createVNode(_unref(VIcon), {
                                            class: _normalizeClass(_ctx.$style['history-details__copy-icon']),
                                            name: _unref(IconName).FILES,
                                            size: _unref(IconSize).SIZE_20
                                        }, null, 8, [
                                            "class",
                                            "name",
                                            "size"
                                        ])
                                    ]),
                                _: 1
                            }, 8, [
                                "label",
                                "value"
                            ]),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataRows), (row, index)=>(_openBlock(), _createBlock(CustomerHistoryListItemDetails, {
                                    key: index,
                                    label: row.label,
                                    value: row.value,
                                    highlight: row.highlight
                                }, null, 8, [
                                    "label",
                                    "value",
                                    "highlight"
                                ]))), 128))
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ]),
                _ctx.transaction.withdrawCancellable && !_ctx.transaction.errorCode ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(VButton, {
                        class: _normalizeClass(_ctx.$style['history-details__withdrawal-button']),
                        "full-width": "",
                        label: _ctx.$t('WEB2_CANCEL_PAYMENT_WITHDRAWAL'),
                        onClick: _cache[1] || (_cache[1] = ($event)=>emit('withdrawal-cancel'))
                    }, null, 8, [
                        "class",
                        "label"
                    ])
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerHistoryDetailsPayment'
                ]
            ]);
        };
    }
});
