import { onBeforeMount, onBeforeUnmount, ref, toRef } from 'vue';
import CustomerFormError from 'web/src/utils/forms/CustomerFormError';
import useSimpleRegistrantProfileStore from 'web/src/modules/profile/store/useSimpleRegistrantProfileStore';
export function usePersonalDataInternational() {
    const simpleRegistrantProfileStore = useSimpleRegistrantProfileStore();
    const isSubmitPending = ref(false);
    const isCustomerFormErrorShown = ref(true);
    const customErrors = ref({});
    onBeforeMount(()=>{
        simpleRegistrantProfileStore.getProfileFields();
    });
    onBeforeUnmount(()=>{
        simpleRegistrantProfileStore.setProfileForm(null);
    });
    function resetCustomErrors() {
        isCustomerFormErrorShown.value = true;
        for (const fieldId of Object.keys(customErrors.value)){
            const errors = customErrors.value[fieldId];
            for (const error of errors)Object.assign(error, {
                message: ''
            });
        }
    }
    function resetPendingState() {
        isSubmitPending.value = false;
    }
    async function updateCustomerData(data) {
        isSubmitPending.value = true;
        resetCustomErrors();
        try {
            await simpleRegistrantProfileStore.updateCustomerData({
                formData: data.formData
            });
            return true;
        } catch (rawError) {
            if (rawError instanceof CustomerFormError) {
                customErrors.value = rawError.customErrors;
                isCustomerFormErrorShown.value = false;
                return false;
            }
            throw rawError;
        }
    }
    return {
        isSubmitPending,
        updateCustomerData,
        isFormLoaded: toRef(simpleRegistrantProfileStore, 'isFormLoaded'),
        profileSchema: toRef(simpleRegistrantProfileStore, 'profileSchema'),
        customErrors,
        resetPendingState
    };
}
