import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getVerificationReasons, makeCustomerVerification } from '@leon-hub/api-sdk';
import { isArrayOf } from '@leon-hub/guards';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { isVerificationReason } from 'web/src/modules/profile/submodules/verification/store/guards';
export const useVerificationDefaultStore = defineStore('verification-default', ()=>{
    const api = useGraphqlClient();
    const reasons = ref([]);
    async function fetchReasons() {
        const response = await getVerificationReasons(api, (node)=>node.queries.verification.getReasons, {
            options: {}
        });
        const responseReasons = response.reasons.filter((reason)=>!!reason.id && !!reason.localizedName);
        isArrayOf(isVerificationReason, responseReasons);
        reasons.value = responseReasons;
    }
    function makeVerification(request) {
        return makeCustomerVerification(api, (node)=>node.mutations.verification.makeCustomerVerification, {
            options: request
        });
    }
    return {
        reasons,
        fetchReasons,
        makeVerification
    };
});
