import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { useCustomerHistoryDetailsScoreBoard } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryDetailsScoreBoard',
    props: {
        transaction: {}
    },
    setup (__props) {
        const props = __props;
        const { isSingle, isExpress, isLive, competitors, betKind, header, showScoreDetails, homeMainScore, awayMainScore, additionalScore, additionalScoreBadgeProperties, currentPhaseScore, statusBadgeProperties, kickoff, goToEvent, gameScoreProgress, isStaticLiveProgress, isTimeReversed, staticDirectTimeProgressLabel, staticReversedTimeProgressLabel, stage, isTimeReversedIndicatorAvailable } = useCustomerHistoryDetailsScoreBoard(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['scoreboard']]: true,
                    [_ctx.$style['scoreboard--hover']]: !!_ctx.transaction.eventUrl
                }),
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(goToEvent) && _unref(goToEvent)(...args);
                })
            }, [
                _unref(isSingle) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['scoreboard__wrapper'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['scoreboard__header'])
                    }, _toDisplayString(_unref(header)), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['scoreboard__event-details'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['event-details__competitors-wrapper'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['event-details__competitors'])
                            }, [
                                _createTextVNode(_toDisplayString(_unref(competitors)) + " ", 1),
                                _ctx.transaction.eventUrl ? (_openBlock(), _createBlock(_unref(VIcon), {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['event-details__link-chevron']),
                                    name: _unref(IconName).CHEVRON
                                }, null, 8, [
                                    "class",
                                    "name"
                                ])) : _createCommentVNode("", true)
                            ], 2)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['event-details__datetime'])
                        }, [
                            _createVNode(VBadge, _mergeProps({
                                class: _ctx.$style['event-details__status-badge']
                            }, _unref(statusBadgeProperties)), null, 16, [
                                "class"
                            ]),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['event-details__kickoff'])
                            }, _toDisplayString(_unref(kickoff)), 3)
                        ], 2)
                    ], 2),
                    _unref(showScoreDetails) ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['scoreboard__score-details'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['score-details__home-img'])
                        }, null, 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['score-details__score-wrapper'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['score-details__main-score-wrapper'])
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass({
                                        [_ctx.$style['score-details__main-score']]: true,
                                        [_ctx.$style['score-details__main-score--positive']]: _unref(isLive)
                                    })
                                }, _toDisplayString(_unref(homeMainScore)), 3),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['score-details__divider'])
                                }, ":", 2),
                                _createElementVNode("span", {
                                    class: _normalizeClass({
                                        [_ctx.$style['score-details__main-score']]: true,
                                        [_ctx.$style['score-details__main-score--positive']]: _unref(isLive)
                                    })
                                }, _toDisplayString(_unref(awayMainScore)), 3)
                            ], 2),
                            _unref(additionalScore) ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['score-details__additional-score-wrapper'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['score-details__additional-score-container'])
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['score-details__additional-score'])
                                    }, _toDisplayString(_unref(additionalScore).home), 3),
                                    _createElementVNode("span", {
                                        class: _normalizeClass({
                                            [_ctx.$style['score-details__divider']]: true,
                                            [_ctx.$style['score-details__divider--small']]: true
                                        })
                                    }, ":", 2),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['score-details__additional-score'])
                                    }, _toDisplayString(_unref(additionalScore).away), 3)
                                ], 2),
                                _createVNode(VBadge, _mergeProps({
                                    class: _ctx.$style['score-details__additional-score-badge']
                                }, _unref(additionalScoreBadgeProperties)), null, 16, [
                                    "class"
                                ])
                            ], 2)) : _createCommentVNode("", true),
                            _unref(currentPhaseScore) ? (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['score-details__phase-score'])
                            }, _toDisplayString(`(${_unref(currentPhaseScore)})`), 3)) : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['score-details__away-img'])
                        }, null, 2)
                    ], 2)) : _createCommentVNode("", true),
                    _unref(isLive) && _unref(stage) && (_unref(gameScoreProgress) || _unref(isStaticLiveProgress)) ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['scoreboard__live-status'])
                    }, [
                        _unref(stage) ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['live-status__stage'])
                        }, _toDisplayString(_unref(stage)), 3)) : _createCommentVNode("", true),
                        _cache[1] || (_cache[1] = _createElementVNode("span", null, " ", -1)),
                        _unref(gameScoreProgress) ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['live-status__progress'])
                        }, _toDisplayString(_unref(gameScoreProgress)), 3)) : _unref(isStaticLiveProgress) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 2
                        }, [
                            _unref(staticReversedTimeProgressLabel) && _unref(isTimeReversedIndicatorAvailable) && _unref(isTimeReversed) ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['live-status__progress'])
                            }, _toDisplayString(_unref(staticReversedTimeProgressLabel)), 3)) : _unref(staticDirectTimeProgressLabel) ? (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['live-status__progress'])
                            }, _toDisplayString(_unref(staticDirectTimeProgressLabel)), 3)) : _createCommentVNode("", true)
                        ], 64)) : _createCommentVNode("", true)
                    ], 2)) : _createCommentVNode("", true)
                ], 2)) : _unref(isSingle) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['scoreboard__wrapper'])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['scoreboard__combi-name'])
                    }, _toDisplayString(_unref(isExpress) ? _ctx.$t('WEB2_TRANSACTION_HISTORY_EXPRESS_BET') : _ctx.$t('WEB2_TRANSACTION_HISTORY_SYSTEM_BET')), 3),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['scoreboard__combi-kind'])
                    }, _toDisplayString(_unref(betKind)), 3)
                ], 2))
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerHistoryDetailsScoreBoard'
                ]
            ]);
        };
    }
});
