import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import CustomerHistoryDetailsSupport from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryDetailsSupport/CustomerHistoryDetailsSupport.vue';
import CustomerHistoryListItemDetails from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemDetails/CustomerHistoryListItemDetails.vue';
import CustomerHistoryList from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryList/CustomerHistoryList.vue';
import { useCustomerHistoryDetailsEgs } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryDetailsEgs',
    props: {
        transaction: {}
    },
    emits: [
        "id-click",
        "support-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { dataRows } = useCustomerHistoryDetailsEgs(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['history-details'])
            }, [
                _createVNode(CustomerHistoryList, {
                    class: _normalizeClass(_ctx.$style['history-details__list'])
                }, {
                    default: _withCtx(()=>[
                            _createVNode(CustomerHistoryListItemDetails, {
                                hover: "",
                                label: _ctx.$t('WEB2_TRANSACTION_HISTORY_TRANSACTION_ID'),
                                value: _ctx.transaction.objectId,
                                onClick: _cache[0] || (_cache[0] = ($event)=>emit('id-click'))
                            }, {
                                icon: _withCtx(()=>[
                                        _createVNode(_unref(VIcon), {
                                            class: _normalizeClass(_ctx.$style['history-details__copy-icon']),
                                            name: _unref(IconName).FILES,
                                            size: _unref(IconSize).SIZE_20
                                        }, null, 8, [
                                            "class",
                                            "name",
                                            "size"
                                        ])
                                    ]),
                                _: 1
                            }, 8, [
                                "label",
                                "value"
                            ]),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataRows), (row, index)=>(_openBlock(), _createBlock(CustomerHistoryListItemDetails, {
                                    key: index,
                                    label: row.label,
                                    value: row.value
                                }, null, 8, [
                                    "label",
                                    "value"
                                ]))), 128))
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ]),
                _createVNode(CustomerHistoryDetailsSupport, {
                    class: _normalizeClass(_ctx.$style['history-details__support-block']),
                    onSupportClick: _cache[1] || (_cache[1] = ($event)=>emit('support-click'))
                }, null, 8, [
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerHistoryDetailsEgs'
                ]
            ]);
        };
    }
});
