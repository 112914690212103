import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "title"
];
const _hoisted_2 = {
    class: "bonus-card-timer__date"
};
const _hoisted_3 = {
    key: 0,
    class: "bonus-card-timer__progress"
};
import { useSlots, ref } from 'vue';
import VCountdown from 'web/src/components/Countdown/VCountdown/VCountdown.vue';
import { useI18n } from 'web/src/modules/i18n/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesCardTimer',
    props: {
        expirationDate: {},
        isShortNames: {
            type: Boolean
        }
    },
    emits: [
        "timer-end"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const slots = useSlots();
        const { $translate } = useI18n();
        const bonusExpiredTranslation = `${$translate('WEB2_BONUS_EXPIRES').value}: `;
        const timeLabel = ref();
        function setTimeLabel(value) {
            timeLabel.value = value;
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "bonus-card-timer",
                    {
                        'bonus-card-timer--with-progress': _unref(slots).progress
                    }
                ]),
                title: timeLabel.value
            }, [
                _createElementVNode("span", _hoisted_2, [
                    props.expirationDate ? (_openBlock(), _createBlock(VCountdown, {
                        key: 0,
                        "expiration-date": props.expirationDate,
                        title: bonusExpiredTranslation,
                        "is-short-names": props.isShortNames || Boolean(_unref(slots).progress),
                        onCountdownEnd: _cache[0] || (_cache[0] = ($event)=>{
                            emit('timer-end');
                        }),
                        onCountdownChange: setTimeLabel
                    }, null, 8, [
                        "expiration-date",
                        "is-short-names"
                    ])) : _createCommentVNode("", true)
                ]),
                _unref(slots).progress ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "progress")
                ])) : _createCommentVNode("", true)
            ], 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'ProfileBonusesCardTimer'
                ]
            ]);
        };
    }
});
