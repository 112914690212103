import { useRouter } from 'vue-router';
import { computed, ref, toRef, watch } from 'vue';
import { CustomerVerificationType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useUserStore } from 'web/src/modules/user/store';
import useVerification from 'web/src/modules/profile/submodules/verification/composables/useVerification';
export default function useVerificationPage() {
    const customerDataStore = useCustomerDataStore();
    const { loadCustomerData } = useUserStore();
    const { isVideoVerificationEnabled, isVerificationNavigationItemEnabled, isVerificationStatusActive, isEkycRequestable } = useVerification();
    const forcedVerificationType = toRef(()=>customerDataStore.forcedVerificationType);
    const verificationType = toRef(()=>customerDataStore.verificationType);
    const isCustomerVerificationAsDefaultAvailable = toRef(()=>customerDataStore.isCustomerVerificationAsDefaultAvailable);
    const startedVerification = ref();
    const { isLoggedIn } = useIsLoggedIn();
    const router = useRouter();
    const isSumSubComponent = computed(()=>verificationType.value !== CustomerVerificationType.INTERNAL && isVerificationStatusActive.value);
    watch(isSumSubComponent, (newValue)=>{
        if (!newValue && isLoggedIn.value) router.replace({
            name: RouteName.PROFILE
        });
    });
    watch(forcedVerificationType, (newValue)=>{
        if (newValue === CustomerVerificationType.CUSTOMER_VERIFICATION_SERVICE) customerDataStore.setForcedVerificationType(null);
    });
    async function beforeComponentMount() {
        if (isLoggedIn.value) await loadCustomerData();
        if (!isVerificationNavigationItemEnabled.value) router.back();
    }
    function beforeComponentUnmount() {
        startedVerification.value = void 0;
        if (isLoggedIn.value) loadCustomerData(true);
    }
    function onStartVerification(param) {
        let { type } = param;
        startedVerification.value = type;
    }
    return {
        forcedVerificationType,
        isSumSubComponent,
        isVideoVerificationEnabled,
        isCustomerVerificationAsDefaultAvailable,
        beforeComponentMount,
        beforeComponentUnmount,
        startedVerification,
        onStartVerification,
        isEkycRequestable
    };
}
