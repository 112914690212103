import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue";
import { defineAsyncComponent } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import SListItem from 'web/src/components/List/SListItem/SListItem.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import SListItemAccordion from 'web/src/components/List/SListItemAccordion/SListItemAccordion.vue';
import NavList from 'web/src/modules/profile/components/NavList';
import { VEmpty } from 'web/src/components/Empty';
import VThemeSwitcher from 'web/src/components/ThemeSwitcher/VThemeSwitcher/VThemeSwitcher.vue';
import PinCode from 'web/src/modules/profile/components/PinCode';
import VList from 'web/src/components/List/VList/VList.vue';
import SList from 'web/src/components/List/SList/SList.vue';
import { ListType } from 'web/src/components/List/enums';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import SListItemSwitcher from 'web/src/components/List/SListItemSwitcher/SListItemSwitcher.vue';
import OddsSelectorItem from 'web/src/modules/sportline/submodules/odds/views/OddsSelectorItem.vue';
import useUserSettingsPage from './composables/useUserSettingsPage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'UserSettingsRoutePage',
    setup (__props) {
        const LanguageSwitcherListItem = // eslint-disable-next-line max-len
        defineAsyncComponent(()=>import('web/src/modules/i18n/components/LanguageSelector/components/LanguageSwitcherListItem/LanguageSwitcherListItem.vue'));
        const { isOddsFormatSwitcherEnabled, userSettingsNavigation, isLanguageSwitcherAvailable, currentLanguageFlag, currentLanguageName, themeLabel, isHiddenBalance, isShowPinCode, showLoginPinCode, hasPinCode, hasLoginPinCode, isFingerprintSupported, isFingerprintAuthenticationEnabled, openLanguage, getIconSettings, handleChangeTheme, onItemClick, switchPinCode, changePinCode, loginPinCode, changeFingerSettings, onClickChangeHiddenBalance } = useUserSettingsPage();
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return _openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style['user-settings'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userSettingsNavigation), (block)=>(_openBlock(), _createBlock(VList, {
                        key: block.id,
                        "list-type": _unref(ListType).GROUP
                    }, {
                        default: _withCtx(()=>[
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.children, (item)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                                        item.props && item.props.isLanguageSelector ? (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 0
                                        }, [
                                            _unref(isLanguageSwitcherAvailable) ? _withDirectives((_openBlock(), _createBlock(_unref(LanguageSwitcherListItem), {
                                                key: item.id,
                                                name: _unref(currentLanguageFlag),
                                                title: _unref(currentLanguageName),
                                                onOpenLanguage: _unref(openLanguage)
                                            }, null, 8, [
                                                "name",
                                                "title",
                                                "onOpenLanguage"
                                            ])), [
                                                [
                                                    _directive_data_test,
                                                    {
                                                        el: 'user-settings-navigation-item',
                                                        navigationId: item.id
                                                    }
                                                ]
                                            ]) : _createCommentVNode("", true)
                                        ], 64)) : item.props && item.props.isThemeSelector ? _withDirectives((_openBlock(), _createBlock(VListItemAccordion, {
                                            id: item.id,
                                            key: item.id,
                                            "icon-name": _unref(getIconSettings)(item),
                                            title: item?.caption || '',
                                            "suffix-text": _unref(themeLabel)
                                        }, {
                                            content: _withCtx(()=>[
                                                    _createVNode(VThemeSwitcher, {
                                                        onChange: _unref(handleChangeTheme)
                                                    }, null, 8, [
                                                        "onChange"
                                                    ])
                                                ]),
                                            _: 2
                                        }, 1032, [
                                            "id",
                                            "icon-name",
                                            "title",
                                            "suffix-text"
                                        ])), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'user-settings-navigation-item',
                                                    navigationId: item.id
                                                }
                                            ]
                                        ]) : item.props && item.props.isHiddenBalance ? _withDirectives((_openBlock(), _createBlock(VListItem, {
                                            key: item.id,
                                            "icon-name": _unref(getIconSettings)(item),
                                            title: item.caption,
                                            "have-switcher": "",
                                            "is-switch-checked": _unref(isHiddenBalance),
                                            onClick: _unref(onClickChangeHiddenBalance)
                                        }, null, 8, [
                                            "icon-name",
                                            "title",
                                            "is-switch-checked",
                                            "onClick"
                                        ])), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'user-settings-navigation-item',
                                                    navigationId: item.id
                                                }
                                            ]
                                        ]) : item.props && item.props.isNewsSubscriptionSwitcher ? _withDirectives((_openBlock(), _createBlock(VListItem, {
                                            key: `${item.id}-5`,
                                            "icon-name": _unref(getIconSettings)(item),
                                            title: item.caption,
                                            "have-expand": "",
                                            onClick: ($event)=>_unref(onItemClick)(item)
                                        }, null, 8, [
                                            "icon-name",
                                            "title",
                                            "onClick"
                                        ])), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'user-settings-navigation-item',
                                                    navigationId: item.id
                                                }
                                            ]
                                        ]) : _unref(isOddsFormatSwitcherEnabled) && item.props && item.props.isOddsAvailable ? (_openBlock(), _createBlock(OddsSelectorItem, {
                                            key: `${item.id}-6`,
                                            "icon-name": _unref(IconName).ODDS,
                                            "has-background": ""
                                        }, null, 8, [
                                            "icon-name"
                                        ])) : item.props && item.props.isLiveChatSwitcher ? _withDirectives((_openBlock(), _createBlock(_unref(VEmpty), {
                                            key: `${item.id}-7`
                                        })), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'user-settings-navigation-item',
                                                    navigationId: item.id
                                                }
                                            ]
                                        ]) : item.props && item.props.isPinCode ? (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 6
                                        }, [
                                            _unref(isShowPinCode) ? _withDirectives((_openBlock(), _createBlock(_unref(PinCode), {
                                                key: item.id,
                                                "icon-name": _unref(getIconSettings)(item),
                                                title: item.caption,
                                                "has-pin-code": _unref(hasPinCode),
                                                "has-login-pin-code": _unref(hasLoginPinCode),
                                                "show-login-pin-code": _unref(showLoginPinCode),
                                                onSwitchPinCode: _unref(switchPinCode),
                                                onChangePinCode: _unref(changePinCode),
                                                onSwitchLoginPinCode: _unref(loginPinCode)
                                            }, null, 8, [
                                                "icon-name",
                                                "title",
                                                "has-pin-code",
                                                "has-login-pin-code",
                                                "show-login-pin-code",
                                                "onSwitchPinCode",
                                                "onChangePinCode",
                                                "onSwitchLoginPinCode"
                                            ])), [
                                                [
                                                    _directive_data_test,
                                                    {
                                                        el: 'user-settings-navigation-item',
                                                        navigationId: item.id
                                                    }
                                                ]
                                            ]) : _createCommentVNode("", true)
                                        ], 64)) : item.props && item.props.isFingerprintAuthSwitcher ? (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 7
                                        }, [
                                            _unref(isFingerprintSupported) ? _withDirectives((_openBlock(), _createBlock(VListItem, {
                                                key: item.id,
                                                "icon-name": _unref(getIconSettings)(item),
                                                title: item.caption,
                                                "have-switcher": "",
                                                "is-switch-checked": _unref(isFingerprintAuthenticationEnabled),
                                                onClickSwitcher: _withModifiers(_unref(changeFingerSettings), [
                                                    "prevent"
                                                ])
                                            }, null, 8, [
                                                "icon-name",
                                                "title",
                                                "is-switch-checked",
                                                "onClickSwitcher"
                                            ])), [
                                                [
                                                    _directive_data_test,
                                                    {
                                                        el: 'user-settings-navigation-item',
                                                        navigationId: item.id
                                                    }
                                                ]
                                            ]) : _createCommentVNode("", true)
                                        ], 64)) : item.children && item.children.length ? _withDirectives((_openBlock(), _createBlock(VListItemAccordion, {
                                            id: item.id,
                                            key: `${item.id}-9`,
                                            "icon-name": _unref(getIconSettings)(item),
                                            title: item?.caption || ''
                                        }, {
                                            content: _withCtx(()=>[
                                                    item.children && item.children.length ? (_openBlock(), _createBlock(_unref(NavList), {
                                                        key: 0,
                                                        "one-level-list": "",
                                                        items: item.children
                                                    }, null, 8, [
                                                        "items"
                                                    ])) : _createCommentVNode("", true)
                                                ]),
                                            _: 2
                                        }, 1032, [
                                            "id",
                                            "icon-name",
                                            "title"
                                        ])), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'user-settings-navigation-item',
                                                    navigationId: item.id
                                                }
                                            ]
                                        ]) : _withDirectives((_openBlock(), _createBlock(VListItem, {
                                            key: `${item.id}-8`,
                                            "icon-name": _unref(getIconSettings)(item),
                                            title: item.caption,
                                            "have-expand": "",
                                            onClick: ($event)=>_unref(onItemClick)(item)
                                        }, null, 8, [
                                            "icon-name",
                                            "title",
                                            "onClick"
                                        ])), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'user-settings-navigation-item',
                                                    navigationId: item.id
                                                }
                                            ]
                                        ])
                                    ], 64))), 256))
                            ]),
                        _: 2
                    }, 1032, [
                        "list-type"
                    ]))), 128))
            ], 2);
        };
    }
});
