import { IconName } from '@leon-hub/icons';
import { ResponsibleGamblingQuizResultCategory } from '../../types';
export default function getIconName(category) {
    switch(category){
        case ResponsibleGamblingQuizResultCategory.TIME:
            return IconName.CLOCK;
        case ResponsibleGamblingQuizResultCategory.FINANCE:
            return IconName.MONEY_ON;
        case ResponsibleGamblingQuizResultCategory.RELATIONSHIPS:
            return IconName.PARTNERS;
        // eslint-disable-next-line unicorn/no-useless-switch-case
        case ResponsibleGamblingQuizResultCategory.HEALTH:
        default:
            return IconName.HEART_PULSE;
    }
}
