import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { useRouter } from 'vue-router';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from '@leon-hub/module-buttons';
import RouteName from '@leon-hub/routing-config-names';
import { VIcon } from '@components/v-icon';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useIntercomStore } from 'web/src/modules/intercom/store';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingInfoRoutePage',
    setup (__props) {
        const router = useRouter();
        const tools = [
            {
                type: 'assignment',
                icon: IconName.ASSIGNMENT,
                title: 'WEB2_RESPONSIBLE_GAMBLING_INFO_TOOLS_ASSIGNMENT_TITLE',
                buttonText: 'WEB2_RESPONSIBLE_GAMBLING_INFO_TOOLS_ASSIGNMENT_BUTTON',
                cmsContent: 'WEB2_RESPONSIBLE_GAMBLING_INFO_PAGE_ASSIGNMENT',
                buttonAction () {
                    router.push({
                        name: RouteName.RESPONSIBLE_GAMBLING_TEST
                    });
                }
            },
            {
                type: 'limits',
                icon: IconName.SPEEDOMETER,
                title: 'WEB2_RESPONSIBLE_GAMBLING_INFO_TOOLS_LIMITS_TITLE',
                buttonText: 'WEB2_RESPONSIBLE_GAMBLING_INFO_TOOLS_LIMITS_BUTTON',
                cmsContent: 'WEB2_RESPONSIBLE_GAMBLING_INFO_PAGE_LIMITS',
                buttonAction () {
                    router.push({
                        name: RouteName.RESPONSIBLE_GAMBLING_LIMITS
                    });
                }
            },
            {
                type: 'support',
                icon: IconName.SUPPORT,
                title: 'WEB2_RESPONSIBLE_GAMBLING_INFO_TOOLS_SUPPORT_TITLE',
                buttonText: 'WEB2_RESPONSIBLE_GAMBLING_INFO_TOOLS_SUPPORT_BUTTON',
                cmsContent: 'WEB2_RESPONSIBLE_GAMBLING_INFO_PAGE_SUPPORT',
                buttonAction () {
                    useIntercomStore().openIntercom();
                }
            }
        ];
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("section", {
                class: _normalizeClass(_ctx.$style['responsible-gambling-info'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-info__image'])
                }, [
                    _createElementVNode("img", {
                        src: require("web/src/modules/profile/submodules/responsible-gambling/images/li/info.svg"),
                        alt: "",
                        role: "presentation"
                    }, null, 8, _hoisted_1)
                ], 2),
                _createElementVNode("section", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-info__description'])
                }, [
                    _createElementVNode("h1", {
                        class: _normalizeClass(_ctx.$style['responsible-gambling-info__description-title'])
                    }, _toDisplayString(_ctx.$t('WEB2_RESPONSIBLE_GAMBLING_INFO_PAGE_DESCRIPTION_TITLE')), 3),
                    _createVNode(VCmsContent, {
                        "cms-key": "WEB2_RESPONSIBLE_GAMBLING_INFO_PAGE_DESCRIPTION",
                        silent: ""
                    })
                ], 2),
                _createElementVNode("section", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-info__tools'])
                }, [
                    (_openBlock(), _createElementBlock("h2", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['responsible-gambling-info__tools-title'])
                    }, _toDisplayString(_ctx.$t('WEB2_RESPONSIBLE_GAMBLING_INFO_TOOLS_TITLE')), 3)),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['responsible-gambling-info__tools-items'])
                    }, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tools, (item, index)=>_createElementVNode("article", {
                                key: index,
                                class: _normalizeClass(_ctx.$style['responsible-gambling-info__tools-item'])
                            }, [
                                _createElementVNode("h2", {
                                    class: _normalizeClass(_ctx.$style['responsible-gambling-info__tools-item-title'])
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass({
                                            [_ctx.$style['responsible-gambling-info__tools-item-icon']]: true,
                                            [_ctx.$style[`${item.type}-color`]]: true
                                        })
                                    }, [
                                        _createVNode(_unref(VIcon), {
                                            name: item.icon,
                                            size: _unref(IconSize).SIZE_28
                                        }, null, 8, [
                                            "name",
                                            "size"
                                        ])
                                    ], 2),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t(item.title)), 1)
                                ], 2),
                                _createVNode(VCmsContent, {
                                    "cms-key": item.cmsContent,
                                    silent: ""
                                }, null, 8, [
                                    "cms-key"
                                ]),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['responsible-gambling-info__tools-item-button-container'])
                                }, [
                                    _createVNode(VButton, {
                                        class: _normalizeClass({
                                            [_ctx.$style['responsible-gambling-info__tools-item-button']]: true,
                                            [_ctx.$style[`${item.type}-color`]]: true
                                        }),
                                        label: _ctx.$t(`${item.buttonText}`),
                                        onClick: item.buttonAction
                                    }, null, 8, [
                                        "class",
                                        "label",
                                        "onClick"
                                    ])
                                ], 2)
                            ], 2)), 64)),
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['responsible-gambling-info__tools-item']]: true,
                                [_ctx.$style["responsible-gambling-info__tools-item--options"]]: true
                            })
                        }, [
                            _createVNode(VCmsContent, {
                                "cms-key": "WEB2_RESPONSIBLE_GAMBLING_INFO_PAGE_OPTIONS_DESCRIPTION",
                                silent: ""
                            }),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['responsible-gambling-info__tools-item-button-container'])
                            }, [
                                (_openBlock(), _createBlock(LButton, {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['responsible-gambling-info__tools-item-button']),
                                    label: _ctx.$t('WEB2_RESPONSIBLE_GAMBLING_INFO_TOOLS_OPTIONS_BUTTON'),
                                    kind: _unref(ButtonKind).TRANSPARENT,
                                    onClick: _cache[1] || (_cache[1] = ($event)=>_unref(router).push({
                                            name: _unref(RouteName).RESPONSIBLE_GAMBLING_OPTIONS
                                        }))
                                }, null, 8, [
                                    "class",
                                    "label",
                                    "kind"
                                ]))
                            ], 2)
                        ], 2)
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-info__tools-item-button-wrapper'])
                }, [
                    (_openBlock(), _createBlock(LButton, {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['responsible-gambling-info__tools-item-button']),
                        label: _ctx.$t('WEB2_RESPONSIBLE_GAMING_OPTIONS_BACK_TO_GAME'),
                        kind: _unref(ButtonKind).BASE,
                        onClick: _cache[3] || (_cache[3] = ($event)=>_unref(router).push({
                                name: _unref(RouteName).HOME
                            }))
                    }, null, 8, [
                        "class",
                        "label",
                        "kind"
                    ]))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingInfoRoutePage'
                ]
            ]);
        };
    }
});
