import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ResponsibleGamblingQuizResultCategory } from 'web/src/modules/profile/submodules/responsible-gambling/components/ResponsibleGamblingQuizResult/types';
import VRadio from 'web/src/components/Radio/VRadio/VRadio.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { ResponsibleGamblingQuizResultsKind } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import ResponsibleGamblingQuizFinalDescription from '../../components/ResponsibleGamblingQuizFinalDescription/ResponsibleGamblingQuizFinalDescription.vue';
import ResponsibleGamblingQuizResult from '../../components/ResponsibleGamblingQuizResult/ResponsibleGamblingQuizResult.vue';
import ResponsibleGamblingQuizProgress from '../../components/ResponsibleGamblingQuizProgress/ResponsibleGamblingQuizProgress.vue';
import useResponsibleGamblingQuizRoutePage from './composables/useResponsibleGamblingQuizRoutePage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingQuizRoutePage',
    setup (__props) {
        const { buttonNextProps, isShowResults, finalDescriptionKind, options, questionsQuantity, resultFinanceProps, resultHealthProps, resultRelationshipsProps, resultTimeProps, selectedAnswer, stepNumber, stepTitle, onChange, onCloseClick, onNextClick, onPrevClick } = useResponsibleGamblingQuizRoutePage();
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['responsible-gambling-quiz'])
            }, [
                _unref(isShowResults) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['responsible-gambling-quiz__results-list'])
                }, [
                    _createVNode(ResponsibleGamblingQuizFinalDescription, {
                        kind: _unref(finalDescriptionKind)
                    }, null, 8, [
                        "kind"
                    ]),
                    _createVNode(ResponsibleGamblingQuizResult, _mergeProps(_unref(resultTimeProps), {
                        category: _unref(ResponsibleGamblingQuizResultCategory).TIME
                    }), null, 16, [
                        "category"
                    ]),
                    _createVNode(ResponsibleGamblingQuizResult, _mergeProps(_unref(resultFinanceProps), {
                        category: _unref(ResponsibleGamblingQuizResultCategory).FINANCE
                    }), null, 16, [
                        "category"
                    ]),
                    _createVNode(ResponsibleGamblingQuizResult, _mergeProps(_unref(resultRelationshipsProps), {
                        category: _unref(ResponsibleGamblingQuizResultCategory).RELATIONSHIPS
                    }), null, 16, [
                        "category"
                    ]),
                    _createVNode(ResponsibleGamblingQuizResult, _mergeProps(_unref(resultHealthProps), {
                        category: _unref(ResponsibleGamblingQuizResultCategory).HEALTH
                    }), null, 16, [
                        "category"
                    ]),
                    _createVNode(VButton, {
                        class: _normalizeClass(_ctx.$style['responsible-gambling-quiz__results-button']),
                        label: _ctx.$t('WEB2_RESPONSIBLE_GAMBLING_QUIZ_CLOSE'),
                        kind: _unref(ButtonKind).BASE,
                        onClick: _unref(onCloseClick)
                    }, null, 8, [
                        "class",
                        "label",
                        "kind",
                        "onClick"
                    ])
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'responsible-gambling-quiz__results-list'
                        }
                    ]
                ]) : _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['responsible-gambling-quiz__question'])
                }, [
                    _createElementVNode("div", null, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['responsible-gambling-quiz__question-progress'])
                        }, [
                            _createVNode(ResponsibleGamblingQuizProgress, {
                                of: _unref(questionsQuantity),
                                value: _unref(stepNumber),
                                kind: _unref(ResponsibleGamblingQuizResultsKind).DEFAULT
                            }, _createSlots({
                                _: 2
                            }, [
                                {
                                    name: "topLabel",
                                    fn: _withCtx(()=>[
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_RESPONSIBLE_GAMBLING_QUIZ_QUESTION')) + " " + _toDisplayString(_unref(stepNumber)) + "/" + _toDisplayString(_unref(questionsQuantity)), 1)
                                        ]),
                                    key: "1"
                                }
                            ]), 1032, [
                                "of",
                                "value",
                                "kind"
                            ])
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['responsible-gambling-quiz__step']]: true
                            })
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['responsible-gambling-quiz__step-title'])
                            }, _toDisplayString(_unref(stepTitle)), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['responsible-gambling-quiz__step-options'])
                            }, [
                                _createVNode(VList, null, {
                                    default: _withCtx(()=>[
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(options), (option, index)=>(_openBlock(), _createBlock(VListItem, {
                                                    key: index
                                                }, {
                                                    default: _withCtx(()=>[
                                                            _createElementVNode("div", {
                                                                class: _normalizeClass(_ctx.$style['responsible-gambling-quiz__step-option'])
                                                            }, [
                                                                _createVNode(VRadio, {
                                                                    label: option.label,
                                                                    value: option.value,
                                                                    checked: option.value === _unref(selectedAnswer),
                                                                    name: "answer_option",
                                                                    onChange: ($event)=>_unref(onChange)(option.value)
                                                                }, null, 8, [
                                                                    "label",
                                                                    "value",
                                                                    "checked",
                                                                    "onChange"
                                                                ])
                                                            ], 2)
                                                        ]),
                                                    _: 2
                                                }, 1024))), 128))
                                        ]),
                                    _: 1
                                })
                            ], 2)
                        ], 2)
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['responsible-gambling-quiz__buttons'])
                    }, [
                        _createVNode(VButton, _mergeProps(_unref(buttonNextProps), {
                            onClick: _unref(onNextClick)
                        }), null, 16, [
                            "onClick"
                        ]),
                        (_openBlock(), _createBlock(LButton, {
                            key: 1,
                            label: _ctx.$t('WEB2_RESPONSIBLE_GAMBLING_QUIZ_BACK'),
                            kind: _unref(ButtonKind).TRANSPARENT,
                            onClick: _unref(onPrevClick)
                        }, null, 8, [
                            "label",
                            "kind",
                            "onClick"
                        ]))
                    ], 2)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'responsible-gambling-quiz__question'
                        }
                    ]
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingQuizRoutePage'
                ]
            ]);
        };
    }
});
