import { defineStore } from 'pinia';
import { getVerificationToken } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const useVerificationStore = defineStore('verification', ()=>{
    const apiClient = useGraphqlClient();
    const getAccessToken = function() {
        let ekyc = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        return getVerificationToken(apiClient, (node)=>node.queries.verification.getTokenV2, {
            options: {
                ekyc
            }
        });
    };
    return {
        getAccessToken
    };
});
export default useVerificationStore;
