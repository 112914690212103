import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { IconName, IconSize } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ResponsibleGamblingOptionType } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
export default function useResponsibleGamblingOption(props) {
    const { $translate } = useI18n();
    const router = useRouter();
    const optionTitle = computed(()=>{
        switch(props.type){
            case ResponsibleGamblingOptionType.TEST:
                return $translate('WEB2_RESPONSIBLE_GAMING_OPTION_TITLE_TEST').value;
            case ResponsibleGamblingOptionType.SUPPORT:
                return $translate('WEB2_RESPONSIBLE_GAMING_OPTION_TITLE_SUPPORT').value;
            case ResponsibleGamblingOptionType.LIMITS:
                return $translate('WEB2_RESPONSIBLE_GAMING_OPTION_TITLE_LIMITS').value;
            case ResponsibleGamblingOptionType.INFO:
                return $translate('WEB2_RESPONSIBLE_GAMING_OPTION_TITLE_INFO').value;
            default:
                return '';
        }
    });
    const iconName = computed(()=>{
        switch(props.type){
            case ResponsibleGamblingOptionType.TEST:
                return IconName.ASSIGNMENT;
            case ResponsibleGamblingOptionType.SUPPORT:
                return IconName.SUPPORT;
            case ResponsibleGamblingOptionType.LIMITS:
                return IconName.SPEEDOMETER;
            case ResponsibleGamblingOptionType.INFO:
                return IconName.INFO_OUTLINE;
            default:
                return IconName.CROSS;
        }
    });
    const iconProps = computed(()=>({
            name: iconName.value,
            size: IconSize.SIZE_28
        }));
    function onClick() {
        if (props.routeName) router.push({
            name: props.routeName
        });
    }
    return {
        iconProps,
        optionTitle,
        onClick
    };
}
