import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';
import { useProfileBonusesStore } from 'web/src/modules/profile/submodules/bonuses/store';
let ProfileBonusesPagePrefetch = class ProfileBonusesPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        const siteConfigStore = useSiteConfigStore();
        const profileBonusesStore = useProfileBonusesStore();
        const bonusesEnable = toRef(()=>siteConfigStore.bonusesEnable);
        const hideOnBoardingPage = toRef(()=>profileBonusesStore.hideOnBoardingPage);
        const loading = toRef(()=>profileBonusesStore.loading);
        await profileBonusesStore.fetchBonuses();
        if (!loading.value && bonusesEnable.value && !hideOnBoardingPage.value) {
            profileBonusesStore.setHideOnBoardingPageState(true);
            next({
                name: RouteName.ONBOARDING,
                params: {
                    id: OnboardingIdsEnum.BONUSES
                }
            });
            return Promise.resolve();
        }
        if (bonusesEnable.value) {
            next();
            return Promise.resolve();
        }
        next({
            name: RouteName.ERROR_NOT_FOUND_404
        });
        return Promise.resolve();
    }
};
export { ProfileBonusesPagePrefetch as default };
