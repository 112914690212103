import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { IconName, IconSize } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { normalizeError } from '@leon-hub/errors';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCurrencyStore } from 'web/src/modules/money/store';
import { useUserStore } from 'web/src/modules/user/store';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import useBonusLoyaltyStore from 'web/src/modules/bonuses/store/useBonusLoyaltyStore';
import { DialogComponent, PresetName } from 'web/src/modules/dialogs/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
import { useCustomerHistoryStore } from 'web/src/modules/profile/submodules/customer-history/store';
export default function useBonusWithdrawalsRoutePage() {
    const bonusStore = useBonusStore();
    const bonusLoyaltyStore = useBonusLoyaltyStore();
    const { $translate } = useI18n();
    const formatMoney = useFormatMoney();
    const { showDialog } = useDialogs();
    const { loadUserData } = useUserStore();
    const router = useRouter();
    const customerHistoryStore = useCustomerHistoryStore();
    const suggestedAmounts = toRef(useSiteConfigStore(), 'suggestedAmounts');
    const formattedCurrency = toRef(useCurrencyStore(), 'formattedCurrency');
    const formattedCurrencyInner = 'L';
    const selectedChoice = ref(0);
    const paymentsInputValue = ref('');
    const buttonState = ref(false);
    const buttonLabel = ref('');
    const isButtonDisabled = ref(false);
    const bonusValue = ref(0);
    const isShowSuccess = ref(false);
    function convertBonusToMoney(value) {
        return bonusStore.oneCoin * value;
    }
    const amount = computed(()=>bonusLoyaltyStore.amountNumber || 0);
    const maxBonusValue = computed(()=>amount.value || 0);
    const minBonusValue = computed(()=>bonusStore.min);
    const fastChoiceValue = computed(()=>selectedChoice.value);
    const maxValueFastChoice = computed(()=>{
        if (maxBonusValue.value === minBonusValue.value) return 0;
        return maxBonusValue.value || 0;
    });
    const money = computed(()=>formatMoney(convertBonusToMoney(bonusValue.value), {
            minimumFractionDigits: 0
        }));
    const isValidInput = (value, minValue, maxValue)=>value >= minValue && value <= maxValue;
    const bonusButtonProps = computed(()=>({
            label: buttonLabel.value,
            disabled: isButtonDisabled.value,
            kind: ButtonKind.PRIMARY,
            fullWidth: true,
            isLoading: buttonState.value,
            isUppercase: false
        }));
    const buttonProps = computed(()=>({
            label: $translate('JSP_PCL_FBOT_CLOSE').value,
            kind: ButtonKind.BASE,
            fullWidth: true
        }));
    const jumbotronProps = computed(()=>({
            icon: IconName.CHECK_OUTLINE,
            iconSize: IconSize.SIZE_80,
            iconKind: JumbotronIconKind.SUCCESS,
            heading: `${$translate('WEB2_YOU_EXCHANGE').value} ${formatMoney(bonusValue.value, {
                currency: 'L'
            })}`,
            text: $translate('WEB2_CREDITED_TO_THE_ACCOUNT').value
        }));
    const fastChoicesOptions = computed(()=>{
        let fastChoices = [
            {
                label: '10000 L',
                value: 10000
            },
            {
                label: '20000 L',
                value: 20000
            },
            {
                label: '30000 L',
                value: 30000
            }
        ];
        if (suggestedAmounts.value) {
            const suggestedAmountArray = suggestedAmounts.value.find((item)=>item.currency === formattedCurrencyInner);
            if (suggestedAmountArray?.depositAmounts?.length) fastChoices = suggestedAmountArray.depositAmounts.map((item)=>({
                    label: `${item} ${formattedCurrencyInner}`,
                    value: item
                }));
        }
        return fastChoices;
    });
    async function onComponentCreated() {
        buttonLabel.value = $translate('BONUS_WIDGET_EXCHANGE_BUTTON').value.toUpperCase();
        await bonusStore.fetchEncashBonusData();
        await bonusLoyaltyStore.fetchBonusLoyalty();
        isShowSuccess.value = false;
        handleInput(minBonusValue.value);
    }
    function handleInputValue(value) {
        if (isValidInput(value, minBonusValue.value, maxBonusValue.value)) {
            bonusValue.value = value;
            isButtonDisabled.value = false;
            buttonLabel.value = `${$translate('WEB2_EXCHANGE_FOR').value.toUpperCase()} ${formatMoney(convertBonusToMoney(value), {
                minimumFractionDigits: 0,
                hideCurrency: true
            })} ${formattedCurrency.value}`;
        } else {
            bonusValue.value = value;
            buttonLabel.value = $translate('BONUS_WIDGET_EXCHANGE_BUTTON').value.toUpperCase();
            isButtonDisabled.value = true;
        }
    }
    function updatePaymentsInputValue(param) {
        let { value } = param;
        paymentsInputValue.value = 'string' == typeof value ? value : value.toString();
    }
    function handleInput(value) {
        selectedChoice.value = value;
        updatePaymentsInputValue({
            value
        });
    }
    function onChange(payload) {
        const { value } = payload.target;
        const valueAsNumber = parseInt(value, 10);
        selectedChoice.value = parseInt(value, 10);
        handleInputValue(valueAsNumber);
    }
    function resetButton() {
        selectedChoice.value = 0;
        paymentsInputValue.value = '0';
        handleInputValue(0);
        buttonLabel.value = $translate('BONUS_WIDGET_EXCHANGE_BUTTON').value.toUpperCase();
        buttonState.value = false;
    }
    function handleClick() {
        resetButton();
        isShowSuccess.value = false;
        router.replace({
            name: RouteName.LOYALTY_PROGRAM
        });
    }
    async function onEncashBonus() {
        if (minBonusValue.value > maxBonusValue.value) {
            showDialog({
                presetName: PresetName.ALERT_WARNING,
                options: {
                    title: $translate('WEB2_UNAVAILABLE_OPERATION').value,
                    dataTestId: 'onencash-bonus-unavailable'
                }
            });
            return;
        }
        let hasError = false;
        const modalProperties = {
            presetName: PresetName.ALERT_SUCCESS,
            options: {
                modalComponent: DialogComponent.BonusWithdrawalModal,
                fullHeight: false,
                isFullHeightCentered: false,
                title: `${$translate('WEB2_YOU_EXCHANGE').value} ${formatMoney(bonusValue.value, {
                    currency: 'L'
                })}`,
                confirmMessage: $translate('WEB2_CREDITED_TO_THE_ACCOUNT').value,
                money: money.value,
                buttons: [
                    {
                        kind: ButtonKind.BASE
                    }
                ]
            }
        };
        if (bonusValue.value > 0) {
            try {
                buttonLabel.value = $translate('WEB2_PROCESSING').value.toUpperCase();
                buttonState.value = true;
                await bonusStore.encashBonus(bonusValue.value);
            } catch (rawError) {
                const error = normalizeError(rawError);
                hasError = true;
                modalProperties.options.confirmMessage = error.message;
                delete modalProperties.options.money;
                modalProperties.options.title = $translate('JS_CAPTION_ATTENTION').value;
                modalProperties.presetName = PresetName.ALERT_WARNING;
                modalProperties.options.buttons = [
                    {
                        kind: ButtonKind.PRIMARY
                    }
                ];
            }
            await loadUserData();
            await customerHistoryStore.updateProfileHistoryList();
            await bonusLoyaltyStore.fetchBonusLoyalty();
            if (hasError) showDialog(modalProperties).promise.then(()=>{
                resetButton();
            });
            else isShowSuccess.value = true;
        }
    }
    return {
        amount,
        maxBonusValue,
        minBonusValue,
        bonusButtonProps,
        buttonProps,
        paymentsInputValue,
        fastChoiceValue,
        maxValueFastChoice,
        jumbotronProps,
        money,
        fastChoicesOptions,
        isShowSuccess,
        onComponentCreated,
        handleClick,
        onChange,
        onEncashBonus,
        handleInputValue,
        handleInput,
        updateProfileHistoryList: customerHistoryStore.updateProfileHistoryList
    };
}
