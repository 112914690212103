import { computed } from 'vue';
import { CasinoIconName } from '@leon-hub/icons';
export default function useProfileBonusesCardHeader(props) {
    const badgeProps = computed(()=>({
            label: props.badgeText,
            iconName: CasinoIconName.CASINO_CHERRY
        }));
    const hasBadge = computed(()=>!!props.badgeText);
    return {
        badgeProps,
        hasBadge
    };
}
