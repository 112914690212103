import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { BetLine } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { currentScore, defaultDateTimeFormat, formatInnings, numberToDouble } from 'web/src/modules/profile/submodules/customer-history/utils';
// eslint-disable-next-line max-len
export default function useCustomerHistoryListItemCombiBet(props) {
    const router = useRouter();
    const league = computed(()=>props.item?.league || '');
    const isLive = computed(()=>props.item?.betLine === BetLine.LIVE);
    const status = computed(()=>props.item?.outcomeStateText || '');
    const eventDescription = computed(()=>props.item.eventParts ? [
            ...props.item.eventParts
        ] : []);
    const isCoefficient = computed(()=>!!props.item?.price);
    const coefficient = computed(()=>props.item?.priceStr ?? numberToDouble(props.item?.price));
    const banker = computed(()=>!!props.item.banker);
    const date = computed(()=>defaultDateTimeFormat(props.item.kickoff));
    const predictionLabel = computed(()=>props.item?.market || '');
    const predictionChoice = computed(()=>props.item.outcome || '');
    const score = computed(()=>{
        const { liveStatus, matchResult } = props.item;
        if (matchResult?.score) return matchResult.score;
        if (liveStatus?.score) return currentScore(liveStatus.score);
        return null;
    });
    const homeCompetitor = computed(()=>score.value?.home ? formatInnings(score.value.home) : '');
    const awayCompetitor = computed(()=>score.value?.away ? formatInnings(score.value.away) : '');
    const eventUrl = computed(()=>{
        const { eventUrl: url } = props.item;
        if (!url?.eventId || !url.league || !url.region || !url.sport || !url.event) return null;
        return {
            id: `${url.eventId}`,
            leagueUrlName: url.league,
            regionUrlName: url.region,
            sportFamily: url.sport,
            urlName: url.event
        };
    });
    const isVirtual = computed(()=>props.item.betLine === BetLine.VSPORTS);
    function goToEvent() {
        if (eventUrl.value && !isVirtual.value) router.push({
            name: RouteName.SPORT_EVENT_DETAILS,
            params: eventUrl.value
        });
    }
    return {
        league,
        isLive,
        status,
        eventDescription,
        isCoefficient,
        coefficient,
        banker,
        date,
        predictionLabel,
        predictionChoice,
        score,
        homeCompetitor,
        awayCompetitor,
        eventUrl,
        goToEvent
    };
}
