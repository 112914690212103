import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { AvailableBonusType, BonusStep, CustomerBonusState, RequirementType, RequirementValueType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config';
import { usePaymentsDepositsNavigation } from 'web/src/modules/core/composables/navigation';
import { ButtonActionType, InfoStatusType } from 'web/src/modules/profile/submodules/bonuses/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { buttonDisabledLoadingState, getBonusBalance, getBonusTermsLink, isComplexBonus } from 'web/src/modules/profile/submodules/bonuses/utils';
import { ProgressKind } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default function useProfileBonusesGeneralController(props, emit) {
    const { $translate } = useI18n();
    const router = useRouter();
    const $formatMoney = useFormatMoney();
    const { pushToDepositsPage } = usePaymentsDepositsNavigation();
    const isRequirementsStep = computed(()=>props.bonus.step === BonusStep.REQUIREMENTS);
    const isFreebetInProgress = computed(()=>!!props.bonus?.freebet?.totalActivated);
    const depositRequirement = computed(()=>props.bonus.requirements.find((requirement)=>requirement.type === RequirementType.DEPOSIT));
    const isDescriptionVisible = computed(()=>{
        const isRequiredValue = !!Number(depositRequirement.value?.requiredValue);
        return isRequirementsStep.value && props.bonus.refuseAvailable && !!depositRequirement.value && isRequiredValue;
    });
    const descriptionText = computed(()=>{
        if (depositRequirement.value?.requiredValue) {
            const { valueType, requiredValue } = depositRequirement.value;
            let amount = '';
            if (valueType === RequirementValueType.MONEY) amount = $formatMoney(Number(requiredValue));
            else if (valueType === RequirementValueType.COUNT) amount = requiredValue || '';
            return $translate('WEB2_BONUSES_MAKE_DEPOSIT_FROM', ref({
                amount
            })).value;
        }
        return '';
    });
    const closeActiveBonusRequirement = computed(()=>props.bonus.requirements.find((requirement)=>requirement.type === RequirementType.CLOSE_ACTIVE_BONUS));
    const identificationRequirement = computed(()=>props.bonus.requirements.find((requirement)=>requirement.type === RequirementType.IDENTIFICATION));
    const confirmationRequirement = computed(()=>props.bonus.requirements.find((requirement)=>requirement.type === RequirementType.CONFIRMATION));
    const bonusCodeConfirmationRequirement = computed(()=>props.bonus.requirements.find((requirement)=>requirement.type === RequirementType.BONUS_CODE_CONFIRMATION));
    const depositBonusCodeRequirement = computed(()=>props.bonus.requirements.find((requirement)=>requirement.type === RequirementType.DEPOSIT_BONUS_CODE));
    const isWagerFreebet = computed(()=>!isRequirementsStep.value && props.bonus.availableBonusType === AvailableBonusType.FREEBET);
    const buttonColor = computed(()=>{
        if (props.bonusState === CustomerBonusState.AVAILABLE) return ButtonKind.MONOCHROME;
        return ButtonKind.PRIMARY;
    });
    const isComplexBonusValue = computed(()=>isComplexBonus(props.bonus));
    const termsLink = computed(()=>getBonusTermsLink(props.bonus));
    const layoutProperties = computed(()=>({
            bonusTitle: props.bonus.campaignName,
            stepNumber: props.bonus.stepNumber,
            stepCount: isComplexBonusValue.value ? 0 : props.bonus.stepCount,
            termsLink: termsLink.value
        }));
    const bonusBalance = computed(()=>{
        const balance = isWagerFreebet.value ? props.bonus.transferAmount || props.bonus.bonusAmount || props.bonus.maxBonusAmount || 0 : getBonusBalance(props.bonus);
        return $formatMoney(balance);
    });
    const bonusBalanceTitle = computed(()=>{
        if (props.bonus.transferAmount) return $translate('WEB2_BONUSES_AMOUNT').value;
        switch(props.bonus.availableBonusType){
            case AvailableBonusType.FREEBET:
                return $translate('WEB2_BONUSES_FREEBET_AMOUNT').value;
            case AvailableBonusType.MAX_BONUS_AMOUNT:
                return $translate('WEB2_BONUSES_MAX_BONUS_AMOUNT').value;
            case AvailableBonusType.BONUS_AMOUNT:
                return $translate('WEB2_BONUSES_AMOUNT').value;
            case AvailableBonusType.FREESPIN:
                return $translate('WEB2_BONUSES_NOMINAL').value;
            default:
                return '';
        }
    });
    const freespinAvailable = computed(()=>{
        if (props.bonus.availableBonusType === AvailableBonusType.FREESPIN) {
            let value = `${props.bonus.freespin?.totalCount || props.bonus.freespin?.count || 0}`;
            if (props.bonus.freespin?.usedCount) value = `${props.bonus.freespin.usedCount}/${props.bonus.freespin?.totalCount || 0}`;
            return {
                title: $translate('WEB2_BONUSES_FREESPIN').value,
                value
            };
        }
        return null;
    });
    const freebetAvailable = computed(()=>{
        if (props.bonus.availableBonusType === AvailableBonusType.FREEBET) {
            let value = `${props.bonus.bonusNumber || 0}/`;
            value += `${props.bonus.freebet?.totalAvailable || props.bonus.maxBonusCount || 0}`;
            return {
                title: $translate('WEB2_BONUSES_FREEBET_AVAILABLE').value,
                value
            };
        }
        return null;
    });
    const oneColumnProperties = computed(()=>({
            descriptionTitle: bonusBalanceTitle.value,
            descriptionText: bonusBalance.value
        }));
    const twoColumnsProperties = computed(()=>{
        switch(props.bonus.availableBonusType){
            case AvailableBonusType.BONUS_AMOUNT:
            case AvailableBonusType.MAX_BONUS_AMOUNT:
                return {
                    titleLeft: $translate('WEB2_BONUSES_FREEBET_AMOUNT').value,
                    valueLeft: oneColumnProperties.value.descriptionText,
                    titleRight: $translate('WEB2_BONUSES_FREEBET_AVAILABLE').value,
                    valueRight: `${props.bonus.bonusNumber}/${props.bonus.maxBonusCount}`
                };
            case AvailableBonusType.FREEBET:
                return {
                    titleLeft: bonusBalanceTitle.value,
                    valueLeft: bonusBalance.value,
                    titleRight: freebetAvailable.value?.title || '',
                    valueRight: freebetAvailable.value?.value || ''
                };
            case AvailableBonusType.FREESPIN:
                return {
                    titleLeft: freespinAvailable.value?.title || '',
                    valueLeft: freespinAvailable.value?.value || '',
                    titleRight: bonusBalanceTitle.value,
                    valueRight: bonusBalance.value
                };
            default:
                break;
        }
    });
    function getFractionDigitsOptions(value1, value2) {
        const calculateFractionDigits = (num)=>Number.isInteger(num) && 0 !== num ? 0 : 2;
        const fractionDigits = Math.max(calculateFractionDigits(value1), calculateFractionDigits(value2));
        return {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        };
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const progressProperties = computed(()=>{
        const list = [];
        for (const requirement of props.bonus.requirements)// Если шаг REQUIREMENTS и dto DEPOSIT, не показываем прогресс бар, т.к. сумма отображается в кнопке
        if (!(requirement.type === RequirementType.DEPOSIT && isRequirementsStep.value)) {
            const { currentValue, requiredValue, valueType, progressBarValue, name, type } = requirement;
            // т.к. dto может придти по типу IDENTIFICATION, проверяем есть ли нужные значения для вывода
            if ('string' == typeof currentValue && 'string' == typeof requiredValue) {
                const fractionDigitsOptions = getFractionDigitsOptions(Number(currentValue), Number(requiredValue));
                let total = $formatMoney(Number(requiredValue), {
                    ...fractionDigitsOptions
                });
                if (valueType === RequirementValueType.COUNT) total = $translate('WEB2_BONUS_WIDGET_BETS_REMAINING_COUNT', ref({
                    betsTotal: requiredValue
                })).value;
                list.push({
                    value: progressBarValue || 0,
                    current: type === RequirementType.BET_COUNT ? currentValue : $formatMoney(Number(currentValue), {
                        hideCurrency: true,
                        ...fractionDigitsOptions
                    }),
                    total,
                    stepName: name || '',
                    kind: ProgressKind.DEFAULT
                });
            }
        }
        if (list.length > 0) {
            // Отображать срок истечения только в последнем прогресс баре
            list[list.length - 1].expirationDate = props.bonus.availableBefore;
            return list;
        }
        return null;
    });
    const status = computed(()=>{
        let text;
        // Статус requirement завершения активной бонусной компании
        if (closeActiveBonusRequirement.value) text = closeActiveBonusRequirement.value.name || '';
        // Статус фрибета в игре
        if (isFreebetInProgress.value) text = $translate('WEB2_BONUSES_FREEBET_IN_PROGRESS').value;
        if (text) return {
            text,
            isLowerCase: true
        };
        return null;
    });
    const statusType = computed(()=>{
        if (isFreebetInProgress.value) return InfoStatusType.FREEBET_IN_PLAY;
        if (closeActiveBonusRequirement.value) return InfoStatusType.COMPLETE_ACTIVE_COMPAIGN;
        return null;
    });
    const depositButton = computed(()=>{
        const button = {
            id: ButtonActionType.DEPOSIT,
            label: $translate('WEB2_BONUSES_DEPOSIT_BUTTON').value.toUpperCase(),
            isUppercase: false,
            kind: buttonColor.value
        };
        if (isRequirementsStep.value && depositRequirement.value?.requiredValue && !props.bonus.refuseAvailable) {
            const { valueType, requiredValue } = depositRequirement.value;
            button.label = $translate('WEB2_BONUSES_DEPOSIT_FROM_BUTTON').value.toUpperCase();
            if (valueType === RequirementValueType.MONEY) button.label += ` ${useFormatMoney()(Number(requiredValue))}`;
            else if (valueType === RequirementValueType.COUNT) button.label += ` ${requiredValue}`;
        }
        return button;
    });
    const depositBonusCodeButton = computed(()=>({
            id: ButtonActionType.DEPOSIT_BONUS_CODE,
            label: $translate('WEB2_BONUS_CODE_AND_DEPOSIT').value,
            kind: buttonColor.value
        }));
    const bonusesCardButton = computed(()=>{
        // Кнопка "Играть" - default
        let actionButton = {
            id: ButtonActionType.PLAY,
            label: $translate('WEB2_BONUSES_PLAY_BUTTON').value,
            kind: buttonColor.value
        };
        let hasColumns = false;
        let buttons;
        if (depositRequirement.value && !depositRequirement.value.satisfied) // Кнопка "Депозит" - подменяет кнопку "Играть", если условие верное
        actionButton = depositButton.value;
        else if (depositBonusCodeRequirement.value && !depositBonusCodeRequirement.value.satisfied) actionButton = depositBonusCodeButton.value;
        else if (identificationRequirement.value) {
            // Кнопка "Пройдите идентификацию" - подменяет кнопку "Играть", если условие верное
            actionButton.id = ButtonActionType.IDENTIFICATION;
            actionButton.label = $translate('WEB2_BONUSES_IDENTIFICATION_BUTTON').value;
        }
        buttons = [
            actionButton
        ];
        // Кнопка "Отказаться"
        if (props.bonus.refuseAvailable) {
            hasColumns = true;
            buttons = [
                ...buttons,
                {
                    id: ButtonActionType.REFUSE,
                    label: $translate('WEB2_BONUSES_REFUSE_BUTTON').value,
                    kind: ButtonKind.SECONDARY
                }
            ];
        }
        if (isRequirementsStep.value) {
            if (confirmationRequirement.value) {
                hasColumns = false;
                buttons = [
                    {
                        id: ButtonActionType.CONFIRMATION,
                        label: $translate('WEB2_TO_PARTICIPATE').value,
                        kind: buttonColor.value
                    }
                ];
            } else if (bonusCodeConfirmationRequirement.value) {
                hasColumns = false;
                buttons = [
                    {
                        id: ButtonActionType.BONUS_CODE_CONFIRMATION,
                        label: $translate('WEB2_TO_PARTICIPATE').value,
                        kind: buttonColor.value
                    }
                ];
            }
        }
        if (props.activeButton) buttons = buttons.map((button)=>({
                ...button,
                ...buttonDisabledLoadingState(props.activeButton, {
                    id: props.bonus.bonusId,
                    type: button.id || ''
                })
            }));
        if (isComplexBonusValue.value) {
            hasColumns = false;
            buttons = [
                {
                    id: ButtonActionType.COMPLEX,
                    label: $translate('WEB2_BONUSES_COMPLEX_GET').value,
                    kind: ButtonKind.MONOCHROME
                }
            ];
        }
        return {
            hasColumns,
            buttons
        };
    });
    const hasTwoColumns = computed(()=>{
        // case LEONWEB-8195
        if (isWagerFreebet.value) return false;
        return !!freespinAvailable.value || !!freebetAvailable.value || !!props.bonus.freebet;
    });
    const hasProgress = computed(()=>{
        if (!props.bonus.requirements.length || !progressProperties.value?.length) return false;
        if (1 === props.bonus.requirements.length) {
            const { type } = props.bonus.requirements[0];
            // Не показывать dto DEPOSIT прогресс бар, если шаг BonusStep.REQUIREMENTS, т.к. сумма отображается в кнопке
            const isDepositDto = type === RequirementType.DEPOSIT && isRequirementsStep.value;
            const isCloseActiveBonusDto = type === RequirementType.CLOSE_ACTIVE_BONUS;
            return !(isDepositDto || isCloseActiveBonusDto);
        }
        return true;
    });
    function handleButtonClick(id) {
        switch(id){
            case ButtonActionType.PLAY:
                emit('play', props.bonus);
                break;
            case ButtonActionType.DEPOSIT:
            case ButtonActionType.DEPOSIT_BONUS_CODE:
                pushToDepositsPage({
                    query: {
                        campaignId: props.bonus.campaignId.toString()
                    }
                });
                break;
            case ButtonActionType.IDENTIFICATION:
                router.push({
                    name: RouteName.IDENTIFICATION
                });
                break;
            case ButtonActionType.REFUSE:
                emit('refuse', props.bonus);
                break;
            case ButtonActionType.COMPLEX:
                emit('complex', props.bonus);
                break;
            case ButtonActionType.CONFIRMATION:
                emit('confirmation', props.bonus);
                break;
            case ButtonActionType.BONUS_CODE_CONFIRMATION:
                router.push({
                    name: RouteName.BONUS_CODE_ENTER
                });
                break;
            default:
                break;
        }
    }
    return {
        statusType,
        layoutProperties,
        isComplexBonusValue,
        hasTwoColumns,
        oneColumnProperties,
        twoColumnsProperties,
        isDescriptionVisible,
        descriptionText,
        hasProgress,
        progressProperties,
        closeActiveBonusRequirement,
        isFreebetInProgress,
        bonusesCardButton,
        status,
        handleButtonClick
    };
}
