import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { ButtonKind } from '@leon-hub/module-buttons';
import RouteName from '@leon-hub/routing-config-names';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingTimeInGameInfoRoutePage',
    setup (__props) {
        const { $translate } = useI18n();
        const router = useRouter();
        const closeInfoPageButtonProps = computed(()=>({
                label: $translate('RG_TIME_IN_GAME_INFO_BUTTON').value,
                kind: ButtonKind.PRIMARY,
                fullWidth: true
            }));
        const handleClose = ()=>{
            router.replace({
                name: RouteName.RESPONSIBLE_GAMBLING_TIME_IN_GAME
            });
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['time-in-game-info'])
            }, [
                _createElementVNode("img", {
                    class: "time-in-game-info__image",
                    src: require("web/src/modules/profile/submodules/responsible-gambling/pages/ResponsibleGamblingTimeInGameRoutePage/images/li/background.svg"),
                    alt: ""
                }, null, 8, _hoisted_1),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['time-in-game-info__content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['time-in-game-info__title'])
                    }, _toDisplayString(_ctx.$t('RG_TIME_IN_GAME_INFO_TITLE')), 3),
                    _createVNode(VDynamicContent, {
                        class: _normalizeClass(_ctx.$style['time-in-game-info__description']),
                        content: _ctx.$t('RG_TIME_IN_GAME_INFO_DESCRIPTION'),
                        silent: ""
                    }, null, 8, [
                        "class",
                        "content"
                    ]),
                    _createVNode(VButton, _mergeProps({
                        class: _ctx.$style['time-in-game__button']
                    }, closeInfoPageButtonProps.value, {
                        onClick: handleClose
                    }), null, 16, [
                        "class"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingTimeInGameInfoRoutePage'
                ]
            ]);
        };
    }
});
