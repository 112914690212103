import { IconName } from '@leon-hub/icons';
import { ButtonActionType } from 'web/src/modules/profile/submodules/bonuses/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { buttonDisabledLoadingState } from 'web/src/modules/profile/submodules/bonuses/utils';
import getHasTransferAmount from './getHasTransferAmount';
import getIsRefuseAvailable from './getIsRefuseAvailable';
export default function getBonusButtons(props, $t) {
    let buttons = [];
    const hasTransferAmount = getHasTransferAmount(props);
    const isRefuseAvailable = getIsRefuseAvailable(props);
    if (props.bonus?.freebet?.totalAvailable) buttons = [
        {
            id: ButtonActionType.PLAY,
            label: $t('WEB2_BONUSES_PLAY_BUTTON').value,
            kind: ButtonKind.PRIMARY
        }
    ];
    if (hasTransferAmount) buttons = [
        ...buttons,
        {
            id: ButtonActionType.COLLECT,
            label: $t('WEB2_BONUSES_COLLECT_BUTTON').value,
            kind: ButtonKind.PRIMARY,
            iconName: IconName.CHECK_FILLED,
            iconRight: false
        }
    ];
    else if (isRefuseAvailable) buttons = [
        ...buttons,
        {
            id: ButtonActionType.REFUSE,
            label: $t('WEB2_BONUSES_REFUSE_BUTTON').value,
            kind: ButtonKind.SECONDARY
        }
    ];
    if (props.activeButton) buttons = buttons.map((button)=>({
            ...button,
            ...buttonDisabledLoadingState(props.activeButton, {
                id: props.bonus.bonusId,
                type: button.id ?? ''
            })
        }));
    return buttons;
}
