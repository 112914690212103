import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { AlertIconName, IconName } from '@leon-hub/icons';
import { BonusCampaignType, CustomerBonusState, RefuseBonusType } from '@leon-hub/api-sdk';
import { Timer } from '@leon-hub/utils';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { CasinoRouteName } from '@leon-hub/routing-config-names';
import RouteName from '@leon-hub/routing-config';
import { normalizeError } from '@leon-hub/errors';
import { getJumbotronProps } from 'web/src/components/Jumbotron';
import { ButtonActionType, ProfileBonusesActiveTab, ProfileButtonsTab } from 'web/src/modules/profile/submodules/bonuses/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useProfileBonusesStore } from 'web/src/modules/profile/submodules/bonuses/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import useNavigationToGameDetails from 'web/src/modules/profile/submodules/bonuses/controllers/composables/useNavigationToGameDetails';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { usePromotionsStore } from 'web/src/modules/promotions/store';
export default function useProfileBonusesRouteComponent() {
    const { $translate } = useI18n();
    const router = useRouter();
    const profileBonusesStore = useProfileBonusesStore();
    const siteConfigStore = useSiteConfigStore();
    const promotionsStore = usePromotionsStore();
    const { showDialog } = useDialogs();
    const $formatMoney = useFormatMoney();
    const analytics = useAnalytics();
    let pollingTimeout = 0;
    const loading = toRef(profileBonusesStore, 'loading');
    const sportBonuses = toRef(profileBonusesStore, 'sportBonuses');
    const egsBonuses = toRef(profileBonusesStore, 'egsBonuses');
    const bonusCampaignType = toRef(profileBonusesStore, 'bonusCampaignType');
    const complexData = toRef(profileBonusesStore, 'complexData');
    const isBonusPageVisited = toRef(profileBonusesStore, 'isBonusPageVisited');
    const isOnboardingIconShown = toRef(profileBonusesStore, 'isOnboardingIconShown');
    const activeTab = toRef(profileBonusesStore, 'activeTab');
    const waitingRefreshDelay = toRef(profileBonusesStore, 'waitingRefreshDelay');
    const bonusesPolling = toRef(siteConfigStore, 'bonusesPolling');
    const delayBeforeNewBonusesListRequest = ref();
    const activeButton = ref(null);
    const withdrawnAmount = ref(null);
    const jumbotronProperties = ref(null);
    const complexCampaignId = ref();
    const switcherIsShown = computed(()=>!complexData.value && true);
    const list = computed(()=>{
        const activeBonuses = [];
        const availableBonuses = [];
        let bonusesList = [
            ...sportBonuses.value
        ];
        if (activeTab.value === ProfileButtonsTab.EGS) bonusesList = [
            ...egsBonuses.value
        ];
        for (const bonus of bonusesList)if (bonus.bonusState === CustomerBonusState.ACTIVE) activeBonuses.push(bonus);
        else availableBonuses.push(bonus);
        let result = [];
        if (activeBonuses.length) result = [
            {
                title: $translate('WEB2_BONUSES_ACTIVE').value,
                bonusesList: activeBonuses,
                bonusState: CustomerBonusState.ACTIVE
            }
        ];
        if (availableBonuses.length) result = [
            ...result,
            {
                title: $translate('WEB2_BONUSES_AVAILABLE').value,
                bonusesList: availableBonuses,
                bonusState: CustomerBonusState.AVAILABLE
            }
        ];
        return result.length ? result : null;
    });
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function beforeMount() {
        delayBeforeNewBonusesListRequest.value = siteConfigStore.delayBeforeNewBonusesListRequest;
        profileBonusesStore.setActiveTab(profileBonusesStore.initialTab);
        "1";
        {
            const category = router.getQuery('category');
            if (category === ProfileBonusesActiveTab.SPORT) profileBonusesStore.setActiveTab(ProfileButtonsTab.SPORTS);
            else if (category === ProfileBonusesActiveTab.GAME) profileBonusesStore.setActiveTab(ProfileButtonsTab.EGS);
            else if (egsBonuses.value.length || !sportBonuses.value.length) profileBonusesStore.setActiveTab(ProfileButtonsTab.EGS);
            else profileBonusesStore.setActiveTab(ProfileButtonsTab.SPORTS);
        }
        // eslint-disable-next-line max-len
        // eslint-disable-next-line max-len
        if (bonusesPolling.value) pollingTimeout = Timer.setInterval(fetchCurrentBonuses, bonusesPolling.value);
        if (!isOnboardingIconShown.value) profileBonusesStore.switchOnboardingTopBarIcon(true);
        if (!isBonusPageVisited.value) {
            analytics.push(AnalyticsEvent.Z_VISIT_BONUSES_PAGE, {
                bonuses: 'visitBonusesPage'
            });
            profileBonusesStore.setIsBonusesPageVisited(true);
        }
    }
    function beforeUnmount() {
        profileBonusesStore.setComplexData(null);
        if (pollingTimeout) {
            Timer.clearInterval(pollingTimeout);
            pollingTimeout = 0;
        }
    }
    async function fetchCurrentBonuses() {
        if (!waitingRefreshDelay.value) {
            let type;
            switch(activeTab.value){
                case ProfileButtonsTab.SPORTS:
                    type = BonusCampaignType.SPORT;
                    break;
                case ProfileButtonsTab.EGS:
                    type = BonusCampaignType.EGS;
                    break;
                default:
                    type = void 0;
                    break;
            }
            await profileBonusesStore.fetchBonuses(type);
        }
    }
    function handlePlay(bonus) {
        if (bonus.campaignType === BonusCampaignType.EGS) {
            if (bonus.freespin?.provider) {
                useNavigationToGameDetails(router).routeToGameDetails(bonus);
                return;
            }
            router.push({
                name: CasinoRouteName.CASINO_LOBBY
            });
        } else router.push({
            name: RouteName.SPORTLINE_TOP_EVENTS
        });
    }
    function handleModalClose() {
        if (!isOnboardingIconShown.value) profileBonusesStore.switchOnboardingTopBarIcon(true);
        activeButton.value = null;
        jumbotronProperties.value = null;
    }
    function showCollectModal(data) {
        const { bonus } = data;
        activeButton.value = {
            id: bonus.bonusId,
            type: ButtonActionType.COLLECT
        };
        "1";
        jumbotronProperties.value = {
            main: {
                heading: $translate('WEB2_BONUS_OUTPUT').value,
                text: $translate('WEB2_BONUS_OUTPUT_QUESTION', ref({
                    AMOUNT: `${$formatMoney(bonus.transferAmount || 0)}`
                })).value,
                ...getJumbotronProps({
                    alertIcon: AlertIconName.Bonus,
                    icon: IconName.ATTENTION_OUTLINE
                })
            },
            button: {
                label: $translate('WEB2_MODAL_CONFIRM').value,
                fullWidth: true
            },
            buttonClose: {
                label: $translate('WEB2_MODAL_CANCEL').value,
                fullWidth: true,
                kind: ButtonKind.TRANSPARENT
            },
            submit: ()=>{
                handleCollectSubmit(data);
            }
        };
    }
    async function showRefuseConfirmModal(bonus) {
        const { bonusId, campaignType } = bonus;
        activeButton.value = {
            id: bonusId,
            type: ButtonActionType.REFUSE
        };
        try {
            profileBonusesStore.switchOnboardingTopBarIcon(false);
            const refuseBonus = await profileBonusesStore.refuseBonus({
                bonusId,
                campaignType,
                preview: true
            });
            const { title, confirmMessage } = getRefuseTranslations(refuseBonus);
            "1";
            // В десктопной версии показываем jumbotron по причине того, что контент внутри модала может иметь высоту больше, чем parent окно
            jumbotronProperties.value = {
                main: {
                    ...getJumbotronProps({
                        alertIcon: AlertIconName.QuestionMark,
                        icon: IconName.ATTENTION_OUTLINE
                    }),
                    heading: title,
                    text: confirmMessage
                },
                button: {
                    label: $translate('WEB2_BONUSES_REFUSE_BUTTON').value,
                    fullWidth: true
                },
                buttonClose: {
                    label: $translate('WEB2_MODAL_CANCEL').value,
                    fullWidth: true,
                    kind: ButtonKind.TRANSPARENT
                },
                submit: ()=>{
                    handleRefuseSubmit(bonus);
                }
            };
        } catch (rawError) {
            const error = normalizeError(rawError);
            showErrorModal(error.message);
        }
    }
    function handleComplexBonus(data) {
        complexCampaignId.value = data.campaignId;
        profileBonusesStore.setComplexData({
            bonusId: data.bonusId,
            bonusCampaignType: data.campaignType
        });
    }
    async function handleConfirmation(data) {
        profileBonusesStore.setWaitingRefreshDelay(true);
        activeButton.value = {
            id: data.bonusId,
            type: ButtonActionType.CONFIRMATION
        };
        await promotionsStore.bonusParticipation({
            actionId: data.campaignId.toString(),
            lbType: data.campaignType
        });
        await fetchBonusesDelayed();
    }
    async function fetchBonusesDelayed() {
        if (delayBeforeNewBonusesListRequest.value) {
            Timer.clearInterval(pollingTimeout);
            Timer.setTimeout(async ()=>{
                await profileBonusesStore.fetchBonuses(bonusCampaignType.value);
                profileBonusesStore.setWaitingRefreshDelay(false);
                if (bonusesPolling.value) pollingTimeout = Timer.setInterval(fetchCurrentBonuses, bonusesPolling.value);
                activeButton.value = null;
            }, 1000 * delayBeforeNewBonusesListRequest.value);
        } else {
            profileBonusesStore.setWaitingRefreshDelay(false);
            await profileBonusesStore.fetchBonuses(bonusCampaignType.value);
            activeButton.value = null;
        }
    }
    async function handleCollectSubmit(data) {
        profileBonusesStore.setWaitingRefreshDelay(true);
        jumbotronProperties.value = null;
        try {
            await profileBonusesStore.collectBonus(data);
            if (data.bonus?.transferAmount) withdrawnAmount.value = $formatMoney(data.bonus?.transferAmount);
            await fetchBonusesDelayed();
        } catch (rawError) {
            profileBonusesStore.setWaitingRefreshDelay(false);
            const error = normalizeError(rawError);
            "1";
            jumbotronProperties.value = {
                main: {
                    heading: $translate('WEB2_DEPFAIL_TITLE').value,
                    text: error.message,
                    icon: IconName.ATTENTION_OUTLINE
                },
                buttonClose: {
                    label: $translate('WEB2_CLOSE').value,
                    fullWidth: true,
                    kind: ButtonKind.PRIMARY
                }
            };
            activeButton.value = null;
        }
    }
    async function handleRefuseSubmit(bonus) {
        const { bonusId, campaignType, campaignName, stepCount, stepNumber } = bonus;
        if (jumbotronProperties.value?.button) jumbotronProperties.value = {
            ...jumbotronProperties.value,
            button: {
                ...jumbotronProperties.value.button,
                isLoading: true
            }
        };
        try {
            await profileBonusesStore.refuseBonus({
                bonusId,
                campaignType,
                preview: false
            });
            await profileBonusesStore.fetchBonuses(profileBonusesStore.bonusCampaignType);
            await useFrontNotificationsStore().getUnreadNotifications();
            analytics.push(AnalyticsEvent.Z_BONUS_REJECTION, {
                bonuses: {
                    bonusRejection: {
                        [campaignName]: `${stepNumber}/${stepCount}`
                    }
                }
            });
            "1";
            jumbotronProperties.value = {
                main: {
                    heading: $translate('WEB2_BONUSES_REFUSE_SUCCESS_TITLE').value,
                    text: $translate('WEB2_BONUSES_REFUSE_SUCCESS_DESCRIPTION').value,
                    icon: IconName.CHECK_OUTLINE,
                    iconKind: JumbotronIconKind.SUCCESS
                },
                buttonClose: {
                    label: $translate('WEB2_CLOSE').value,
                    fullWidth: true,
                    kind: ButtonKind.BASE
                }
            };
        } catch (rawError) {
            const error = normalizeError(rawError);
            showErrorModal(error.message);
        }
    }
    function showErrorModal(message) {
        "1";
        jumbotronProperties.value = {
            main: {
                heading: $translate('WEB2_BONUSES_REFUSE_FAILED_TITLE').value,
                text: message,
                icon: IconName.ATTENTION_OUTLINE
            },
            buttonClose: {
                label: $translate('WEB2_CLOSE').value,
                fullWidth: true,
                kind: ButtonKind.SECONDARY
            }
        };
    }
    function handleCloseSuccessModal() {
        withdrawnAmount.value = null;
        activeButton.value = null;
    }
    function handleRedirectToPromotions() {
        router.push({
            name: RouteName.PROMOTIONS
        });
    }
    function getRefuseTranslations(refuseBonus) {
        const data = {
            title: $translate('WEB2_BCAMPAIGN_OPT_OUT_CONFIRMATION_TITLE').value,
            confirmMessage: ''
        };
        switch(refuseBonus.refuseType){
            case RefuseBonusType.ONLY_WRITEOFF:
                data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_FREEBET_WRITEOFF', ref({
                    amount: $formatMoney(refuseBonus.writeOffAmount || 0)
                })).value;
                break;
            case RefuseBonusType.WRITEOFF_AND_DEPOSIT_RETURN:
                data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_WRITEOFF_AND_DEPOSIT_RETURN', ref({
                    depositAmount: $formatMoney(refuseBonus.returnDepositAmount || 0),
                    writeOffAmount: $formatMoney(refuseBonus.writeOffAmount || 0)
                })).value;
                break;
            case RefuseBonusType.FREEBET_CANCEL:
                data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_FREEBET_CANCEL', ref({
                    amount: $formatMoney(refuseBonus.freebetAmount || 0)
                })).value;
                break;
            case RefuseBonusType.FREESPIN_CANCEL:
                data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_FREESPIN_CANCEL', ref({
                    amount: refuseBonus?.freespinCount?.toString() || '0'
                })).value;
                break;
            default:
                data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_NONE').value;
                break;
        }
        return data;
    }
    function onHavePromoClick() {
        router.push({
            name: RouteName.BONUS_CODE_ENTER
        });
    }
    return {
        loading,
        complexData,
        activeTab,
        activeButton,
        withdrawnAmount,
        jumbotronProperties,
        complexCampaignId,
        switcherIsShown,
        list,
        beforeMount,
        onHavePromoClick,
        beforeUnmount,
        fetchCurrentBonuses,
        handlePlay,
        showCollectModal,
        showRefuseConfirmModal,
        handleComplexBonus,
        handleConfirmation,
        handleModalClose,
        handleCloseSuccessModal,
        handleRedirectToPromotions
    };
}
