import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
export default function useProfileBonusesCardDescriptionColumn(props) {
    const iconProps = {
        name: IconName.CROSS,
        size: IconSize.SIZE_10
    };
    const badgeProps = computed(()=>({
            label: props.badgeCount > 0 ? String(props.badgeCount) : '0',
            kind: BadgeKind.DEFAULT
        }));
    return {
        iconProps,
        badgeProps
    };
}
