import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "verify-page"
};
const _hoisted_2 = {
    class: "verify-page__submit-button"
};
const _hoisted_3 = {
    class: "verify-page__requirements"
};
import RouteName from '@leon-hub/routing-config-names';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { VForm } from 'web/src/components/Form';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import useCustomerVerificationInternal from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/verification/components/Internal/composables/useCustomerVerificationInternal';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerVerificationInternal',
    setup (__props) {
        const { activateButtonProps, schema, uiSchema, errors, isLoading, onSubmit, onChange, buttonClickSubmit, form } = useCustomerVerificationInternal();
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _unref(schema) ? (_openBlock(), _createBlock(_unref(VForm), {
                    key: 0,
                    ref_key: "form",
                    ref: form,
                    schema: _unref(schema),
                    "ui-schema": _unref(uiSchema),
                    "custom-errors": _unref(errors),
                    "is-pending": _unref(isLoading),
                    onSubmit: _unref(onSubmit),
                    onChange: _unref(onChange)
                }, null, 8, [
                    "schema",
                    "ui-schema",
                    "custom-errors",
                    "is-pending",
                    "onSubmit",
                    "onChange"
                ])) : _createCommentVNode("", true),
                _createVNode(_unref(TextSlotPlaceholder), {
                    class: "verify-page__link",
                    text: _ctx.$t('WEB2_VERIFICATION_UPLOAD_DOCUMENTS_WARNING')
                }, {
                    requirements: _withCtx(()=>[
                            _createVNode(_component_router_link, {
                                to: {
                                    name: _unref(RouteName).CMS_VERIFICATION_REQUIREMENTS
                                }
                            }, {
                                default: _withCtx(()=>[
                                        _createTextVNode(_toDisplayString(_ctx.$t('WEB2_VERIFICATION_UPLOAD_DOCUMENTS_LINK')), 1)
                                    ]),
                                _: 1
                            }, 8, [
                                "to"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "text"
                ]),
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(VButton, _mergeProps(_unref(activateButtonProps), {
                        onClick: _unref(buttonClickSubmit)
                    }), null, 16, [
                        "onClick"
                    ])
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('WEB2_VERIFY_TEXT')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('WEB2_VERIFY_TEXT2')), 1)
                ])
            ])), [
                [
                    _directive_auto_id,
                    'CustomerVerificationInternal'
                ]
            ]);
        };
    }
});
