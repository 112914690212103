import { toRef, computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { HistoryFilter, TransactionGroup, UiAccountType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { useCustomerHistoryStore } from 'web/src/modules/profile/submodules/customer-history/store';
import { useCurrency } from 'web/src/modules/money/composables';
import CustomerHistoryDetailsEgs from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryDetailsEgs/CustomerHistoryDetailsEgs.vue';
import CustomerHistoryDetailsBet from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryDetailsBet/CustomerHistoryDetailsBet.vue';
import CustomerHistoryDetailsPayment from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryDetailsPayment/CustomerHistoryDetailsPayment.vue';
import { useCashoutStore } from 'web/src/modules/cashout/store';
import { useEventsSubscriptionsRequestStore } from 'web/src/modules/sportline/submodules/events-subscriptions/store';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { usePendingBetsStore } from 'web/src/modules/slip/submodules/pending-bets/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
function isUiAccountTypeKey(accountTypeKey) {
    return 'string' == typeof accountTypeKey && Object.keys(UiAccountType).includes(accountTypeKey);
}
export default function useCustomerHistoryDetailsRouteComponent() {
    const { $translate } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const { showDialog } = useDialogs();
    const { currency: currencyBase } = useCurrency();
    const customerHistoryStore = useCustomerHistoryStore();
    const cashoutStore = useCashoutStore();
    useEventsSubscriptionsRequestStore();
    const pendingBetsStore = usePendingBetsStore();
    const selectedHistoryItem = toRef(customerHistoryStore, 'selectedHistoryItem');
    const betCashOutProps = toRef(cashoutStore, 'coreComponentProps');
    const errorMessage = toRef(cashoutStore, 'errorMessage');
    const cashoutAvailable = toRef(cashoutStore, 'cashoutAvailable');
    const isLoaded = ref(false);
    const isCashOutModal = ref(false);
    const routerParams = computed(()=>({
            group: String(router.getParam('group')),
            id: String(router.getParam('id')),
            accountType: String(router.getParam('accountType'))
        }));
    // eslint-disable-next-line max-len
    const component = computed(()=>{
        const { group } = routerParams.value;
        switch(group?.toUpperCase()){
            case TransactionGroup.EGS:
            case TransactionGroup.EGSOW:
            case TransactionGroup.MG:
                return CustomerHistoryDetailsEgs;
            case TransactionGroup.BET:
                return CustomerHistoryDetailsBet;
            default:
                return CustomerHistoryDetailsPayment;
        }
    });
    function isTransactionGroupKey(value) {
        return Object.keys(TransactionGroup).includes(value);
    }
    const requestParameters = computed(()=>{
        const { group, id, accountType } = routerParams.value;
        if (!id || !group) return null;
        const key = group.toUpperCase();
        if (isTransactionGroupKey(key) && key in TransactionGroup) {
            const type = 'string' == typeof accountType ? accountType.toUpperCase() : '';
            if (isUiAccountTypeKey(type)) {
                const accountTypeValue = UiAccountType[type];
                if (accountTypeValue) return {
                    group: TransactionGroup[key],
                    id,
                    accountType: accountTypeValue
                };
            }
            return {
                group: TransactionGroup[key],
                id
            };
        }
        return null;
    });
    const currency = computed(()=>{
        if (routerParams.value.group?.toUpperCase() === HistoryFilter.LEONSHOP) return 'L';
        return currencyBase.value;
    });
    const notificationText = computed(()=>{
        const { group } = routerParams.value;
        return group?.toUpperCase() === TransactionGroup.BET ? $translate('WEB2_COPY_BET_NUMBER').value : $translate('WEB2_COPY_TRANSACTION_ID').value;
    });
    function resetState() {
        cashoutStore.resetState();
    }
    async function getCurrentTransaction() {
        if (requestParameters.value) await customerHistoryStore.getAccountTransaction(requestParameters.value);
        else // eslint-disable-next-line max-len
        router.push({
            name: RouteName.ERROR_NOT_FOUND_404
        });
    }
    function cancelWithdrawal() {
        if (!requestParameters.value) return;
        const { id } = requestParameters.value;
        customerHistoryStore.doWithdrawalCancel({
            paymentId: Number(id)
        }).// eslint-disable-next-line promise/always-return
        then(async ()=>{
            await onWithdrawalSuccess();
            showSuccessWithdrawalModal();
        }).catch((error)=>{
            showDialog({
                presetName: PresetName.ALERT_ERROR,
                options: {
                    confirmMessage: error.message,
                    fullHeight: true
                }
            });
        });
    }
    function onWithdrawalCancel() {
        const { subscribe } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                title: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT').value,
                confirmMessage: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT_QUESTION').value,
                buttons: [
                    {
                        label: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT_YES').value
                    },
                    {
                        label: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT_NO').value
                    }
                ]
            }
        });
        subscribe({
            [DialogAction.CONFIRM]: ()=>cancelWithdrawal()
        });
    }
    function showSuccessWithdrawalModal() {
        showDialog({
            presetName: PresetName.ALERT_SUCCESS,
            options: {
                title: $translate('WEB2_TRANSACTION_HISTORY_CANCELED_PAYMENT').value,
                fullHeight: true,
                buttons: [
                    {
                        kind: ButtonKind.BASE,
                        label: $translate('WEB2_TRANSACTION_HISTORY_CANCELED_PAYMENT_CLOSE').value
                    }
                ]
            }
        });
    }
    async function onCashOutInit(transaction) {
        const { objectId, betType } = transaction;
        if (cashoutAvailable.value && objectId) await cashoutStore.initCashout({
            betType,
            betId: Number(objectId)
        });
    }
    function doCashOut() {
        openCashOutModal();
    }
    function openCashOutModal() {
        isCashOutModal.value = true;
    }
    function closeCashOutModal() {
        isCashOutModal.value = false;
    }
    async function onCashOutSuccess() {
        resetState();
        await Promise.all([
            getCurrentTransaction(),
            updateCustomerHistoryListItem(),
            pendingBetsStore.loadPendingBets()
        ]);
    }
    function onCashOutConfirm() {
        closeCashOutModal();
        onCashOutSuccess();
    }
    async function onWithdrawalSuccess() {
        resetState();
        await Promise.all([
            getCurrentTransaction(),
            updateCustomerHistoryListItem()
        ]);
    }
    async function updateCustomerHistoryListItem() {
        if (requestParameters.value && selectedHistoryItem.value && !errorMessage.value) await customerHistoryStore.updateHistoryListItem({
            id: String(requestParameters.value.id),
            group: requestParameters.value.group,
            accountType: requestParameters.value.accountType
        });
    }
    function onSupportClick() {
        router.push({
            name: RouteName.SUPPORT,
            query: {
                betId: selectedHistoryItem.value?.objectId
            }
        });
    }
    async function beforeMount() {
        await getCurrentTransaction();
        isLoaded.value = true;
    }
    watch(()=>route.params, async ()=>{
        if (route.name === RouteName.CUSTOMER_HISTORY_TRANSACTION_DETAILS) {
            isLoaded.value = false;
            await getCurrentTransaction();
            isLoaded.value = true;
        }
    }, {
        deep: true
    });
    function beforeUnmount() {
        resetState();
        customerHistoryStore.setSelectedCustomerHistoryItem(null);
    }
    return {
        isLoaded,
        currency,
        isCashOutModal,
        component,
        notificationText,
        selectedHistoryItem,
        betCashOutProps,
        onWithdrawalCancel,
        onCashOutInit,
        doCashOut,
        onSupportClick,
        onCashOutConfirm,
        closeCashOutModal,
        beforeMount,
        beforeUnmount
    };
}
