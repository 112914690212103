import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeProps as _normalizeProps, createBlock as _createBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { VIcon } from '@components/v-icon';
import { useResponsibleGamblingQuizFinalDescription } from './composables';
import { ResponsibleGamblingQuizResultsKind } from '../../enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingQuizFinalDescription',
    props: {
        kind: {
            default: ResponsibleGamblingQuizResultsKind.DEFAULT
        }
    },
    setup (__props) {
        const props = __props;
        const { iconProps, finalTitle, finalDescription } = useResponsibleGamblingQuizFinalDescription(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['responsible-gambling-quiz-final-description'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-quiz-final-description__top'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['responsible-gambling-quiz-final-description__icon']]: true,
                            [_ctx.$style[`responsible-gambling-quiz-final-description__icon--${_ctx.kind}`]]: true
                        })
                    }, [
                        (_openBlock(), _createBlock(_unref(VIcon), _normalizeProps(_mergeProps({
                            key: 1
                        }, _unref(iconProps))), null, 16))
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['responsible-gambling-quiz-final-description__title'])
                    }, _toDisplayString(_unref(finalTitle)), 3)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-quiz-final-description__description'])
                }, _toDisplayString(_unref(finalDescription)), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingQuizFinalDescription'
                ]
            ]);
        };
    }
});
