/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ import { computed, onBeforeMount, onMounted, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useNavigationItemsStore, useNavigationStore } from 'web/src/modules/core/store';
import { ResponsibleGamblingOptionType } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
import useResponsibleGamblingStoreV2 from 'web/src/modules/profile/submodules/responsible-gambling/store/useResponsibleGamblingStoreV2';
import { ListItemExtendedKind, ListItemExtendedSubtitleColor } from 'web/src/components/List/VListItemExtended/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
export default function useResponsibleGamblingLimitsRoutePage() {
    const router = useRouter();
    const { $translate } = useI18n();
    const responsibleGamblingItems = toRef(useNavigationItemsStore(), 'responsibleGamblingItems');
    const responsibleGamblingStoreV2 = useResponsibleGamblingStoreV2();
    const navigationStore = useNavigationStore();
    const responsibleGamingLimits = toRef(useCustomerDataStore(), 'responsibleGamingLimits');
    const timeInGameTimerData = toRef(responsibleGamblingStoreV2, 'timeInGameTimerData');
    const list = computed(()=>{
        const limitsChildren = responsibleGamblingItems.value.find((item)=>item.props?.type === ResponsibleGamblingOptionType.LIMITS);
        if (limitsChildren?.children?.length) return limitsChildren.children;
        return [];
    });
    const getSubTitleProps = (responsibleGamingLimitType)=>{
        if (0 === responsibleGamingLimits.value.length) return {
            subTitleFirst: '\u200B'
        };
        const limit = responsibleGamingLimits.value.find((item)=>item.type === responsibleGamingLimitType && item.isSet);
        return {
            subTitleFirst: limit ? $translate('RG_LIMITS_SET').value : $translate('RG_LIMITS_NOT_SET').value,
            subTitleFirstColor: limit ? ListItemExtendedSubtitleColor.BRAND : ListItemExtendedSubtitleColor.DEFAULT
        };
    };
    const getItemProps = (item)=>({
            title: item.label,
            iconName: item.icon?.name,
            iconBackground: item.props?.color,
            kind: ListItemExtendedKind.ICON_WITH_BACKGROUND,
            ...getSubTitleProps(String(item.props?.limitType))
        });
    const getSItemProps = (item)=>({
            type: 'primary',
            title: item.label,
            iconName: item.icon?.name,
            ...getSubTitleProps(String(item.props?.limitType))
        });
    const handleClick = (name)=>{
        if (name) router.push({
            name
        });
    };
    onBeforeMount(async ()=>{
        await navigationStore.updateDefaultNavigationConfig(true);
    });
    onMounted(()=>{
        if (!timeInGameTimerData.value) responsibleGamblingStoreV2.timeInGameGetState();
    });
    return {
        list,
        timeInGameTimerData,
        getItemProps,
        getSItemProps,
        handleClick
    };
}
