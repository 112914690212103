import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { ResponsibleGamblingQuizResultCategory } from '../../../components/ResponsibleGamblingQuizResult/types';
import { getCategorySum, getResultProps, getStepQuestion, getFinalDescriptionKind } from './utils';
export default function useResponsibleGamblingQuizRoutePage() {
    const { $translate } = useI18n();
    const router = useRouter();
    const questionsQuantity = 9;
    const answersTotalTime = 12;
    const answersTotalFinance = 18;
    const answersTotalRelationship = 12;
    const answersTotalHealth = 18;
    const answers = ref([]);
    const stepNumber = ref(1);
    const selectedAnswer = ref(0);
    const isShowResults = ref(false);
    const stepTitle = computed(()=>getStepQuestion(stepNumber.value));
    const options = computed(()=>[
            {
                label: $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_OPTION_NORMAL').value,
                value: 0
            },
            {
                label: $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_OPTION_ACCEPTABLE').value,
                value: 1
            },
            {
                label: $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_OPTION_MODERATELY').value,
                value: 2
            },
            {
                label: $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_OPTION_CRITICAL').value,
                value: 3
            }
        ]);
    const buttonNextProps = computed(()=>({
            label: stepNumber.value === questionsQuantity ? $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_GET_RESULTS').value : $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_NEXT').value,
            kind: ButtonKind.PRIMARY
        }));
    const finalDescriptionKind = computed(()=>getFinalDescriptionKind(answers.value));
    /*
  * Numbers for question category:
  * Time - № 3, 7, 8, 9.
  * Finance - № 1, 2, 3, 4, 5, 8.
  * Relationships - № 4, 5, 7, 8.
  * Health - № 2, 3, 5, 6, 7, 9.
  */ const timeSum = computed(()=>getCategorySum(answers.value, 3, 7, 8, 9));
    const financeSum = computed(()=>getCategorySum(answers.value, 1, 2, 3, 4, 5, 8));
    const relationshipsSum = computed(()=>getCategorySum(answers.value, 4, 5, 7, 8));
    const healthSum = computed(()=>getCategorySum(answers.value, 2, 3, 5, 6, 7, 9));
    const resultTimeProps = computed(()=>getResultProps(answersTotalTime, timeSum.value, ResponsibleGamblingQuizResultCategory.TIME));
    const resultFinanceProps = computed(()=>getResultProps(answersTotalFinance, financeSum.value, ResponsibleGamblingQuizResultCategory.FINANCE));
    const resultRelationshipsProps = computed(()=>getResultProps(answersTotalRelationship, relationshipsSum.value, ResponsibleGamblingQuizResultCategory.RELATIONSHIPS));
    const resultHealthProps = computed(()=>getResultProps(answersTotalHealth, healthSum.value, ResponsibleGamblingQuizResultCategory.HEALTH));
    function onChange(value) {
        selectedAnswer.value = value;
        const currentAnswer = answers.value.find((item)=>item.stepNumber === stepNumber.value);
        if (currentAnswer) currentAnswer.answerValue = selectedAnswer.value;
    }
    function onNextClick() {
        if (stepNumber.value <= questionsQuantity) {
            const currentAnswer = answers.value.find((item)=>item.stepNumber === stepNumber.value);
            const nextAnswer = answers.value.find((item)=>item.stepNumber === stepNumber.value + 1);
            if (answers.value.length && currentAnswer) selectedAnswer.value = nextAnswer ? nextAnswer.answerValue : 0;
            else {
                answers.value.push({
                    stepNumber: stepNumber.value,
                    answerValue: selectedAnswer.value
                });
                selectedAnswer.value = 0;
            }
            if (stepNumber.value !== questionsQuantity) stepNumber.value += 1;
            else isShowResults.value = true;
        }
    }
    function onPrevClick() {
        if (stepNumber.value > 1) {
            const previousAnswer = answers.value.find((item)=>item.stepNumber === stepNumber.value - 1);
            if (previousAnswer) selectedAnswer.value = previousAnswer.answerValue;
            stepNumber.value -= 1;
        } else router.push({
            name: RouteName.RESPONSIBLE_GAMBLING_TEST
        });
    }
    function onCloseClick() {
        stepNumber.value = 1;
        isShowResults.value = false;
        router.push({
            name: RouteName.RESPONSIBLE_GAMBLING_OPTIONS
        });
    }
    return {
        buttonNextProps,
        finalDescriptionKind,
        isShowResults,
        options,
        questionsQuantity,
        resultFinanceProps,
        resultHealthProps,
        resultRelationshipsProps,
        resultTimeProps,
        selectedAnswer,
        stepNumber,
        stepTitle,
        onChange,
        onCloseClick,
        onNextClick,
        onPrevClick
    };
}
