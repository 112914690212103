import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
import { onMounted } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import SNavigationButton from 'web/src/components/Button/SNavigationButton/SNavigationButton.vue';
import useResponsibleGamblingTopBarRouteComponent from './composables/useResponsibleGamblingTopBarRouteComponent';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingTopBarRouteComponent',
    setup (__props) {
        const { hasResponsibleGamblingOnboarding, handleCloseButtonClick, handleOnboardingButtonClick, onMountedComponent } = useResponsibleGamblingTopBarRouteComponent();
        onMounted(onMountedComponent);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                class: _normalizeClass(_ctx.$style['responsible-gambling-top-bar'])
            }, {
                suffix: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['responsible-gambling-top-bar__suffix'])
                        }, [
                            _unref(hasResponsibleGamblingOnboarding) ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _withDirectives((_openBlock(), _createBlock(LButton, {
                                    key: 1,
                                    "icon-name": _unref(IconName).INFO_OUTLINE,
                                    "icon-size": _unref(IconSize).SIZE_24,
                                    kind: _unref(ButtonKind).TRANSPARENT,
                                    class: _normalizeClass(_ctx.$style['responsible-gambling-top-bar__button']),
                                    onClick: _unref(handleOnboardingButtonClick)
                                }, null, 8, [
                                    "icon-name",
                                    "icon-size",
                                    "kind",
                                    "class",
                                    "onClick"
                                ])), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'responsible-gambling-top-bar-button-onboarding'
                                        }
                                    ]
                                ])
                            ], 64)) : _createCommentVNode("", true),
                            _withDirectives((_openBlock(), _createBlock(VButton, {
                                key: 1,
                                "icon-name": _unref(IconName).CROSS,
                                "icon-size": _unref(IconSize).SIZE_24,
                                kind: _unref(ButtonKind).TRANSPARENT,
                                class: _normalizeClass(_ctx.$style['responsible-gambling-top-bar__button']),
                                onClick: _unref(handleCloseButtonClick)
                            }, null, 8, [
                                "icon-name",
                                "icon-size",
                                "kind",
                                "class",
                                "onClick"
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'responsible-gambling-top-bar-button-cross'
                                    }
                                ]
                            ])
                        ], 2)
                    ]),
                _: 1
            }, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingTopBarRouteComponent'
                ]
            ]);
        };
    }
});
