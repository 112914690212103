export default function getCategorySum(answers) {
    for(var _len = arguments.length, questionNumbers = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
        questionNumbers[_key - 1] = arguments[_key];
    }
    return questionNumbers.reduce((acc, index)=>{
        const targetIndex = index - 1;
        let sum = acc;
        if (targetIndex >= 0 && targetIndex < answers.length) sum += answers[targetIndex].answerValue;
        return sum;
    }, 0);
}
