import { computed, onBeforeMount, onMounted, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { CustomerVerificationStatus, CustomerVerificationType, HistoryFilter } from '@leon-hub/api-sdk/src/sdk/sdk';
import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import { useUserNavigationStore } from 'web/src/modules/user/submodules/navigation/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useUserStore } from 'web/src/modules/user/store';
import { useCustomerBalanceUpdateStore, useCustomerDataStore } from 'web/src/modules/customer/store';
import { useVideoVerificationStore } from 'web/src/modules/verification/submodules/video-verification/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { DEFAULT_ORDER_FILTER } from 'web/src/modules/profile/submodules/customer-history/store/constants';
import { ActiveOrderFilterEnum } from 'web/src/modules/profile/submodules/customer-history/types';
import useOnboardingStore from 'web/src/modules/onboarding/store/useOnboardingStore';
import useVerification from 'web/src/modules/profile/submodules/verification/composables/useVerification';
import { useReferralProgramStore } from 'web/src/modules/referral-program/store';
import { useBonusLoyaltyStore, useBonusStore } from 'web/src/modules/bonuses/store';
import { getEgsLoyaltyStore } from 'web/src/modules/core/components/AuthorizedAppController/composables/utils';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useProfileBonusesStore } from 'web/src/modules/profile/submodules/bonuses/store';
import { useCashbackStore } from 'web/src/modules/promotions/store';
import { usePendingBetsStore } from 'web/src/modules/slip/submodules/pending-bets/store';
import { useAchievementsStore } from 'web/src/modules/profile/submodules/achievements/store';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useUserProfilePage() {
    const userStore = useUserStore();
    const { $translate } = useI18n();
    const router = useRouter();
    const siteConfigStore = useSiteConfigStore();
    const bonusStore = useBonusStore();
    const formatMoney = useFormatMoney();
    const egsLoyaltyStore = getEgsLoyaltyStore();
    const bonusLoyaltyStore = useBonusLoyaltyStore();
    const profileBonusesStore = useProfileBonusesStore();
    const balanceStore = useCustomerBalanceUpdateStore();
    const customerDataStore = useCustomerDataStore();
    const cashbackStore = useCashbackStore();
    const isCashbackAvailable = toRef(cashbackStore, 'isCashbackAvailable');
    const pendingBetsStore = usePendingBetsStore();
    const referralStore = useReferralProgramStore();
    const achievementsStore = useAchievementsStore();
    const userPhoneProfileNavigation = toRef(useUserNavigationStore(), 'userPhoneProfileNavigation');
    const availability = toRef(referralStore, 'availability');
    const verificationStatus = toRef(customerDataStore, 'verificationStatus');
    const verificationType = toRef(customerDataStore, 'verificationType');
    const isVerificationPurseCheck = toRef(customerDataStore, 'isVerificationPurseCheck');
    const isShowHintVideoVerificationProfile = toRef(useVideoVerificationStore(), 'isShowHintVideoVerificationProfile');
    const inGameValue = toRef(userStore, 'inGameValue');
    const inGameValueFormatted = computed(()=>formatMoney(inGameValue.value));
    const profileBonusesEnabled = toRef(siteConfigStore, 'bonusesEnable');
    const isProfileBetsCounterEnabled = toRef(siteConfigStore, 'isProfileBetsCounterEnabled');
    toRef(achievementsStore, 'availability');
    const bonusCbc = toRef(bonusStore, 'bonusCbc');
    const { isAccountVerificationRequired } = useVerification();
    const userPhoneProfileNavigationFiltered = computed(()=>userPhoneProfileNavigation.value.map((list)=>list.children?.length ? {
                ...list,
                children: list.children.filter((item)=>{
                    if (item.props?.isAchievementsVisible !== void 0) return !!item.props.isAchievementsVisible && void 0;
                    return true;
                })
            } : list));
    onBeforeMount(async ()=>{
        if (siteConfigStore.isProfileReferralProgramEnabled) await referralStore.getAvailability();
    });
    onMounted(async ()=>{
        const { onboardingLength, getOnboarding } = useOnboardingStore();
        if (!onboardingLength) await getOnboarding({});
        await Promise.all([
            balanceStore.loadData(true),
            bonusStore.getBonusWalletTransferAmount(true),
            bonusStore.fetchEncashBonusData()
        ]);
        bonusLoyaltyStore.fetchBonusLoyalty();
        if (profileBonusesEnabled.value) profileBonusesStore.fetchBonuses();
        "1";
        egsLoyaltyStore.fetchCasinoLoyaltyStatus();
        if (useSiteConfigStore().isWeb2BonusCashbackWidgetEnabled) cashbackStore.getCashback();
    });
    function logout() {
        userStore.logout();
    }
    const verificationServiceLabel = computed(()=>{
        switch(verificationStatus.value){
            case CustomerVerificationStatus.STARTED:
                return isVerificationPurseCheck.value ? $translate('WEB2_PROFILE_PURSE_CHECK_STARTED_LABEL').value : $translate('WEB2_PROFILE_VERIFY_STARTED_LABEL').value;
            case CustomerVerificationStatus.IN_PROCESS:
                return isVerificationPurseCheck.value ? $translate('WEB2_PROFILE_PURSE_CHECK_IN_PROCESS_LABEL').value : $translate('WEB2_PROFILE_VERIFY_IN_PROCESS_LABEL').value;
            case CustomerVerificationStatus.RETRY:
                return isVerificationPurseCheck.value ? $translate('WEB2_PROFILE_PURSE_CHECK_RETRY_LABEL').value : $translate('WEB2_PROFILE_VERIFY_RETRY_LABEL').value;
            case CustomerVerificationStatus.FAILED:
                return isVerificationPurseCheck.value ? $translate('WEB2_PROFILE_PURSE_CHECK_FAILED_LABEL').value : $translate('WEB2_PROFILE_VERIFY_FAILED_LABEL').value;
            default:
                return;
        }
    });
    const verificationLabel = computed(()=>{
        if (isShowHintVideoVerificationProfile.value) return $translate('WEB2_VERIFY_PAGE_TITLE').value;
        if (verificationType.value === CustomerVerificationType.CUSTOMER_VERIFICATION_SERVICE) return verificationServiceLabel.value;
        return $translate('WEB2_ADDITIONAL_DOCS').value;
    });
    const verificationAction = computed(()=>{
        if (isShowHintVideoVerificationProfile.value) return $translate('WEB2_VV_MORE_BUTTON').value;
        if (verificationType.value === CustomerVerificationType.CUSTOMER_VERIFICATION_SERVICE) {
            if (verificationStatus.value === CustomerVerificationStatus.STARTED) {
                if (isVerificationPurseCheck.value) return $translate('WEB2_PROFILE_PURSE_CHECK_STARTED_BTN').value;
                return $translate('WEB2_PROFILE_VERIFY_STARTED_BTN').value;
            }
            return;
        }
        return $translate('WEB2_VERIFY_PAGE_UPLOAD').value;
    });
    const notificationType = computed(()=>void 0);
    function onVerificationClick() {
        router.push({
            name: RouteName.CUSTOMER_VERIFICATION
        });
    }
    function onClickBetsInGame() {
        router.push({
            name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
            query: {
                filter: HistoryFilter.ALL_BETS,
                activeOrderFilter: ActiveOrderFilterEnum.PENDING_BETS
            }
        });
    }
    const customerHistoryContainerProperties = {
        isHiddenTabs: true,
        isHiddenSelectFilter: true,
        isLazyLoadingEnabled: false,
        filterParamsFromProps: {
            ...DEFAULT_ORDER_FILTER,
            rows: 5
        }
    };
    const pendingBetsCount = toRef(pendingBetsStore, 'pendingBetsCount');
    const balanceMode = computed(()=>{
        if (!!bonusCbc.value && isCashbackAvailable.value) return BalanceMode.Slider;
        if (!!bonusCbc.value || isCashbackAvailable.value) return BalanceMode.Multiple;
        return BalanceMode.Single;
    });
    const isRefAvailable = computed(()=>!!availability.value?.available);
    const isShowPendingBetsHint = computed(()=>pendingBetsCount.value > 0 && isProfileBetsCounterEnabled.value);
    return {
        userPhoneProfileNavigationFiltered,
        isRefAvailable,
        customerHistoryContainerProperties,
        isAccountVerificationRequired,
        verificationLabel,
        verificationAction,
        notificationType,
        balanceMode,
        pendingBetsCount,
        inGameValue,
        inGameValueFormatted,
        isShowPendingBetsHint,
        logout,
        onVerificationClick,
        onClickBetsInGame
    };
}
