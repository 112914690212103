import { defineComponent as _defineComponent } from 'vue';
import { createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingBreakRoutePage',
    setup (__props) {
        // empty
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, _cache[0] || (_cache[0] = [
                _createTextVNode("ResponsibleGamblingBreakRoutePage")
            ]))), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingBreakRoutePage'
                ]
            ]);
        };
    }
});
