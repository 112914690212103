import { computed, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import { isArray, isString } from '@leon-hub/guards';
import { isFile } from '@leon-hub/utils';
import { AlertIconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getImageOrIcon } from 'web/src/modules/icons';
import { DocumentReason, VerifyFieldNameEnum } from 'web/src/modules/profile/submodules/verification/enums';
import { FormControlType } from 'web/src/components/Form/enums';
import { ERROR_STATUSES, FETCH_ERROR_STATUS, FILE_EXISTS_STATUS, MAX_SYMBOLS_FOR_COMMENT, SUCCESSFUL_STATUS, SUCCESSFUL_STATUSES } from 'web/src/modules/profile/submodules/verification/components/Internal/consts';
import { getFileExtension, getFileType } from 'web/src/modules/profile/submodules/verification/utils';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import isFormDataValue from 'web/src/components/Form/guards/isFormDataValue';
import { useUploadDocumentsStore } from 'web/src/modules/profile/submodules/verification/store';
export default function useCustomerVerificationInternal() {
    const uploadDocumentsStore = useUploadDocumentsStore();
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const router = useRouter();
    const isLoading = ref(false);
    const errors = ref({});
    const files = ref({});
    const isCommentRequired = ref(true);
    const form = ref();
    const maxFileSizeByExtension = toRef(uploadDocumentsStore, 'maxFileSizeByExtension');
    const verifyReasons = toRef(uploadDocumentsStore, 'verifyReasons');
    const maxFilesNumber = toRef(uploadDocumentsStore, 'maxFilesNumber');
    const uploadResult = toRef(uploadDocumentsStore, 'result');
    const activateButtonProps = computed(()=>({
            isLoading: isLoading.value,
            label: isLoading.value ? $translate('WEB2_VERIFY_PAGE_UPLOAD_PROCESS').value : $translate('WEB2_VERIFY_PAGE_UPLOAD').value,
            kind: ButtonKind.PRIMARY,
            fullWidth: true
        }));
    const maxFileSizeText = computed(()=>{
        const groups = {};
        for (const { name, value } of maxFileSizeByExtension.value)groups[value] = Array.isArray(groups[value]) ? [
            ...groups[value],
            name
        ] : [
            name
        ];
        const result = [];
        for (const [key, value] of Object.entries(groups))if (key && value) result.push(`${key}${$translate('WEB2_MB_DATA_MEASUREMENT').value} (${value})`);
        return `${$translate('WEB2_VERIFY_PAGE_DOCUMENT_RULES').value} ${result.toString().replace(/,/g, ', ')}`;
    });
    const clearResultData = ()=>{
        uploadDocumentsStore.clearResultData();
    };
    const buttonClickSubmit = ()=>{
        form.value?.submit();
    };
    const reasonLabels = computed(()=>verifyReasons.value.reduce((accumulator, reason)=>{
            if (reason.id && reason.localizedName) // eslint-disable-next-line no-param-reassign
            accumulator[String(reason.id)] = reason.localizedName;
            return accumulator;
        }, {}));
    const allowedFiles = computed(()=>{
        const fileRecords = {};
        for (const item of maxFileSizeByExtension.value)fileRecords[item.name] = {
            type: getFileType(item.name)
        };
        return fileRecords;
    });
    const getReasons = computed(()=>verifyReasons.value.reduce((accumulator, reason)=>{
            if (reason.id) accumulator.push(String(reason.id));
            return accumulator;
        }, []));
    const defaultReason = computed(()=>1 === getReasons.value.length ? getReasons.value[0] : '');
    const validateFiles = (formFiles)=>{
        const BYTES_IN_MEGABYTE = 1000000;
        errors.value = {};
        if (Array.isArray(formFiles)) for (const [index, file] of formFiles.entries()){
            const fileExtension = getFileExtension(file.type);
            const maxFileSizeAllowed = maxFileSizeByExtension.value.find((item)=>item.name === fileExtension);
            if (maxFileSizeAllowed && file.size > parseInt(maxFileSizeAllowed.value, 10) * BYTES_IN_MEGABYTE) {
                let message = $translate('WEB2_UPLOAD_FILE_ERROR_SIZE_LIMIT').value;
                "1";
                message += `: ${file.name}`;
                errors.value = {
                    [`files/${index}`]: [
                        {
                            message,
                            value: file.name
                        }
                    ]
                };
            }
        }
    };
    const onChange = (formOutput)=>{
        const { formData } = formOutput;
        const { files: formFiles, reason = '', comment = '' } = formData;
        validateFiles(formFiles);
        if ('' === comment) switch(reason){
            case DocumentReason.PURSE_CHANGE:
            case DocumentReason.EMAIL_CHANGE:
            case DocumentReason.PHONE_NUMBER_CHANGE:
                isCommentRequired.value = true;
                break;
            default:
                isCommentRequired.value = false;
                form.value?.focusByName('comment');
        }
    };
    const schema = computed(()=>{
        const required = [
            VerifyFieldNameEnum.FILES,
            VerifyFieldNameEnum.REASON
        ];
        if (isCommentRequired.value) required.push(VerifyFieldNameEnum.COMMENT);
        return {
            $schema: 'http://json-schema.org/draft-07/schema#',
            $id: 'http://example.com/schemas/registration-leon-ru.json',
            type: 'object',
            required,
            properties: {
                comment: {
                    type: 'string'
                },
                reason: {
                    type: 'string',
                    enum: getReasons.value
                },
                files: {
                    type: 'array',
                    minItems: 1
                }
            }
        };
    });
    const uiSchema = computed(()=>({
            order: [
                VerifyFieldNameEnum.REASON,
                VerifyFieldNameEnum.FILES,
                VerifyFieldNameEnum.COMMENT
            ],
            submitButton: null,
            fields: {
                [VerifyFieldNameEnum.FILES]: {
                    title: $translate('WEB2_VERIFY_PAGE_ADD_DOCUMENT').value,
                    widget: FormControlType.FileMultiple,
                    hintMessage: maxFileSizeText.value,
                    options: {
                        max: parseInt(maxFilesNumber.value, 10) || 3,
                        fileTypes: allowedFiles.value,
                        placeholder: $translate('WEB2_VERIFY_PAGE_UPLOAD').value,
                        label: $translate('WEB2_VERIFY_PAGE_ADD_DOCUMENT').value
                    }
                },
                [VerifyFieldNameEnum.REASON]: {
                    widget: FormControlType.Select,
                    hintMessage: $translate('WEB2_VERIFY_DOCUMENT_REASON').value,
                    title: $translate('WEB2_VERIFY_SENDING_REASON_PLACEHOLDER').value,
                    labels: reasonLabels.value,
                    options: {
                        dropdownMinWidth: 280,
                        placeholder: $translate('WEB2_VERIFY_SENDING_REASON_PLACEHOLDER').value,
                        label: $translate('WEB2_VERIFY_SENDING_REASON').value
                    },
                    default: defaultReason.value
                },
                [VerifyFieldNameEnum.COMMENT]: {
                    title: $translate('WEB2_VERIFY_PAGE_COMMENT').value,
                    widget: FormControlType.MultilineTextInput,
                    options: {
                        placeholder: $translate('WEB2_COMMENT_PLACEHOLDER').value,
                        maxLength: MAX_SYMBOLS_FOR_COMMENT,
                        rowsMin: 4
                    }
                }
            }
        }));
    const onSubmit = (formOutput)=>{
        const { formData, errors: formErrors } = formOutput;
        const { files: formFiles, reason = '', comment = '' } = formData;
        Array.isArray(formFiles) && formFiles.every((f)=>isFormDataValue(f));
        files.value = formFiles;
        isArray(formFiles) && formFiles.every(isFile);
        isString(reason);
        isString(comment);
        if (!formFiles.length) return;
        if ([
            ...formErrors?.keys() ?? []
        ].some((item)=>!!item)) return;
        if (Object.keys(errors.value).length) return;
        if (!reason || isCommentRequired.value && !comment) return;
        isLoading.value = true;
        uploadDocumentsStore.uploadDocuments({
            files: formFiles,
            reason,
            comment
        }).finally(()=>{
            isLoading.value = false;
        });
    };
    const results = computed(()=>uploadResult.value.map((result)=>{
            if (result.result?.toUpperCase() !== SUCCESSFUL_STATUS || !result.uploadResult) return {
                isOk: false,
                status: FETCH_ERROR_STATUS,
                errorMessage: result.msg ?? $translate('WEB2_TECHNICAL_ERROR').value
            };
            if (ERROR_STATUSES.includes(result.uploadResult)) return {
                isOk: false,
                status: result.uploadResult,
                errorMessage: $translate(`WEB2_UPLOAD_FILE_ERROR_${result.uploadResult}`).value
            };
            if (!SUCCESSFUL_STATUSES.includes(result.uploadResult)) return {
                isOk: false,
                status: result.uploadResult,
                errorMessage: result.msg ?? $translate('WEB2_TECHNICAL_ERROR').value
            };
            return {
                isOk: true,
                status: result.uploadResult
            };
        }));
    watch(results, ()=>{
        if (0 === results.value.length) return;
        // show modal
        if (results.value.every((item)=>item.status === FILE_EXISTS_STATUS)) {
            showDialog({
                presetName: PresetName.ALERT_ERROR,
                options: {
                    iconSize: IconSize.SIZE_60,
                    confirmMessage: 1 === results.value.length ? $translate('WEB2_UPLOAD_FILE_ERROR_FILE_EXISTS').value : $translate('WEB2_UPLOAD_FILE_ERROR_FILES_EXISTS').value,
                    buttons: [
                        {
                            kind: ButtonKind.SECONDARY
                        }
                    ],
                    dataTestId: 'upload-file-error-file-exists'
                }
            });
            return;
        }
        // successful
        if (results.value.every((item)=>item.isOk)) {
            clearResultData();
            showDialog({
                presetName: PresetName.ALERT_SUCCESS,
                options: {
                    title: $translate('WEB2_VERIFY_PAGE_FILES_IS_UPLOADED_TITLE').value,
                    iconSize: IconSize.SIZE_60,
                    image: getImageOrIcon({
                        alertIcon: AlertIconName.Success
                    }).image,
                    confirmMessage: $translate('WEB2_VERIFY_PAGE_FILES_IS_UPLOADED_LABEL').value,
                    buttons: [
                        {
                            label: $translate('WEB2_VERIFY_PAGE_FILES_IS_UPLOADED_BUTTON').value,
                            kind: ButtonKind.SECONDARY,
                            action: DialogAction.CONFIRM
                        }
                    ],
                    dataTestId: 'verify-page-file-is-uploaded'
                }
            }).subscribe({
                [DialogAction.CONFIRM]: ()=>{
                    router.back();
                }
            });
            return;
        }
        errors.value = results.value.reduce((resultErrors, value, index)=>{
            if (!value.isOk) {
                Array.isArray(files.value);
                // eslint-disable-next-line no-param-reassign
                resultErrors[`files/${index}`] = [
                    {
                        message: value.errorMessage,
                        value: files.value[index].name
                    }
                ];
            }
            return resultErrors;
        }, {});
    }, {
        immediate: true
    });
    onMounted(async ()=>{
        errors.value = {};
        clearResultData();
        await uploadDocumentsStore.fetchVerifyData();
        schema.value.properties.reason.enum = getReasons.value;
    });
    onBeforeUnmount(clearResultData);
    return {
        activateButtonProps,
        schema,
        uiSchema,
        errors,
        isLoading,
        onSubmit,
        onChange,
        buttonClickSubmit,
        form
    };
}
