import { computed, ref, watch } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
export default function useLimitStatusCard(props) {
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const endDate = ref(null);
    const progressProperties = computed(()=>({
            percentage: props.percentage,
            circleSize: CircleSize.SIZE_152
        }));
    const buttonPrimaryProperties = {
        kind: ButtonKind.PRIMARY,
        height: ButtonHeight.MEDIUM
    };
    const buttonSecondaryProperties = {
        kind: ButtonKind.SECONDARY,
        height: ButtonHeight.MEDIUM
    };
    const heading = computed(()=>props.isTimer ? $translate('WEB2_RESPONSIBLE_GAMING_LIMIT_CURRENT_TIMER_LABEL').value : $translate('WEB2_RESPONSIBLE_GAMING_LIMIT_CURRENT_LIMIT_LABEL').value);
    const progressText = computed(()=>{
        if (!props.isLimitActive) {
            if (props.isTimer) return '00:00';
            return `${$formatMoney(0, {
                minimumFractionDigits: 0
            })}`;
        }
        return props.progressText;
    });
    const subHeading = computed(()=>{
        if (!props.isLimitActive) {
            if (props.isTimer) return $translate('WEB2_RESPONSIBLE_GAMING_LIMIT_TIMER_DEFAULT_LABEL').value;
            return `${$formatMoney(0, {
                minimumFractionDigits: 0
            })}`;
        }
        return props.subHeading;
    });
    const parseDate = (date)=>date.toLocaleString().slice(0, -3).replace('T', ' ').replaceAll('-', '.');
    const startDate = computed(()=>{
        if (!props.startDt) return null;
        return parseDate(new Date(props.startDt));
    });
    const setEndDate = ()=>{
        if (props.startDt) {
            const date = new Date(props.startDt);
            date.setHours(date.getHours() + (props.hours ?? 0));
            date.setMinutes(date.getMinutes() + (props.minutes ?? 0));
            endDate.value = parseDate(date);
        }
    };
    watch(progressText, (newVal)=>{
        if (newVal && !endDate.value && props.startDt) setEndDate();
    });
    return {
        heading,
        subHeading,
        progressText,
        progressProperties,
        buttonPrimaryProperties,
        buttonSecondaryProperties,
        startDate,
        endDate
    };
}
