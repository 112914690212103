import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 1,
    class: "bonus-card-footer__step"
};
import { VIcon } from '@components/v-icon';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import { useProfileBonusesCardFooter } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesCardFooter',
    props: {
        termsLink: {},
        stepNumber: {},
        stepCount: {}
    },
    setup (__props) {
        const props = __props;
        const { iconProps, handleTermsLinkClick, stepNumberString, stepCountString } = useProfileBonusesCardFooter(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "bonus-card-footer",
                    {
                        'bonus-card-footer__has-link': _ctx.termsLink
                    }
                ])
            }, [
                _ctx.termsLink ? (_openBlock(), _createBlock(VRouterLink, {
                    key: 0,
                    class: "bonus-card-footer__link",
                    to: _ctx.termsLink,
                    onClick: _unref(handleTermsLinkClick)
                }, {
                    default: _withCtx(()=>[
                            _createVNode(_unref(VIcon), _mergeProps(_unref(iconProps), {
                                class: "bonus-card-footer__icon"
                            }), null, 16),
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_BONUS_TERMS')), 1)
                        ]),
                    _: 1
                }, 8, [
                    "to",
                    "onClick"
                ])) : _createCommentVNode("", true),
                Number(_ctx.stepCount) > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_BONUS_STEP')) + " " + _toDisplayString(_unref(stepNumberString)) + "/" + _toDisplayString(_unref(stepCountString)), 1)
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ProfileBonusesCardFooter'
                ]
            ]);
        };
    }
});
