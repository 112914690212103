import { ref, computed } from 'vue';
export default function useResponsibleGamblingTimer(props, emit) {
    const COUNT = 4;
    const timer = ref(`${props.hoursInit}${props.minutesInit}`);
    function handleClick(value) {
        if ('BACKSPACE' === value) {
            if (timer.value.length > 0 && '0000' !== timer.value) {
                timer.value = timer.value.slice(0, -1);
                timer.value = `0${timer.value}`;
            }
        } else {
            const valueWithOutLeadingZero = timer.value.replace(/^0+/, '');
            if ('' === valueWithOutLeadingZero || valueWithOutLeadingZero.length < COUNT) {
                if (timer.value.startsWith('0')) timer.value = '00' === value ? timer.value.substring(2) : timer.value.substring(1);
                timer.value += value;
            }
        }
        emit('change', timer.value);
    }
    const hours = computed(()=>timer.value.substring(0, 2));
    const minutes = computed(()=>timer.value.substring(2, 4));
    return {
        hours,
        minutes,
        handleClick
    };
}
