import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
import { useRouter } from 'vue-router';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from '@leon-hub/module-buttons';
import RouteName from '@leon-hub/routing-config-names';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import SNavigationButton from 'web/src/components/Button/SNavigationButton/SNavigationButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingExclusionTopBarRoutePage',
    props: {
        info: {
            type: Boolean
        }
    },
    setup (__props) {
        const router = useRouter();
        function handleSuffix() {
            router.push({
                name: RouteName.RESPONSIBLE_GAMBLING_EXCLUSION_INFO
            });
        }
        function onClose() {
            router.closeModal();
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, null, {
                suffix: _withCtx(()=>[
                        _ctx.info ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            (_openBlock(), _createBlock(LButton, {
                                key: 1,
                                "icon-name": _unref(IconName).INFO_OUTLINE,
                                "icon-size": _unref(IconSize).SIZE_24,
                                kind: _unref(ButtonKind).TRANSPARENT,
                                onClick: _withModifiers(handleSuffix, [
                                    "prevent"
                                ])
                            }, null, 8, [
                                "icon-name",
                                "icon-size",
                                "kind"
                            ]))
                        ])),
                        (_openBlock(), _createBlock(VButton, {
                            key: 1,
                            kind: _unref(ButtonKind).NAV,
                            "icon-name": _unref(IconName).CROSS,
                            "icon-size": _unref(IconSize).SIZE_24,
                            onClick: onClose
                        }, null, 8, [
                            "kind",
                            "icon-name",
                            "icon-size"
                        ]))
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingExclusionTopBarRoutePage'
                ]
            ]);
        };
    }
});
