import useBaseApiClient from 'web/src/modules/core/services/api/useBaseApiClient';
import { isUploadResponse } from './guards';
export default function createVerificationApi() {
    const client = useBaseApiClient();
    return {
        async uploadDocument (input) {
            const formData = new FormData();
            formData.append('file', input.file, input.file.name);
            formData.append('reason', input.reason);
            formData.append('comment', input.comment);
            return client.request({
                silent: true,
                id: 'account/upload-doc',
                method: 'POST',
                endpoint: '/account/upload/doc',
                data: formData,
                guard: isUploadResponse,
                requestTimeout: 180000
            });
        }
    };
}
