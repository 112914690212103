export const SUCCESSFUL_STATUS = 'OK';
export const FETCH_ERROR_STATUS = 'FETCH_ERROR';
export const ERROR_STATUS = 'ERROR';
export const SIZE_LIMIT_STATUS = 'SIZE_LIMIT';
export const MAX_FILES_LIMIT_STATUS = 'MAX_FILES_LIMIT';
export const INVALID_FORMAT_STATUS = 'INVALID_FORMAT';
export const INVALID_FORMAT_SIMPLEID_STATUS = 'INVALID_FORMAT_SIMPLEID';
export const TERMINATE_STATUS = 'TERMINATE';
export const FILES_LIMIT_AT_ONCE_STATUS = 'FILES_LIMIT_AT_ONCE';
export const FILE_EXISTS_STATUS = 'FILE_EXISTS';
export const ERROR_STATUSES = [
    ERROR_STATUS,
    SIZE_LIMIT_STATUS,
    MAX_FILES_LIMIT_STATUS,
    INVALID_FORMAT_STATUS,
    INVALID_FORMAT_SIMPLEID_STATUS,
    TERMINATE_STATUS,
    FILES_LIMIT_AT_ONCE_STATUS
];
export const SUCCESSFUL_STATUSES = [
    SUCCESSFUL_STATUS,
    FILE_EXISTS_STATUS
];
export const MAX_SYMBOLS_FOR_COMMENT = 500;
