import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconSize, IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VListItemExtended from 'web/src/components/List/VListItemExtended/VListItemExtended.vue';
import SList from 'web/src/components/List/SList/SList.vue';
import SListItem from 'web/src/components/List/SListItem/SListItem.vue';
import { useResponsibleGamblingLimitsRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingLimitsRoutePage',
    setup (__props) {
        const { list, handleClick, getItemProps, getSItemProps } = useResponsibleGamblingLimitsRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['responsible-gambling-limits'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, {
                    key: 1
                }, _renderList(_unref(list), (item)=>(_openBlock(), _createBlock(VListItemExtended, _mergeProps({
                        key: item.id,
                        ref_for: true
                    }, _unref(getItemProps)(item), {
                        onClick: ($event)=>_unref(handleClick)(item.routeName)
                    }), null, 16, [
                        "onClick"
                    ]))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingLimitsRoutePage'
                ]
            ]);
        };
    }
});
