import { InputFileTypes } from 'web/src/components/Input/enums';
export const getFileExtension = (type)=>{
    const fileExtension = /[a-z]*$/.exec(type);
    return fileExtension ? fileExtension[0] : '';
};
export const getFileType = (extension)=>{
    switch(extension){
        case 'jpg':
        case 'jpeg':
        case 'png':
            return InputFileTypes.Image;
        case 'pdf':
            return InputFileTypes.Pdf;
        case 'mp4':
        case 'mov':
        case '3gp':
        case 'webm':
        case 'm4v':
            return InputFileTypes.Video;
        default:
            return InputFileTypes.Other;
    }
};
