import { computed, onBeforeMount, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import { AlertIconName, IconName } from '@leon-hub/icons';
import { normalizeError } from '@leon-hub/errors';
import RouteName from '@leon-hub/routing-config-names';
import { getImageOrIcon } from 'web/src/modules/icons';
import { usePushNotificationsStore } from 'web/src/modules/push-notifications/store';
import { useUserStore } from 'web/src/modules/user/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { usePushNotificationsConfigSyncState, usePushNotificationsSubscribe } from 'web/src/modules/push-notifications/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { usePushNotificationSettingsStore } from 'web/src/modules/push-notifications/submodules/push-settings/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useTooltipStore } from 'web/src/modules/dialogs/store';
import { ConfirmEmailStage } from 'web/src/modules/verify-email/enums';
import useSimpleRegistrantProfileStore from 'web/src/modules/profile/store/useSimpleRegistrantProfileStore';
import useDialogsStore from 'web/src/modules/dialogs/store/useDialogsStore';
import { ConsentsTextEnum, isConsentsTextEnum, isUpdateNotificationConsentsRequest } from '../types';
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ // eslint-disable-next-line sonarjs/cognitive-complexity
export default function useCustomerSubscriptionsPage() {
    const router = useRouter();
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const userStore = useUserStore();
    const pushNotificationsStore = usePushNotificationsStore();
    const pushNotificationSettingsStore = usePushNotificationSettingsStore();
    const customerDataStore = useCustomerDataStore();
    let simpleRegistrantProfileStore;
    let isFormLoaded;
    "1";
    simpleRegistrantProfileStore = useSimpleRegistrantProfileStore();
    isFormLoaded = toRef(simpleRegistrantProfileStore, 'isFormLoaded');
    toRef(useDialogsStore(), 'hasInnerDialog');
    const isPushesEnabled = toRef(pushNotificationsStore, 'isPushesEnabled');
    const isAllowed = toRef(pushNotificationsStore, 'isAllowed');
    const safeUserConsents = toRef(userStore, 'consents');
    const isEmailConfirmed = toRef(customerDataStore, 'isEmailConfirmed');
    const isBetPushesAllowed = toRef(pushNotificationsStore, 'isPushOnBetsEnabled');
    const isBetOutcomePushEnabled = toRef(pushNotificationSettingsStore, 'isBetOutcomePushEnabled');
    const isWinOnlyBetOutcomePushEnabled = toRef(pushNotificationSettingsStore, 'isWinOnlyBetOutcomePushEnabled');
    const haveValidEmailValue = toRef(customerDataStore, 'haveValidEmailValue');
    const sendingConfirmEmailLink = ref(false);
    const showConfirmEmailModal = ref(false);
    const submitForm = ref();
    let validEmailWatcher = null;
    onBeforeMount(async ()=>{
        await Promise.all([
            userStore.getNotificationConsents(),
            usePushNotificationsConfigSyncState(),
            pushNotificationSettingsStore.fetchSettings()
        ]);
        if (!haveValidEmailValue.value) await simpleRegistrantProfileStore.getProfileFields();
    });
    const isEmailFormReady = computed(()=>isFormLoaded.value);
    function getIconName(key) {
        switch(key){
            case ConsentsTextEnum.PUSH_NOTIFICATION:
                return IconName.NOTIFICATION_PUSH;
            case ConsentsTextEnum.EMAIL:
                return IconName.MAIL;
            case ConsentsTextEnum.SMS:
                return IconName.CHAT_PROCESSING;
            case ConsentsTextEnum.TELEPHONE:
                return IconName.PHONE;
            case ConsentsTextEnum.SITE_NOTIFICATION:
                return IconName.INFO;
            default:
                throw new Error('Unknown consent');
        }
    }
    function consentName(name) {
        switch(name){
            case ConsentsTextEnum.EMAIL:
                return $translate('WEB2_SUBSCRIPTION_EMAIL').value;
            case ConsentsTextEnum.SMS:
                return $translate('WEB2_SUBSCRIPTION_SMS').value;
            case ConsentsTextEnum.TELEPHONE:
                return $translate('WEB2_SUBSCRIPTION_TELEPHONE').value;
            case ConsentsTextEnum.SITE_NOTIFICATION:
                return $translate('WEB2_SUBSCRIPTION_SITENOTIFICATION').value;
            case ConsentsTextEnum.PUSH_NOTIFICATION:
                return $translate('WEB2_SUBSCRIPTION_PUSHNOTIFICATION').value;
            default:
                return '';
        }
    }
    function isEnabled(name, value) {
        if (name === ConsentsTextEnum.PUSH_NOTIFICATION && !isAllowed.value) return false;
        return value;
    }
    const consents = computed(()=>{
        const result = [];
        for (const [key, value] of Object.entries(safeUserConsents.value)){
            const consentText = isConsentsTextEnum(key) ? key : null;
            if (!(!consentText || consentText === ConsentsTextEnum.PUSH_NOTIFICATION && !isPushesEnabled.value || void ConsentsTextEnum.EMAIL)) // @TODO: need refactor after adding consents dependency  from siteconfig
            result.push({
                id: consentText,
                name: consentName(key),
                isEnabled: isEnabled(consentText, !!value),
                iconName: getIconName(key)
            });
        }
        return result;
    });
    function onSwitch(newValue, name) {
        const currentConsents = {
            ...safeUserConsents.value,
            [name]: newValue
        };
        isUpdateNotificationConsentsRequest(currentConsents);
        try {
            userStore.updateNotificationConsents(currentConsents);
        } catch (rawError) {
            const error = normalizeError(rawError);
            userStore.getNotificationConsents();
            showDialog({
                presetName: PresetName.ALERT_WARNING,
                options: {
                    title: $translate('JSPDEP_UKASH_DECLINED').value,
                    confirmMessage: error.message,
                    dataTestId: 'switch-subscription-error'
                }
            });
        }
    }
    function onNotificationDisable(name) {
        showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                title: $translate('WEB2_CONFIRM_EMAIL_NOTIFICATIONS_DISABLE_CAPTION').value,
                confirmMessage: $translate('WEB2_CONFIRM_EMAIL_NOTIFICATIONS_DISABLE_MESSAGE').value,
                ...getImageOrIcon({
                    alertIcon: AlertIconName.QuestionMark
                }),
                buttons: [
                    {
                        action: DialogAction.MODAL_CLOSE,
                        label: $translate('WEB2_LATER').value
                    },
                    {
                        action: DialogAction.CONFIRM,
                        label: $translate('WEB2_UNSUBSCRIBE').value
                    }
                ],
                dataTestId: 'confirm-email-notification-disable'
            }
        }).subscribe({
            [DialogAction.CONFIRM]: ()=>{
                onSwitch(false, name);
            }
        });
    }
    async function onPushNotificationEnable() {
        // Switch backend anyway
        onSwitch(true, ConsentsTextEnum.PUSH_NOTIFICATION);
        pushNotificationSettingsStore.fetchSettings();
        if (!isAllowed.value) await usePushNotificationsSubscribe();
    }
    function onPushNotificationsClick() {
        const name = ConsentsTextEnum.PUSH_NOTIFICATION;
        const isEnabledValue = isEnabled(name, !!safeUserConsents.value?.pushNotification);
        if (isEnabledValue) {
            onNotificationDisable(name);
            return;
        }
        onPushNotificationEnable();
    }
    function openEmailDialog() {
        showConfirmEmailModal.value = true;
    }
    function closeEmailDialog() {
        showConfirmEmailModal.value = false;
    }
    function onEmailClick() {
        if (!isEmailConfirmed.value) {
            openEmailDialog();
            return;
        }
        const name = ConsentsTextEnum.EMAIL;
        const isEnabledValue = isEnabled(name, !!safeUserConsents.value?.email);
        if (isEnabledValue) {
            onNotificationDisable(name);
            return;
        }
        onSwitch(true, name);
    }
    function onConsentItemClick(event, consent) {
        event.preventDefault();
        event.stopPropagation();
        if (consent.id === ConsentsTextEnum.PUSH_NOTIFICATION) {
            onPushNotificationsClick();
            return;
        }
        if (consent.id === ConsentsTextEnum.EMAIL) {
            onEmailClick();
            return;
        }
        onSwitch(!consent.isEnabled, consent.id);
    }
    function showDisabledBetOutcomePushTooltip() {
        useTooltipStore().showTooltip({
            notificationText: $translate('WEB2_PUSH_SETTINGS_ENABLE_TOOLTIP').value,
            isProfileModalTooltip: true
        });
    }
    const pushSettings = computed(()=>[
            {
                id: "all",
                name: $translate('WEB2_PUSH_NOTIFICATION_ALL_BETS').value,
                isEnabled: !isWinOnlyBetOutcomePushEnabled.value
            },
            {
                id: "winOnly",
                name: $translate('WEB2_PUSH_NOTIFICATION_ONLY_WIN').value,
                isEnabled: isWinOnlyBetOutcomePushEnabled.value
            }
        ]);
    function onPushSettingChanged(event, id) {
        const { checked } = event.target;
        if (checked) pushNotificationSettingsStore.saveIsWinOnlyBetOutcomePushEnabled("winOnly" === id);
    }
    function onConfirmEmailClose() {
        closeEmailDialog();
    }
    const confirmEmailStage = computed(()=>{
        if (haveValidEmailValue.value) return ConfirmEmailStage.EMAIL_IS_NOT_CONFIRMED;
        return ConfirmEmailStage.EMAIL_UNKNOWN;
    });
    function onConfirmEmail() {
        if (!haveValidEmailValue.value) {
            // close modal based on SetEmailForm success
            validEmailWatcher = watch(haveValidEmailValue, (value)=>value && goToStatusPage());
            submitForm.value?.submit();
            return;
        }
        sendConfirmation();
    }
    function goToStatusPage() {
        validEmailWatcher?.();
        userStore.setConfirmationLinkWasSent(true);
        closeEmailDialog();
        router.push({
            name: RouteName.VERIFY_EMAIL_STATUS_PAGE
        });
    }
    async function sendConfirmation() {
        sendingConfirmEmailLink.value = true;
        await userStore.resendVerificationEmail();
        sendingConfirmEmailLink.value = false;
        goToStatusPage();
    }
    return {
        consents,
        showConfirmEmailModal,
        isBetPushesAllowed,
        isBetOutcomePushEnabled,
        isEmailFormReady,
        pushSettings,
        haveValidEmailValue,
        confirmEmailStage,
        sendingConfirmEmailLink,
        submitForm,
        email: toRef(customerDataStore, 'email'),
        onConsentItemClick,
        toggleBetOutcomePush: pushNotificationSettingsStore.toggleBetOutcomePush,
        showDisabledBetOutcomePushTooltip,
        onPushSettingChanged,
        onConfirmEmailClose,
        onConfirmEmail
    };
}
