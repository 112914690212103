import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { HomePageType } from '@leon-hub/api-sdk';
import { getBonusBalance } from 'web/src/modules/profile/submodules/bonuses/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { ButtonActionType, InfoStatusType } from 'web/src/modules/profile/submodules/bonuses/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { getAvailableBefore, getAvailableBonusAmount, getAvailableFreebets, getBalanceProperties, getHasTransferAmount, getIsFreebetInProgress, getIsRefuseAvailable, getTotalAvailable, getBonusButtons, getLayoutProperties } from './utils';
export default function useProfileBonusesFreebetController(props, emit) {
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const router = useRouter();
    const siteConfigStore = useSiteConfigStore();
    const homePageType = toRef(siteConfigStore, 'homePageType');
    const availableBefore = computed(()=>getAvailableBefore(props));
    const availableBonusAmount = computed(()=>getAvailableBonusAmount(props));
    const availableFreebets = computed(()=>getAvailableFreebets(props));
    const balanceProperties = computed(()=>getBalanceProperties(props, $translate, $formatMoney));
    const bonusButtons = computed(()=>getBonusButtons(props, $translate));
    const hasTransferAmount = computed(()=>getHasTransferAmount(props));
    const isFreebetInProgress = computed(()=>getIsFreebetInProgress(props));
    const isRefuseAvailable = computed(()=>getIsRefuseAvailable(props));
    const layoutProperties = computed(()=>getLayoutProperties(props));
    const totalAvailable = computed(()=>getTotalAvailable(props));
    const balance = computed(()=>getBonusBalance(props.bonus));
    const infoStatusType = InfoStatusType.FREEBET_IN_PLAY;
    const refuseBonusEmit = ()=>{
        emit('refuse', props.bonus);
    };
    const collectBonusEmit = ()=>{
        emit('collect', {
            bonus: props.bonus,
            balance: balance.value
        });
    };
    const emitTimerEnd = ()=>{
        emit('timer-end');
    };
    const handleButtonClick = (id)=>{
        switch(id){
            case ButtonActionType.PLAY:
                // eslint-disable-next-line max-len
                if (homePageType.value === HomePageType.SLOTS || homePageType.value === HomePageType.LIVE_GAMES) router.push({
                    name: RouteName.SPORTLINE_LIVE_EVENTS
                });
                else router.push({
                    name: RouteName.HOME
                });
                break;
            case ButtonActionType.REFUSE:
                refuseBonusEmit();
                break;
            case ButtonActionType.COLLECT:
                collectBonusEmit();
                break;
            default:
                break;
        }
    };
    return {
        availableBefore,
        availableBonusAmount,
        availableFreebets,
        balanceProperties,
        bonusButtons,
        hasTransferAmount,
        isFreebetInProgress,
        isRefuseAvailable,
        layoutProperties,
        totalAvailable,
        balance,
        infoStatusType,
        refuseBonusEmit,
        collectBonusEmit,
        emitTimerEnd,
        handleButtonClick
    };
}
