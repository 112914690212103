// eslint-disable-next-line import/prefer-default-export
export { default as VerifyFieldNameEnum } from './VerifyFieldNameEnum';
export var DocumentReason;
var DocumentReason1;
// other labels available here:
// https://gitlab.leoncorp.net/leoncorp/leoncommon/blob/release/src/com/leoncommon/models/DocumentReason.java
(DocumentReason1 = DocumentReason || (DocumentReason = {}))["VERIFICATION"] = "1", DocumentReason1["PURSE_CHANGE"] = "2", DocumentReason1["EMAIL_CHANGE"] = "3", DocumentReason1["PHONE_NUMBER_CHANGE"] = "4", DocumentReason1["OTHER"] = "7", DocumentReason1["IDENTIFICATION"] = "9", DocumentReason1["INN_SET"] = "10";
export var VerificationPageType;
var VerificationPageType1;
(VerificationPageType1 = VerificationPageType || (VerificationPageType = {}))[VerificationPageType1["DEFAULT"] = 1] = "DEFAULT", VerificationPageType1[VerificationPageType1["INTERNAL"] = 2] = "INTERNAL", VerificationPageType1[VerificationPageType1["EXTERNAL"] = 3] = "EXTERNAL", VerificationPageType1[VerificationPageType1["VIDEO"] = 4] = "VIDEO";
