import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "bonus-card__content"
};
const _hoisted_2 = {
    key: 0,
    class: "bonus-card__description"
};
import ProfileBonusesCardHeader from '../../components/ProfileBonusesCardHeader/ProfileBonusesCardHeader.vue';
import ProfileBonusesCardDescriptionColumns from '../../components/ProfileBonusesCardDescriptionColumns/ProfileBonusesCardDescriptionColumns.vue';
import { useProfileBonusesComplexController } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesComplexController',
    props: {
        index: {},
        active: {},
        name: {},
        deposit: {},
        bonusAmount: {
            default: ''
        },
        type: {},
        freespin: {
            default: null
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isDepositBonus, depositDescription, columnsProperties, headerProperties } = useProfileBonusesComplexController(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("button", {
                type: "button",
                class: "bonus-card",
                onClick: _cache[0] || (_cache[0] = ($event)=>emit('click', _ctx.index))
            }, [
                _createVNode(ProfileBonusesCardHeader, _normalizeProps(_guardReactiveProps(_unref(headerProperties))), null, 16),
                _createElementVNode("span", _hoisted_1, [
                    _unref(isDepositBonus) || !_ctx.freespin?.nominal ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(depositDescription)), 1)) : (_openBlock(), _createBlock(ProfileBonusesCardDescriptionColumns, _normalizeProps(_mergeProps({
                        key: 1
                    }, _unref(columnsProperties))), null, 16))
                ])
            ])), [
                [
                    _directive_auto_id,
                    'ProfileBonusesComplexController'
                ]
            ]);
        };
    }
});
