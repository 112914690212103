import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, mergeProps as _mergeProps, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import HintBlockKind from 'web/src/components/HintBlock/VHintBlock/enums/HintBlockKind';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import CustomerVerificationCardTimer from 'web/src/modules/profile/submodules/verification/components/CardTimer/CustomerVerificationCardTimer.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerVerificationConstructor',
    props: {
        jumbotron: {},
        buttonFirst: {},
        buttonSecond: {},
        hintText: {},
        isTimeChangeAvailable: {
            type: Boolean
        },
        text: {},
        cmsKey: {},
        time: {},
        isCentered: {
            type: Boolean
        },
        timeEnd: {}
    },
    emits: [
        "click",
        "click-second",
        "change-time",
        "time-end"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const buttonPropertiesFirst = computed(()=>({
                label: 'Button 1',
                kind: ButtonKind.PRIMARY,
                fullWidth: true,
                ...props?.buttonFirst ? props.buttonFirst : {}
            }));
        const buttonPropertiesSecond = computed(()=>({
                label: 'Button 2',
                kind: ButtonKind.TRANSPARENT,
                fullWidth: true,
                ...props?.buttonSecond ? props.buttonSecond : {}
            }));
        const jumbotronProperties = computed(()=>({
                icon: IconName.VIDEO_CALL,
                iconKind: JumbotronIconKind.SUCCESS,
                iconSize: IconSize.SIZE_80,
                heading: '',
                text: '',
                ...props?.jumbotron ? props?.jumbotron : {}
            }));
        const hintBlockProperties = {
            kind: HintBlockKind.DEFAULT
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "",
                    {
                        [_ctx.$style['video-verification']]: true,
                        [_ctx.$style['video-verification__center']]: _ctx.isCentered
                    }
                ])
            }, [
                _createVNode(_unref(VJumbotron), _normalizeProps(_guardReactiveProps(jumbotronProperties.value)), {
                    default: _withCtx(()=>[
                            _ctx.text ? (_openBlock(), _createBlock(_unref(TextSlotPlaceholder), {
                                key: 0,
                                text: _ctx.text
                            }, _createSlots({
                                _: 2
                            }, [
                                _ctx.time ? {
                                    name: "time",
                                    fn: _withCtx(()=>[
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['vv-time'])
                                            }, _toDisplayString(_ctx.time), 3)
                                        ]),
                                    key: "0"
                                } : void 0
                            ]), 1032, [
                                "text"
                            ])) : _createCommentVNode("", true),
                            _ctx.cmsKey ? (_openBlock(), _createBlock(VCmsContent, {
                                key: 1,
                                "cms-key": _ctx.cmsKey,
                                class: _normalizeClass(_ctx.$style['video-verification__content'])
                            }, null, 8, [
                                "cms-key",
                                "class"
                            ])) : _createCommentVNode("", true),
                            _ctx.isTimeChangeAvailable ? (_openBlock(), _createElementBlock("a", {
                                key: 2,
                                class: _normalizeClass(_ctx.$style['video-verification__change-time']),
                                onClick: _cache[0] || (_cache[0] = _withModifiers(($event)=>emit('change-time'), [
                                    "prevent"
                                ]))
                            }, _toDisplayString(_ctx.$t('WEB2_VV_CHANGE_TIME')), 3)) : _createCommentVNode("", true),
                            _ctx.hintText ? (_openBlock(), _createBlock(VHintBlock, _mergeProps({
                                key: 3
                            }, hintBlockProperties, {
                                class: _ctx.$style['video-verification__hint']
                            }), {
                                default: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['video-verification__hint-text'])
                                        }, _toDisplayString(_ctx.hintText), 3)
                                    ]),
                                _: 1
                            }, 16, [
                                "class"
                            ])) : _createCommentVNode("", true)
                        ]),
                    footer: _withCtx(()=>[
                            _ctx.buttonFirst ? (_openBlock(), _createBlock(_unref(JumbotronFooterButton), _mergeProps({
                                key: 0
                            }, buttonPropertiesFirst.value, {
                                onClick: _cache[1] || (_cache[1] = ($event)=>emit('click'))
                            }), null, 16)) : _createCommentVNode("", true),
                            _ctx.timeEnd ? (_openBlock(), _createBlock(CustomerVerificationCardTimer, {
                                key: 1,
                                "expiration-date": _ctx.timeEnd,
                                onTimerEnd: _cache[2] || (_cache[2] = ($event)=>emit('time-end'))
                            }, null, 8, [
                                "expiration-date"
                            ])) : _createCommentVNode("", true),
                            _ctx.buttonSecond ? (_openBlock(), _createBlock(_unref(JumbotronFooterButton), _mergeProps({
                                key: 2
                            }, buttonPropertiesSecond.value, {
                                onClick: _cache[3] || (_cache[3] = ($event)=>emit('click-second'))
                            }), null, 16)) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }, 16)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerVerificationConstructor'
                ]
            ]);
        };
    }
});
