import { computed } from 'vue';
import { defaultDateTimeFormat } from 'web/src/modules/profile/submodules/customer-history/utils';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
// eslint-disable-next-line max-len
export default function useCustomerHistoryDetailsEgs(props) {
    const $formatMoney = useFormatMoney();
    const { $translate } = useI18n();
    const date = computed(()=>defaultDateTimeFormat(props.transaction.date));
    const debit = computed(()=>$formatMoney(props.transaction.debit || 0));
    const credit = computed(()=>props.transaction.credit > 0 ? $formatMoney(props.transaction.credit) : null);
    const transactionType = computed(()=>{
        const { txTypeName, egsGameName } = props.transaction;
        return `${txTypeName}${egsGameName && txTypeName ? ' - ' : ''}${egsGameName}`;
    });
    const dataRows = computed(()=>{
        let data = [
            {
                label: $translate('WEB2_TRANSACTION_HISTORY_TRANSACTION_TYPE').value,
                value: transactionType.value
            }
        ];
        if (props.transaction.odds) data.push({
            label: $translate('WEB2_TRANSACTION_HISTORY_TRANSACTION_ODDS').value,
            value: `${props.transaction.odds}`
        });
        data = [
            ...data,
            {
                label: $translate('WEB2_TRANSACTION_HISTORY_TRANSACTION_STATUS').value,
                value: props.transaction.statusName
            },
            {
                label: $translate('WEB2_TRANSACTION_HISTORY_TRANSACTION_ACCEPTED').value,
                value: date.value
            },
            {
                label: $translate('WEB2_BET_DETAILS_SUMM').value,
                value: debit.value
            }
        ];
        if (credit.value) data.push({
            label: $translate('WEB2_BET_DETAILS_WIN').value,
            value: credit.value
        });
        return data;
    });
    return {
        date,
        debit,
        credit,
        dataRows
    };
}
