import { computed, onBeforeMount, ref, toRef } from 'vue';
import { isOptionalString, isString } from '@leon-hub/guards';
import { IconName } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { DocumentReason, VerifyFieldNameEnum } from 'web/src/modules/profile/submodules/verification/enums';
import { FormControlType } from 'web/src/components/Form/enums';
import { MAX_SYMBOLS_FOR_COMMENT } from 'web/src/modules/profile/submodules/verification/components/Internal/consts';
import { useVerificationDefaultStore } from 'web/src/modules/profile/submodules/verification/store';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
export function useCustomerVerificationDefault() {
    const verificationStore = useVerificationDefaultStore();
    const reasons = toRef(()=>verificationStore.reasons);
    const selectedReason = ref('');
    const selectedComment = ref();
    const verificationTokenData = ref();
    const { showDialog } = useDialogs();
    const reasonsMap = computed(()=>reasons.value.reduce((accumulator, reason)=>{
            accumulator[String(reason.id)] = reason.localizedName;
            return accumulator;
        }, {}));
    const isCommentRequired = computed(()=>[
            DocumentReason.PURSE_CHANGE,
            DocumentReason.EMAIL_CHANGE,
            DocumentReason.PHONE_NUMBER_CHANGE
        ].includes(selectedReason.value));
    const isCommentHidden = computed(()=>// eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        !selectedReason.value || selectedReason.value === DocumentReason.VERIFICATION);
    const isLoadingRequest = ref(false);
    const isLoadingReasons = computed(()=>!reasons.value.length);
    const defaultReason = computed(()=>1 === reasons.value.length ? String(reasons.value[0].id) : '');
    const isButtonDisabled = computed(()=>{
        if (!selectedReason.value) return true;
        return isCommentRequired.value && !selectedComment.value;
    });
    const { $translate } = useI18n();
    const onChange = (formOutput)=>{
        const { formData } = formOutput;
        const { reason, comment } = formData;
        isOptionalString(comment);
        selectedReason.value = String(reason);
        selectedComment.value = comment;
    };
    const commentPlaceholder = computed(()=>{
        switch(selectedReason.value){
            case DocumentReason.PURSE_CHANGE:
                return $translate('WEB2_VERIFICATION_PURSE_CHANGE_COMMENT_PLACEHOLDER').value;
            case DocumentReason.EMAIL_CHANGE:
                return $translate('WEB2_VERIFICATION_EMAIL_CHANGE_COMMENT_PLACEHOLDER').value;
            case DocumentReason.PHONE_NUMBER_CHANGE:
                return $translate('WEB2_VERIFICATION_PHONE_NUMBER_CHANGE_COMMENT_PLACEHOLDER').value;
            default:
                return null;
        }
    });
    const schema = computed(()=>{
        const required = [
            VerifyFieldNameEnum.REASON
        ];
        if (isCommentRequired.value) required.push(VerifyFieldNameEnum.COMMENT);
        return {
            type: 'object',
            required,
            properties: {
                reason: {
                    type: 'string',
                    enum: Object.keys(reasonsMap.value)
                },
                comment: {
                    type: 'string'
                }
            }
        };
    });
    const uiSchema = computed(()=>({
            order: [
                VerifyFieldNameEnum.REASON,
                ...isCommentHidden.value ? [] : [
                    VerifyFieldNameEnum.COMMENT
                ]
            ],
            submitButton: {
                label: $translate('WEB2_VERIFICATION_START_BTN').value,
                isLoading: isLoadingRequest.value,
                disabled: isButtonDisabled.value
            },
            fields: {
                [VerifyFieldNameEnum.REASON]: {
                    widget: FormControlType.Select,
                    hintMessage: $translate('WEB2_VERIFY_DOCUMENT_REASON').value,
                    title: $translate('WEB2_VERIFY_SENDING_REASON_PLACEHOLDER').value,
                    labels: reasonsMap.value,
                    options: {
                        dropdownMinWidth: 280,
                        placeholder: $translate('WEB2_VERIFY_SENDING_REASON_PLACEHOLDER').value,
                        label: $translate('WEB2_VERIFY_SENDING_REASON').value
                    },
                    default: defaultReason.value
                },
                [VerifyFieldNameEnum.COMMENT]: {
                    title: $translate('WEB2_VERIFY_PAGE_COMMENT').value,
                    widget: FormControlType.MultilineTextInput,
                    options: {
                        placeholder: commentPlaceholder.value ?? $translate('WEB2_COMMENT_PLACEHOLDER').value,
                        maxLength: MAX_SYMBOLS_FOR_COMMENT,
                        rowsMin: 4
                    },
                    default: ''
                }
            }
        }));
    async function makeSubmitRequest(param) {
        let { reason, comment } = param;
        isLoadingRequest.value = true;
        try {
            verificationTokenData.value = await verificationStore.makeVerification({
                reasonId: reason,
                comment
            });
        } finally{
            isLoadingRequest.value = false;
        }
    }
    function showSubmitDialog(data) {
        showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                iconName: IconName.VERIFICATION,
                title: $translate('WEB2_DEFAULT_VERIFICATION_SUBMIT_DIALOG_TTL').value,
                confirmMessage: $translate('WEB2_DEFAULT_VERIFICATION_SUBMIT_DIALOG_MSG').value,
                buttons: [
                    {
                        label: $translate('WEB2_DEFAULT_VERIFICATION_SUBMIT_DIALOG_BTN_SUBMIT').value,
                        action: DialogAction.CONFIRM
                    },
                    {
                        label: $translate('WEB2_DEFAULT_VERIFICATION_SUBMIT_DIALOG_BTN_REJECT').value,
                        action: DialogAction.MODAL_CLOSE
                    }
                ]
            }
        }).subscribe({
            [DialogAction.CONFIRM]: ()=>{
                makeSubmitRequest(data);
            }
        });
    }
    function onSubmit(formOutput) {
        const { formData, errors } = formOutput;
        const { reason, comment } = formData;
        isString(reason);
        isOptionalString(comment);
        if (errors) return;
        if (!reason || isCommentRequired.value && !comment) return;
        showSubmitDialog({
            reason,
            comment
        });
    }
    onBeforeMount(()=>{
        verificationStore.fetchReasons();
    });
    return {
        schema,
        uiSchema,
        isLoadingRequest,
        isLoadingReasons,
        verificationTokenData,
        onSubmit,
        onChange
    };
}
