import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { PinCodeRequiredExceptionCode } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { ButtonKind } from '@leon-hub/module-buttons';
import { useCustomerFinanceStore } from 'web/src/modules/customer/store';
import { useResponsibleGamblingStore } from 'web/src/modules/responsible-gambling/store';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { getInputPlaceholder } from 'web/src/modules/profile/submodules/responsible-gambling/utils/getInputPlaceholder';
import { getLimitPeriod } from 'web/src/modules/profile/submodules/responsible-gambling/utils/getLimitPeriod';
import { getCircularProgressData } from 'web/src/modules/profile/submodules/responsible-gambling/utils/getCircularProgressData';
import { getSwitcherOptions } from 'web/src/modules/profile/submodules/responsible-gambling/utils/getSwitcherOptions';
import { LimitName, StepName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
import useResponsibleGamblingStoreV2 from 'web/src/modules/profile/submodules/responsible-gambling/store/useResponsibleGamblingStoreV2';
import { useI18n } from 'web/src/modules/i18n/composables';
import useCustomerDataStore from 'web/src/modules/customer/store/useCustomerDataStore';
export default function useResponsibleGamblingBetLimitRoutePage() {
    const { $translate } = useI18n();
    const financeStore = useCustomerFinanceStore();
    const responsibleGamblingStore = useResponsibleGamblingStore();
    const responsibleGamblingStoreV2 = useResponsibleGamblingStoreV2();
    const router = useRouter();
    const formatMoney = useFormatMoney();
    const { showDialog } = useDialogs();
    const pinCodeStore = usePinCodeStore();
    const customerDataStore = useCustomerDataStore();
    const depositLimitsData = toRef(responsibleGamblingStore, 'depositLimitsData');
    const betLimits = toRef(responsibleGamblingStore, 'betLimits');
    const balance = toRef(financeStore, 'balance');
    const step = toRef(responsibleGamblingStoreV2, 'betStep');
    const customerData = toRef(customerDataStore, 'customerData');
    const limit = ref(LimitName.DAILY);
    const loading = ref(false);
    const minAmount = ref(0);
    const switcherOptions = getSwitcherOptions($translate);
    const isInitialized = computed(()=>!!betLimits.value && !!customerData.value);
    const placeholder = computed(()=>getInputPlaceholder($translate, limit.value));
    const limitPeriod = computed(()=>getLimitPeriod(limit.value));
    const circularProgressData = computed(()=>getCircularProgressData(limit.value, betLimits.value));
    const description = computed(()=>{
        switch(limit.value){
            case LimitName.DAILY:
                return $translate('WEB2_RG_BET_INFO_DAILY_DESCRIPTION').value;
            case LimitName.WEEKLY:
                return $translate('WEB2_RG_BET_INFO_WEEKLY_DESCRIPTION').value;
            default:
                return $translate('WEB2_RG_BET_INFO_MONTHLY_DESCRIPTION').value;
        }
    });
    function setActiveTab(value) {
        limit.value = value;
    }
    function setActiveStep(value) {
        responsibleGamblingStoreV2.setBetStep(value);
    }
    function showSuccessDialog(value) {
        let confirmMessage = $translate('WEB2_RG_BET_REMOVAL_SUCCESS').value;
        if (value) {
            const count = formatMoney(Number(value));
            switch(limit.value){
                case LimitName.DAILY:
                    confirmMessage = $translate('WEB2_RG_BET_DAILY_ADDED_SUCCESS', ref({
                        count
                    })).value;
                    break;
                case LimitName.WEEKLY:
                    confirmMessage = $translate('WEB2_RG_BET_WEEKLY_ADDED_SUCCESS', ref({
                        count
                    })).value;
                    break;
                default:
                    confirmMessage = $translate('WEB2_RG_BET_MONTHLY_ADDED_SUCCESS', ref({
                        count
                    })).value;
                    break;
            }
        }
        showDialog({
            presetName: PresetName.ALERT_SUCCESS,
            options: {
                title: value ? $translate('WEB2_RG_LIMIT_SUCCESS_ON_TITLE').value : $translate('WEB2_RG_LIMIT_SUCCESS_OFF_TITLE').value,
                confirmMessage,
                buttons: [
                    {
                        action: DialogAction.MODAL_CLOSE,
                        label: $translate('JSP_PCL_FBOT_CLOSE').value,
                        kind: ButtonKind.SECONDARY
                    }
                ]
            }
        }).promise.then(()=>{
            setActiveStep(StepName.INFO);
        });
    }
    function submitLimit(value) {
        loading.value = true;
        responsibleGamblingStore.setBetLimitsAction({
            limitPeriod: limitPeriod.value,
            limit: value,
            type: limit.value
        }).then(()=>{
            showSuccessDialog(value);
            responsibleGamblingStore.setDepositLimitsData(null);
        }).catch((error)=>{
            if (error.message === PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED) {
                pinCodeStore.setRouteFrom({
                    name: RouteName.RESPONSIBLE_GAMBLING_BET_LIMIT
                });
                responsibleGamblingStore.setDepositLimitsData({
                    limitPeriod: limitPeriod.value,
                    $event: {
                        type: limit.value,
                        limit: value
                    }
                });
                router.replace({
                    name: RouteName.PIN_CODE_PROFILE
                });
            } else showDialog({
                presetName: PresetName.ALERT_WARNING,
                options: {
                    title: '',
                    confirmMessage: error.message,
                    isProfileLayout: true
                }
            });
        }).finally(()=>{
            loading.value = false;
        });
    }
    function resetLimit() {
        submitLimit(null);
    }
    function handleBack() {
        router.push({
            name: RouteName.HOME
        });
    }
    async function beforeMounted() {
        await Promise.all([
            responsibleGamblingStore.loadBetLimits()
        ]);
        if (depositLimitsData.value) {
            setActiveTab(depositLimitsData.value?.$event.type);
            submitLimit(depositLimitsData.value?.$event.limit);
        }
    }
    function beforeUnmounted() {
        setActiveStep(StepName.INFO);
    }
    return {
        isInitialized,
        step,
        limit,
        switcherOptions,
        balance,
        minAmount,
        loading,
        circularProgressData,
        placeholder,
        description,
        setActiveTab,
        setActiveStep,
        submitLimit,
        resetLimit,
        handleBack,
        beforeMounted,
        beforeUnmounted
    };
}
