import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    class: "bonus-card-header"
};
const _hoisted_2 = {
    class: "bonus-card-header__title"
};
const _hoisted_3 = {
    key: 0,
    class: "bonus-card-header__badge-select-active__inner"
};
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import { useProfileBonusesCardHeader } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesCardHeader',
    props: {
        title: {
            default: ''
        },
        badgeText: {
            default: ''
        },
        badgeSelect: {
            type: Boolean
        },
        active: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { badgeProps, hasBadge } = useProfileBonusesCardHeader(props);
        return (_ctx, _cache)=>{
            const _directive_collect = _resolveDirective("collect");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
                _unref(hasBadge) ? _withDirectives((_openBlock(), _createBlock(VBadge, _mergeProps({
                    key: 0
                }, _unref(badgeProps), {
                    class: "bonus-card-header__badge"
                }), null, 16)), [
                    [
                        _directive_collect,
                        {
                            bonuses: 'cherry'
                        },
                        "click-counter"
                    ],
                    [
                        _unref(vDataTestUnit),
                        {
                            el: 'bonus-card-header-badge'
                        }
                    ]
                ]) : _ctx.badgeSelect ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass([
                        "bonus-card-header__badge-select",
                        {
                            'bonus-card-header__badge-select-active': _ctx.active
                        }
                    ])
                }, [
                    _ctx.active ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, null, 512)), [
                        [
                            _unref(vDataTestUnit),
                            {
                                el: 'bonus-card-header-badge-select-active-inner'
                            }
                        ]
                    ]) : _createCommentVNode("", true)
                ], 2)), [
                    [
                        _unref(vDataTestUnit),
                        {
                            el: 'bonus-card-header-badge-select'
                        }
                    ]
                ]) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'ProfileBonusesCardHeader'
                ]
            ]);
        };
    }
});
