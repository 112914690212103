import { ResponsibleGamblingQuizResultsKind } from '../../../../enums';
export default function getResultProps(of, answerCount, category) {
    let kindComputed = ResponsibleGamblingQuizResultsKind.DEFAULT;
    const percentScale = parseFloat((answerCount / of * 100).toFixed(1));
    if (percentScale >= 1 && percentScale < 17) kindComputed = ResponsibleGamblingQuizResultsKind.ACCEPTABLE;
    if (percentScale >= 17 && percentScale < 51) kindComputed = ResponsibleGamblingQuizResultsKind.MODERATELY;
    if (percentScale >= 51) kindComputed = ResponsibleGamblingQuizResultsKind.CRITICAL;
    return {
        of,
        category,
        value: answerCount,
        kind: kindComputed
    };
}
