import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue";
import { onBeforeMount } from 'vue';
import { FastMoneyMaxPosition, VFastMoneyChoice } from 'web/src/components/FastMoneyChoice';
import { useBonusWithdrawalsRoutePage } from 'web/src/modules/profile/submodules/bonuses/pages/BonusWithdrawalsRoutePage/composables';
import VJumbotron from 'web/src/components/Jumbotron/VJumbotron/VJumbotron.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import PaymentsInput from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/PaymentsInput.vue';
const formattedCurrency = 'L';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusWithdrawalsRoutePage',
    setup (__props) {
        const { amount, maxBonusValue, minBonusValue, bonusButtonProps, buttonProps, paymentsInputValue, fastChoiceValue, maxValueFastChoice, jumbotronProps, money, fastChoicesOptions, isShowSuccess, onComponentCreated, handleClick, handleInput, onChange, onEncashBonus } = useBonusWithdrawalsRoutePage();
        onBeforeMount(()=>{
            onComponentCreated();
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['bonus-withdrawals'])
            }, [
                _unref(isShowSuccess) ? (_openBlock(), _createBlock(VJumbotron, _mergeProps({
                    key: 0,
                    class: _ctx.$style['bonus-withdrawals__jumbotron']
                }, _unref(jumbotronProps)), {
                    footer: _withCtx(()=>[
                            _createVNode(VButton, _mergeProps({
                                class: _ctx.$style['bonus-withdrawals__button']
                            }, _unref(buttonProps), {
                                onClick: _unref(handleClick)
                            }), null, 16, [
                                "class",
                                "onClick"
                            ])
                        ]),
                    default: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['bonus-withdrawals__money'])
                            }, _toDisplayString(_unref(money)), 3)
                        ]),
                    _: 1
                }, 16, [
                    "class"
                ])) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _withDirectives((_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(_ctx.$style['bonus-withdrawals__input'])
                    }, [
                        _createVNode(PaymentsInput, {
                            bonus: "",
                            deposit: false,
                            "allow-input-suggested-amounts-only": false,
                            "suggested-amounts": [],
                            currency: formattedCurrency,
                            balance: _unref(amount),
                            "min-amount": _unref(minBonusValue),
                            "max-amount": _unref(maxBonusValue),
                            "input-value": _unref(paymentsInputValue),
                            "default-init-input-value": _unref(minBonusValue),
                            onChange: _unref(onChange),
                            onInput: _cache[0] || (_cache[0] = ($event)=>_unref(handleInput)($event.target.value))
                        }, null, 8, [
                            "balance",
                            "min-amount",
                            "max-amount",
                            "input-value",
                            "default-init-input-value",
                            "onChange"
                        ]),
                        _createVNode(_unref(VFastMoneyChoice), {
                            class: _normalizeClass(_ctx.$style['bonus-withdrawals__payments-sum-fast']),
                            items: _unref(fastChoicesOptions),
                            "current-value": _unref(fastChoiceValue),
                            "min-value": _unref(minBonusValue),
                            "max-value": _unref(maxValueFastChoice),
                            "max-position": _unref(FastMoneyMaxPosition).END,
                            "max-enabled": "",
                            dense: "",
                            "is-bordered": "",
                            onSelectValue: _unref(handleInput)
                        }, null, 8, [
                            "class",
                            "items",
                            "current-value",
                            "min-value",
                            "max-value",
                            "max-position",
                            "onSelectValue"
                        ])
                    ], 2)), [
                        [
                            _directive_data_test,
                            {
                                el: 'bonus-withdrawals__input'
                            }
                        ]
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['bonus-withdrawals__button-wrapper'])
                    }, [
                        _createVNode(VButton, _mergeProps(_unref(bonusButtonProps), {
                            onClick: _unref(onEncashBonus)
                        }), null, 16, [
                            "onClick"
                        ])
                    ], 2)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusWithdrawalsRoutePage'
                ]
            ]);
        };
    }
});
