import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onBeforeMount, onBeforeUnmount } from 'vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { CashoutModal } from 'web/src/modules/cashout/views';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
import { useCustomerHistoryDetailsRouteComponent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryDetailsRouteComponent',
    setup (__props) {
        const { isLoaded, currency, isCashOutModal, component, notificationText, selectedHistoryItem, betCashOutProps, onWithdrawalCancel, onCashOutInit, doCashOut, onSupportClick, onCashOutConfirm, closeCashOutModal, beforeMount, beforeUnmount } = useCustomerHistoryDetailsRouteComponent();
        onBeforeMount(beforeMount);
        onBeforeUnmount(beforeUnmount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['history-details-route-component']]: true,
                    [_ctx.$style['history-details-route-component--desktop']]: true
                })
            }, [
                _unref(selectedHistoryItem) && _unref(isLoaded) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(component)), {
                    key: 0,
                    transaction: _unref(selectedHistoryItem),
                    currency: _unref(currency),
                    "bet-cash-out": _unref(betCashOutProps),
                    onWithdrawalCancel: _unref(onWithdrawalCancel),
                    onCashOutInit: _unref(onCashOutInit),
                    onCashOut: _unref(doCashOut),
                    onSupportClick: _unref(onSupportClick),
                    onIdClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$copyToClipboard({
                            data: _unref(selectedHistoryItem).objectId,
                            notificationText: _unref(notificationText),
                            isProfileModalTooltip: true
                        }))
                }, null, 40, [
                    "transaction",
                    "currency",
                    "bet-cash-out",
                    "onWithdrawalCancel",
                    "onCashOutInit",
                    "onCashOut",
                    "onSupportClick"
                ])) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 1
                })),
                _createVNode(ModalPortal, {
                    selector: _unref(ModalSelector).DESKTOP_INNER_MODAL
                }, {
                    default: _withCtx(()=>[
                            _unref(isCashOutModal) ? (_openBlock(), _createBlock(_unref(CashoutModal), {
                                key: 0,
                                transaction: _unref(selectedHistoryItem),
                                onConfirm: _unref(onCashOutConfirm),
                                onCancel: _unref(closeCashOutModal)
                            }, null, 8, [
                                "transaction",
                                "onConfirm",
                                "onCancel"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }, 8, [
                    "selector"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerHistoryDetailsRouteComponent'
                ]
            ]);
        };
    }
});
