import { computed } from 'vue';
import { AccountHistoryStatus, StatusColor } from '@leon-hub/api-sdk';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { replacePlaceholders } from 'web/src/utils/replacePlaceholders';
import { useI18n } from 'web/src/modules/i18n/composables';
import { defaultDateTimeFormat } from '../../../utils';
// eslint-disable-next-line max-len
export default function useCustomerHistoryDetailsPayment(props) {
    const { $translate } = useI18n();
    const formatMoney = useFormatMoney();
    const amount = computed(()=>{
        const { transaction, currency } = props;
        const { debit, credit } = transaction;
        let text = '';
        if (debit) text = formatMoney(debit, {
            currency
        });
        if (credit) text = formatMoney(credit, {
            currency
        });
        return text;
    });
    const taxedAmount = computed(()=>{
        const taxedSum = props.transaction?.ndflAmounts?.taxedAmnt;
        if (taxedSum) return formatMoney(taxedSum);
        return '';
    });
    const sumText = computed(()=>taxedAmount.value ? $translate('WEB2_TRANSACTION_HISTORY_TRANSACTION_ORIGINAL_SUM').value : $translate('WEB2_TRANSACTION_HISTORY_TRANSACTION_SUM').value);
    const isWithdrawPending = computed(()=>{
        const { withdrawCancellable, status } = props.transaction;
        if (!withdrawCancellable) switch(status){
            case AccountHistoryStatus.PAY_STARTED:
            case AccountHistoryStatus.PAY_PROCESSING:
            case AccountHistoryStatus.PAY_WAITING:
                return true;
            default:
                break;
        }
        return false;
    });
    const txTypeName = computed(()=>{
        if (props.transaction?.placeholders?.length) return replacePlaceholders({
            text: props.transaction.txTypeName,
            placeholders: props.transaction?.placeholders,
            formatMoney
        });
        return props.transaction.txTypeName;
    });
    const dataRows = computed(()=>{
        const data = [
            {
                label: $translate('WEB2_TRANSACTION_HISTORY_TRANSACTION_TYPE').value,
                value: txTypeName.value
            },
            {
                label: $translate('WEB2_TRANSACTION_HISTORY_TRANSACTION_STATUS').value,
                value: props.transaction.statusName,
                highlight: props.transaction.itemStatusColor === StatusColor.PENDING
            },
            {
                label: $translate('WEB2_TRANSACTION_HISTORY_TRANSACTION_ACCEPTED').value,
                value: defaultDateTimeFormat(props.transaction.date)
            },
            {
                label: sumText.value,
                value: amount.value
            }
        ];
        if (taxedAmount.value) data.push({
            label: $translate('WEB2_TRANSACTION_HISTORY_TRANSACTION_TAXED_SUM').value,
            value: taxedAmount.value
        });
        return data;
    });
    return {
        amount,
        sumText,
        txTypeName,
        taxedAmount,
        isWithdrawPending,
        dataRows
    };
}
