import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "theme-switcher"
};
const _hoisted_2 = [
    "onClick"
];
const _hoisted_3 = [
    "src"
];
const _hoisted_4 = {
    class: "theme-switcher__button-label"
};
import { useVThemeSwitcher } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VThemeSwitcher',
    emits: [
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { themeList } = useVThemeSwitcher();
        function emitChange(theme) {
            emit('change', theme);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(themeList), (theme, index)=>(_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: _normalizeClass([
                            "theme-switcher__button",
                            {
                                'theme-switcher__button--active': theme.isActive
                            }
                        ]),
                        onClick: ($event)=>emitChange(theme.theme)
                    }, [
                        _createElementVNode("img", {
                            class: "theme-switcher__button-image",
                            src: require(`web/src/assets/images/theme-select-circle-${theme.theme.toLowerCase()}.svg`),
                            alt: ""
                        }, null, 8, _hoisted_3),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(theme.name), 1)
                    ], 10, _hoisted_2))), 128))
            ])), [
                [
                    _directive_auto_id,
                    'VThemeSwitcher'
                ]
            ]);
        };
    }
});
