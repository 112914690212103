import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode } from "vue";
import { onBeforeMount } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import CustomerHistoryList from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryList/CustomerHistoryList.vue';
import CustomerHistoryListItemDetails from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemDetails/CustomerHistoryListItemDetails.vue';
import { BetCashOutInline } from 'web/src/components/BetCashOut/components';
import { defaultCurrency } from 'web/src/modules/money/consts';
import { TestKeysEnum } from 'web/src/modules/profile/submodules/customer-history/types';
import { useCustomerHistoryDetailsBetDescription } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryDetailsBetDescription',
    props: {
        transaction: {},
        currency: {
            default: defaultCurrency
        },
        betCashOut: {},
        isWinBoost: {
            type: Boolean
        }
    },
    emits: [
        "cash-out-init",
        "id-click",
        "cash-out"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { cashOutProperties, dataRows } = useCustomerHistoryDetailsBetDescription(props);
        onBeforeMount(()=>{
            emit('cash-out-init', props.transaction);
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['history-details'])
            }, [
                _createVNode(CustomerHistoryList, {
                    class: _normalizeClass(_ctx.$style['history-details__list'])
                }, {
                    default: _withCtx(()=>[
                            _withDirectives((_openBlock(), _createBlock(CustomerHistoryListItemDetails, {
                                hover: "",
                                label: _ctx.$t('WEB2_BET_DETAILS_BET_NUMBER'),
                                value: _ctx.transaction.objectId,
                                onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('id-click'))
                            }, {
                                icon: _withCtx(()=>[
                                        _createVNode(_unref(VIcon), {
                                            class: _normalizeClass(_ctx.$style['history-details__copy-icon']),
                                            name: _unref(IconName).FILES,
                                            size: _unref(IconSize).SIZE_16
                                        }, null, 8, [
                                            "class",
                                            "name",
                                            "size"
                                        ])
                                    ]),
                                _: 1
                            }, 8, [
                                "label",
                                "value"
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: _unref(TestKeysEnum).ID
                                    }
                                ]
                            ]),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataRows), (row, index)=>_withDirectives((_openBlock(), _createBlock(CustomerHistoryListItemDetails, {
                                    key: index,
                                    label: row.label,
                                    value: row.value,
                                    highlight: row.highlight,
                                    "highlight-red": row.highlightRed,
                                    "odds-boost": row.oddsBoost,
                                    "un-boosted-price": row.unBoostedPrice,
                                    "win-boost": row.winBoost
                                }, null, 8, [
                                    "label",
                                    "value",
                                    "highlight",
                                    "highlight-red",
                                    "odds-boost",
                                    "un-boosted-price",
                                    "win-boost"
                                ])), [
                                    [
                                        _directive_data_test,
                                        row.testLabel ? {
                                            el: row.testLabel
                                        } : void 0
                                    ]
                                ])), 128)),
                            _unref(cashOutProperties) ? _withDirectives((_openBlock(), _createBlock(CustomerHistoryListItemDetails, {
                                key: 0
                            }, {
                                default: _withCtx(()=>[
                                        _createVNode(_unref(BetCashOutInline), _mergeProps(_unref(cashOutProperties), {
                                            "bet-price": props.transaction.debit,
                                            onCashOut: _cache[1] || (_cache[1] = ($event)=>_ctx.$emit('cash-out'))
                                        }), null, 16, [
                                            "bet-price"
                                        ])
                                    ]),
                                _: 1
                            })), [
                                [
                                    _directive_data_test,
                                    {
                                        el: _unref(TestKeysEnum).CASH_OUT_AVAILABLE
                                    }
                                ]
                            ]) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerHistoryDetailsBetDescription'
                ]
            ]);
        };
    }
});
