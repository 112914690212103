import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import PinButtons from 'web/src/modules/pin-code/components/PinButtons/PinButtons.vue';
import { PinButtonEvent } from 'web/src/modules/pin-code/enums';
import ResponsibleGamblingTimerTime from '../ResponsibleGamblingTimerTime/ResponsibleGamblingTimerTime.vue';
import { useResponsibleGamblingTimer } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingTimer',
    props: {
        hoursInit: {
            default: '00'
        },
        minutesInit: {
            default: '00'
        }
    },
    emits: [
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const pinButtonsProps = [
            {
                title: '1',
                value: PinButtonEvent.VALUE_1
            },
            {
                title: '2',
                value: PinButtonEvent.VALUE_2
            },
            {
                title: '3',
                value: PinButtonEvent.VALUE_3
            },
            {
                title: '4',
                value: PinButtonEvent.VALUE_4
            },
            {
                title: '5',
                value: PinButtonEvent.VALUE_5
            },
            {
                title: '6',
                value: PinButtonEvent.VALUE_6
            },
            {
                title: '7',
                value: PinButtonEvent.VALUE_7
            },
            {
                title: '8',
                value: PinButtonEvent.VALUE_8
            },
            {
                title: '9',
                value: PinButtonEvent.VALUE_9
            },
            {
                title: '00',
                value: PinButtonEvent.VALUE_00
            },
            {
                title: '0',
                value: PinButtonEvent.VALUE_0
            },
            {
                value: PinButtonEvent.BACKSPACE,
                isFilled: false,
                iconName: IconName.BACKSPACE
            }
        ];
        const { hours, minutes, handleClick } = useResponsibleGamblingTimer(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['responsible-gambling-timer'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-timer__header'])
                }, [
                    _createVNode(ResponsibleGamblingTimerTime, {
                        value: _unref(hours) || _ctx.hoursInit
                    }, null, 8, [
                        "value"
                    ]),
                    _createVNode(ResponsibleGamblingTimerTime, {
                        value: _unref(minutes) || _ctx.minutesInit,
                        "is-minutes": ""
                    }, null, 8, [
                        "value"
                    ])
                ], 2),
                _createVNode(PinButtons, {
                    "pin-buttons-list-initial": pinButtonsProps,
                    class: _normalizeClass(_ctx.$style['pin-code-route-component__buttons']),
                    onClick: _unref(handleClick)
                }, null, 8, [
                    "class",
                    "onClick"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingTimer'
                ]
            ]);
        };
    }
});
