import { useRouter } from 'vue-router';
import { computed, toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { InGameStepEnum } from 'web/src/modules/profile/submodules/responsible-gambling/store/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import useResponsibleGamblingStoreV2 from 'web/src/modules/profile/submodules/responsible-gambling/store/useResponsibleGamblingStoreV2';
import { useI18n } from 'web/src/modules/i18n/composables';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useResponsibleGamblingTimeInGameTopBarRoutePage(props) {
    const { $translate } = useI18n();
    const router = useRouter();
    const { timeInGameSetStep } = useResponsibleGamblingStoreV2();
    const step = toRef(useResponsibleGamblingStoreV2(), 'timeInGameStep');
    const title = computed(()=>{
        if (props.info) return '';
        return $translate('RG_TIME_IN_GAME_HEADER').value;
    });
    const suffixButtonProps = {
        iconName: IconName.INFO_OUTLINE,
        iconSize: IconSize.SIZE_24,
        kind: ButtonKind.TRANSPARENT
    };
    const closeButtonProperties = {
        kind: ButtonKind.NAV,
        iconName: IconName.CROSS,
        iconSize: IconSize.SIZE_24
    };
    const handlePrefix = ()=>{
        if (props.info) router.replace({
            name: RouteName.RESPONSIBLE_GAMBLING_TIME_IN_GAME
        });
        else if (step.value === InGameStepEnum.INITIAL) router.replace({
            name: RouteName.RESPONSIBLE_GAMBLING_LIMITS
        });
        else timeInGameSetStep(InGameStepEnum.INITIAL);
    };
    const handleSuffix = ()=>{
        router.push({
            name: RouteName.RESPONSIBLE_GAMBLING_TIME_IN_GAME_INFO
        });
    };
    function onClose() {
        router.closeModal();
    }
    return {
        step,
        title,
        suffixButtonProps,
        closeButtonProperties,
        handlePrefix,
        handleSuffix,
        onClose
    };
}
