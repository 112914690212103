import { computed, onMounted, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { AlertIconName } from '@leon-hub/icons';
import { PinCodePlace, PinCodeRequiredExceptionCode, responsibleGamingLimitTypePlayTimeReminder } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { getJumbotronProps } from 'web/src/components/Jumbotron';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { PresetName } from 'web/src/modules/dialogs/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { InGameActionEnum, InGameStepEnum } from 'web/src/modules/profile/submodules/responsible-gambling/store/enums';
import useResponsibleGamblingStoreV2 from 'web/src/modules/profile/submodules/responsible-gambling/store/useResponsibleGamblingStoreV2';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import useLocalizedTimeString from 'web/src/modules/i18n/composables/useLocalizedTimeString';
import normalizeTime from 'web/src/modules/profile/utils/normalizeTime';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
export default function useResponsibleGamblingTimeInGameRoutePage() {
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const { convertToBaseError } = useErrorsConverter();
    const pinCodeStore = usePinCodeStore();
    const router = useRouter();
    const { getTimeString } = useLocalizedTimeString();
    const responsibleGamblingStoreV2 = useResponsibleGamblingStoreV2();
    const { setResponsibleGamingLimits } = useCustomerDataStore();
    const timerData = toRef(responsibleGamblingStoreV2, 'timeInGameTimerData');
    const timeInGameLoadingState = toRef(responsibleGamblingStoreV2, 'timeInGameLoadingState');
    const timer = ref('');
    const step = computed(()=>useResponsibleGamblingStoreV2().timeInGameStep);
    const hours = computed(()=>{
        const [h] = normalizeTime(timerData.value?.state?.hours || 0, timerData.value?.state?.minutes || 0);
        return {
            count: `${h}`
        };
    });
    const minutes = computed(()=>{
        const [, m] = normalizeTime(timerData.value?.state?.hours || 0, timerData.value?.state?.minutes || 0);
        return {
            count: `${m}`
        };
    });
    const showTimerSet = ()=>{
        responsibleGamblingStoreV2.timeInGameSetStep(InGameStepEnum.TIMER);
    };
    const hideTimer = ()=>{
        responsibleGamblingStoreV2.timeInGameSetStep(InGameStepEnum.INITIAL);
    };
    const successSetDescriptionParams = computed(()=>{
        let timeName = null;
        if (timerData.value?.state?.timeLeft) timeName = getTimeString(hours, minutes);
        return {
            time: `${timeName?.hours.toLowerCase()} ${timeName?.minutes.toLowerCase()}`
        };
    });
    const checkPinCodeVerification = (originalError, type, time)=>{
        const error = convertToBaseError(originalError);
        if (error.code.equals(PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED)) {
            responsibleGamblingStoreV2.timeInGameSetData({
                type,
                time
            });
            pinCodeStore.setPinCodePlace(PinCodePlace.PLAY_TIME_CONTROL);
            pinCodeStore.setStepWithLS('VERIFY');
            pinCodeStore.setRouteFrom({
                name: RouteName.RESPONSIBLE_GAMBLING_TIME_IN_GAME
            });
            router.replace({
                name: RouteName.PIN_CODE_PROFILE
            });
        }
    };
    const handleChangeTimer = async (value)=>{
        try {
            const hoursNumber = Number(value.substring(0, 2));
            const minutesNumber = Number(value.substring(2));
            await responsibleGamblingStoreV2.timeInGameTimerActivate({
                hours: hoursNumber,
                minutes: minutesNumber
            });
            setResponsibleGamingLimits(responsibleGamingLimitTypePlayTimeReminder, true);
            showDialog({
                presetName: PresetName.ALERT_SUCCESS,
                options: {
                    ...getJumbotronProps({
                        alertIcon: AlertIconName.Success
                    }),
                    title: $translate('RG_TIME_IN_GAME_NOTIFICATION_SET_SUCCESS_TITLE').value,
                    confirmMessage: $translate('RG_TIME_IN_GAME_NOTIFICATION_SET_SUCCESS_DESCRIPTION', successSetDescriptionParams).value,
                    buttons: [
                        {
                            kind: ButtonKind.SECONDARY
                        }
                    ]
                }
            });
            timer.value = value;
            responsibleGamblingStoreV2.timeInGameSetStep(InGameStepEnum.INITIAL);
        } catch (originalError) {
            checkPinCodeVerification(originalError, InGameActionEnum.SET, value);
        }
    };
    async function resetTimer() {
        try {
            await responsibleGamblingStoreV2.timeInGameTimerDeactivate();
            setResponsibleGamingLimits(responsibleGamingLimitTypePlayTimeReminder, false);
            showDialog({
                presetName: PresetName.ALERT_SUCCESS,
                options: {
                    ...getJumbotronProps({
                        alertIcon: AlertIconName.Success
                    }),
                    title: $translate('RG_TIME_IN_GAME_NOTIFICATION_RESET_SUCCESS_TITLE').value,
                    confirmMessage: $translate('RG_TIME_IN_GAME_NOTIFICATION_RESET_SUCCESS_DESCRIPTION').value,
                    buttons: [
                        {
                            kind: ButtonKind.SECONDARY
                        }
                    ]
                }
            });
        } catch (originalError) {
            checkPinCodeVerification(originalError, InGameActionEnum.RESET);
        }
    }
    onMounted(async ()=>{
        if (responsibleGamblingStoreV2.timeInGameTimerSetupData) {
            switch(responsibleGamblingStoreV2.timeInGameTimerSetupData.type){
                case InGameActionEnum.RESET:
                    await resetTimer();
                    break;
                case InGameActionEnum.SET:
                    await handleChangeTimer(responsibleGamblingStoreV2.timeInGameTimerSetupData.time || '');
                    break;
                default:
                    break;
            }
            responsibleGamblingStoreV2.timeInGameSetData(null);
        } else await responsibleGamblingStoreV2.timeInGameGetState();
    });
    onMounted(()=>{
        responsibleGamblingStoreV2.timeInGameSetStep(InGameStepEnum.INITIAL);
    });
    return {
        step,
        timerData,
        successSetDescriptionParams,
        showTimerSet,
        resetTimer,
        handleChangeTimer,
        hideTimer,
        getTimeString,
        timeInGameLoadingState
    };
}
