import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "bonus-card-description-columns"
};
import ProfileBonusesCardDescriptionColumn from '../ProfileBonusesCardDescriptionColumn/ProfileBonusesCardDescriptionColumn.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesCardDescriptionColumns',
    props: {
        titleLeft: {},
        titleRight: {},
        valueLeft: {},
        valueRight: {},
        badgeCountLeft: {
            default: 0
        },
        badgeCountRight: {
            default: 0
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(ProfileBonusesCardDescriptionColumn, {
                    title: _ctx.titleLeft,
                    value: _ctx.valueLeft,
                    "badge-count": _ctx.badgeCountLeft
                }, null, 8, [
                    "title",
                    "value",
                    "badge-count"
                ]),
                _createVNode(ProfileBonusesCardDescriptionColumn, {
                    title: _ctx.titleRight,
                    value: _ctx.valueRight,
                    "badge-count": _ctx.badgeCountRight
                }, null, 8, [
                    "title",
                    "value",
                    "badge-count"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'ProfileBonusesCardDescriptionColumns'
                ]
            ]);
        };
    }
});
