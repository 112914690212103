import { getBonusBalance } from 'web/src/modules/profile/submodules/bonuses/utils';
import getTotalAvailable from './getTotalAvailable';
import getAvailableBonusAmount from './getAvailableBonusAmount';
import getAvailableFreebets from './getAvailableFreebets';
import getHasTransferAmount from './getHasTransferAmount';
export default function getBalanceProperties(props, $t, $formatMoney) {
    // in right section show available bonus if has transfer amount or it's leonshop (maxBonusCount = 0)
    const isAvailableBonusPresent = getHasTransferAmount(props) || !props.bonus?.maxBonusCount;
    return {
        titleLeft: $t('WEB2_BONUSES_FREEBET_AMOUNT').value,
        valueLeft: props.bonus ? $formatMoney(getBonusBalance(props.bonus)) : '',
        badgeCountLeft: getTotalAvailable(props),
        titleRight: isAvailableBonusPresent ? $t('WEB2_BONUS_BALANCE_AVAILABLE_BONUS_TITLE').value : $t('WEB2_BONUSES_FREEBET_AVAILABLE').value,
        valueRight: isAvailableBonusPresent ? $formatMoney(getAvailableBonusAmount(props)) : getAvailableFreebets(props)
    };
}
