import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "id"
];
import { toRef } from 'vue';
import useCustomerVerificationSumSub from './composables/useCustomerVerificationSumSub';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerVerificationSumSub',
    props: {
        initialTokenData: {}
    },
    setup (__props) {
        const props = __props;
        const { containerId } = useCustomerVerificationSumSub({
            initialTokenData: toRef(()=>props.initialTokenData)
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sumsub'])
            }, [
                _createElementVNode("div", {
                    id: _unref(containerId),
                    class: _normalizeClass(_ctx.$style['sumsub-container'])
                }, null, 10, _hoisted_1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerVerificationSumSub'
                ]
            ]);
        };
    }
});
