import { computed } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import useOnboarding from 'web/src/modules/onboarding/pages/composables/useOnboading';
import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';
import useOnboardingStore from 'web/src/modules/onboarding/store/useOnboardingStore';
export default function useResponsibleGamblingTopBarRouteComponent() {
    const router = useRouter();
    const onboarding = useOnboarding();
    const { getOnboarding } = useOnboardingStore();
    const hasResponsibleGamblingOnboarding = computed(()=>{
        const hasSlides = onboarding.getOnboardingById(OnboardingIdsEnum.RESPONSIBLE_GAMBLING);
        return !!hasSlides.value;
    });
    function handleOnboardingButtonClick() {
        router.push({
            name: RouteName.ONBOARDING,
            params: {
                id: OnboardingIdsEnum.RESPONSIBLE_GAMBLING
            }
        });
    }
    function handleCloseButtonClick() {
        router.closeModal();
    }
    async function onMountedComponent() {
        await getOnboarding({});
    }
    return {
        hasResponsibleGamblingOnboarding,
        handleCloseButtonClick,
        handleOnboardingButtonClick,
        onMountedComponent
    };
}
