import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ProgressHeight, ProgressKind } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import { ResponsibleGamblingQuizResultsKind } from '../../../enums';
export default function useResponsibleGamblingQuizProgress(props) {
    const { $translate } = useI18n();
    const computedKind = computed(()=>{
        switch(props.kind){
            case ResponsibleGamblingQuizResultsKind.ACCEPTABLE:
                return ProgressKind.MINT;
            case ResponsibleGamblingQuizResultsKind.CRITICAL:
                return ProgressKind.ERROR;
            case ResponsibleGamblingQuizResultsKind.MODERATELY:
                return ProgressKind.YELLOW;
            // eslint-disable-next-line unicorn/no-useless-switch-case
            case ResponsibleGamblingQuizResultsKind.DEFAULT:
            default:
                return ProgressKind.DEFAULT;
        }
    });
    const progressProps = computed(()=>({
            height: ProgressHeight.TINY,
            noShadow: props.noShadow,
            kind: computedKind.value,
            value: props.value,
            of: props.of
        }));
    const bottomLabel = computed(()=>{
        switch(props.kind){
            case ResponsibleGamblingQuizResultsKind.ACCEPTABLE:
                return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_PROGRESS_LABEL_ACCEPTABLE').value;
            case ResponsibleGamblingQuizResultsKind.CRITICAL:
                return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_PROGRESS_LABEL_CRITICAL').value;
            case ResponsibleGamblingQuizResultsKind.MODERATELY:
                return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_PROGRESS_LABEL_MODERATELY').value;
            // eslint-disable-next-line unicorn/no-useless-switch-case
            case ResponsibleGamblingQuizResultsKind.DEFAULT:
            default:
                return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_PROGRESS_LABEL_NORMAL').value;
        }
    });
    return {
        progressProps,
        bottomLabel
    };
}
