import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import LimitStatusCard from 'web/src/modules/responsible-gambling/submodules/sunny-limits/components/LimitStatusCard/LimitStatusCard.vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import SLimitStatusCard from 'web/src/modules/responsible-gambling/submodules/sunny-limits/components/LimitStatusCard/SLimitStatusCard.vue';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import { useResponsibleGamblingTimeInGameInitialStep } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingTimeInGameInitialStep',
    props: {
        timerData: {}
    },
    emits: [
        "set",
        "reset"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { title, buttonBackToTheGameProps, limitStatusCardProps, setTimer, resetTimer, handleBackToGame } = useResponsibleGamblingTimeInGameInitialStep(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['initial-step'])
            }, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createVNode(VHintBlock, {
                        "has-icon": false,
                        class: _normalizeClass(_ctx.$style['initial-step-notification'])
                    }, {
                        title: _withCtx(()=>[
                                _createElementVNode("span", null, _toDisplayString(_unref(title)), 1),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['initial-step-notification-description'])
                                }, _toDisplayString(_ctx.$t('RG_TIME_IN_GAME_INIT_STEP_DESCRIPTION')), 3)
                            ]),
                        _: 1
                    }, 8, [
                        "class"
                    ]),
                    _createVNode(LimitStatusCard, _mergeProps({
                        class: _ctx.$style['initial-step-status']
                    }, _unref(limitStatusCardProps), {
                        onSet: _unref(setTimer),
                        onChange: _unref(setTimer),
                        onReset: _unref(resetTimer)
                    }), null, 16, [
                        "class",
                        "onSet",
                        "onChange",
                        "onReset"
                    ])
                ], 64)),
                (_openBlock(), _createBlock(LButton, _mergeProps({
                    key: 3,
                    class: _ctx.$style['initial-step-button']
                }, _unref(buttonBackToTheGameProps), {
                    onClick: _unref(handleBackToGame)
                }), null, 16, [
                    "class",
                    "onClick"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingTimeInGameInitialStep'
                ]
            ]);
        };
    }
});
