import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 1
};
const _hoisted_2 = {
    class: "profile-bonuses-content"
};
const _hoisted_3 = {
    key: 0
};
const _hoisted_4 = {
    class: "profile-bonuses__title"
};
const _hoisted_5 = {
    class: "profile-bonuses__list"
};
const _hoisted_6 = {
    key: 1
};
const _hoisted_7 = {
    key: 0,
    class: "profile-bonuses__title"
};
const _hoisted_8 = {
    class: "profile-bonuses__empty"
};
const _hoisted_9 = {
    class: "profile-bonuses__empty-title"
};
const _hoisted_10 = {
    class: "profile-bonuses__empty-description"
};
import { defineAsyncComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import ProfileBonusesGeneralController from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesGeneralController/ProfileBonusesGeneralController.vue';
import ProfileBonusesComplexComponent from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/bonuses/views/ProfileBonusesComplexComponent/ProfileBonusesComplexComponent.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import PaymentsModalsController from 'web/src/modules/payments/components/PaymnetsModalsController/PaymentsModalsController.vue';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import ProfileBonusesTransferController from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesTransferController/ProfileBonusesTransferController.vue';
import ProfileBonusesFreebetController from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesFreebetController/ProfileBonusesFreebetController.vue';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { PaymentsModalType } from 'web/src/modules/payments/enums';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import { useProfileBonusesRouteComponent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesRouteComponent',
    setup (__props) {
        const ProfileBonusesFreespinController = defineAsyncComponent(()=>import(// eslint-disable-next-line max-len
            'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesFreespinController/ProfileBonusesFreespinController.vue'));
        const { loading, complexData, activeButton, withdrawnAmount, jumbotronProperties, complexCampaignId, switcherIsShown, list, beforeMount, beforeUnmount, fetchCurrentBonuses, handlePlay, showCollectModal, showRefuseConfirmModal, handleComplexBonus, handleConfirmation, handleModalClose, handleCloseSuccessModal, handleRedirectToPromotions, onHavePromoClick } = useProfileBonusesRouteComponent();
        onBeforeMount(beforeMount);
        onBeforeUnmount(beforeUnmount);
        return (_ctx, _cache)=>_unref(loading) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                key: 0
            })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _unref(complexData) ? (_openBlock(), _createBlock(ProfileBonusesComplexComponent, _mergeProps({
                    key: 0
                }, _unref(complexData), {
                    "campaign-id": _unref(complexCampaignId)
                }), null, 16, [
                    "campaign-id"
                ])) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _unref(withdrawnAmount) ? (_openBlock(), _createBlock(PaymentsModalsController, {
                        key: 0,
                        "modal-type": _unref(PaymentsModalType).DEPOSITS_OTHER_SUCCESS_MODAL,
                        "modal-text": _unref(withdrawnAmount),
                        "is-in-profile": "",
                        onClose: _unref(handleCloseSuccessModal)
                    }, null, 8, [
                        "modal-type",
                        "modal-text",
                        "onClose"
                    ])) : _createCommentVNode("", true),
                    _unref(jumbotronProperties) ? (_openBlock(), _createBlock(_unref(VJumbotron), _mergeProps({
                        key: 1
                    }, _unref(jumbotronProperties).main, {
                        class: {
                            'profile-bonuses-jumbotron': true,
                            'profile-bonuses-jumbotron__with-padding': _unref(switcherIsShown)
                        }
                    }), {
                        footer: _withCtx(()=>[
                                !!_unref(jumbotronProperties).button && _unref(jumbotronProperties).submit ? (_openBlock(), _createBlock(_unref(JumbotronFooterButton), _mergeProps({
                                    key: 0
                                }, _unref(jumbotronProperties).button, {
                                    onClick: _cache[0] || (_cache[0] = ($event)=>_unref(jumbotronProperties).submit())
                                }), null, 16)) : _createCommentVNode("", true),
                                _createVNode(_unref(JumbotronFooterButton), _mergeProps(_unref(jumbotronProperties).buttonClose, {
                                    onClick: _unref(handleModalClose)
                                }), null, 16, [
                                    "onClick"
                                ])
                            ]),
                        _: 1
                    }, 16, [
                        "class"
                    ])) : (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass({
                            'profile-bonuses': true,
                            'profile-bonuses__with-padding': _unref(switcherIsShown)
                        })
                    }, [
                        _createElementVNode("div", _hoisted_2, [
                            _unref(list) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (part, index)=>(_openBlock(), _createElementBlock("div", {
                                        key: index,
                                        class: "profile-bonuses-block"
                                    }, [
                                        _createElementVNode("span", _hoisted_4, _toDisplayString(part.title), 1),
                                        _createElementVNode("div", _hoisted_5, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(part.bonusesList, (bonus)=>(_openBlock(), _createElementBlock(_Fragment, {
                                                    key: bonus.bonusId
                                                }, [
                                                    'TRANSFER' === bonus.step ? (_openBlock(), _createBlock(ProfileBonusesTransferController, {
                                                        key: 0,
                                                        bonus: bonus,
                                                        "active-button": _unref(activeButton),
                                                        onCollect: _unref(showCollectModal)
                                                    }, null, 8, [
                                                        "bonus",
                                                        "active-button",
                                                        "onCollect"
                                                    ])) : 'FREESPIN' === bonus.step ? (_openBlock(), _createBlock(_unref(ProfileBonusesFreespinController), {
                                                        key: 1,
                                                        bonus: bonus,
                                                        "active-button": _unref(activeButton),
                                                        onCollect: _unref(showCollectModal),
                                                        onRefuse: _unref(showRefuseConfirmModal),
                                                        onTimerEnd: _unref(fetchCurrentBonuses)
                                                    }, null, 8, [
                                                        "bonus",
                                                        "active-button",
                                                        "onCollect",
                                                        "onRefuse",
                                                        "onTimerEnd"
                                                    ])) : 'FREEBET' === bonus.step ? (_openBlock(), _createBlock(ProfileBonusesFreebetController, {
                                                        key: 2,
                                                        bonus: bonus,
                                                        "active-button": _unref(activeButton),
                                                        onCollect: _unref(showCollectModal),
                                                        onRefuse: _unref(showRefuseConfirmModal),
                                                        onTimerEnd: _unref(fetchCurrentBonuses)
                                                    }, null, 8, [
                                                        "bonus",
                                                        "active-button",
                                                        "onCollect",
                                                        "onRefuse",
                                                        "onTimerEnd"
                                                    ])) : (_openBlock(), _createBlock(ProfileBonusesGeneralController, {
                                                        key: 3,
                                                        bonus: bonus,
                                                        "bonus-state": part.bonusState,
                                                        "active-button": _unref(activeButton),
                                                        onPlay: _unref(handlePlay),
                                                        onRefuse: _unref(showRefuseConfirmModal),
                                                        onComplex: _unref(handleComplexBonus),
                                                        onConfirmation: _unref(handleConfirmation),
                                                        onTimerEnd: _unref(fetchCurrentBonuses)
                                                    }, null, 8, [
                                                        "bonus",
                                                        "bonus-state",
                                                        "active-button",
                                                        "onPlay",
                                                        "onRefuse",
                                                        "onComplex",
                                                        "onConfirmation",
                                                        "onTimerEnd"
                                                    ]))
                                                ], 64))), 128))
                                        ])
                                    ]))), 128))
                            ])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('WEB2_BONUSES_AVAILABLE')), 1)),
                                _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('WEB2_BONUSES_AVAILABLE_NOT_FOUND')), 1),
                                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('WEB2_BONUSES_AVAILABLE_NOT_FOUND_DESCRIPTION')), 1),
                                    (_openBlock(), _createBlock(VButton, {
                                        key: 1,
                                        label: _ctx.$t('WEB2_BONUSES_PROMOTIONS_BUTTON'),
                                        kind: _unref(ButtonKind).BASE,
                                        rounded: "",
                                        "is-uppercase": false,
                                        onClick: _unref(handleRedirectToPromotions)
                                    }, null, 8, [
                                        "label",
                                        "kind",
                                        "onClick"
                                    ]))
                                ])
                            ])),
                            //@ts-ignore
                            _createCommentVNode("", true)
                        ])
                    ], 2))
                ], 64))
            ]));
    }
});
