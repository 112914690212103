import { computed } from 'vue';
import { ProfileBonusesComplexType } from 'web/src/modules/profile/submodules/bonuses/enums';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useI18nStore } from 'web/src/modules/i18n/store';
// eslint-disable-next-line max-len
export default function useProfileBonusesComplexController(props) {
    const { $translate } = useI18n();
    const i18nStore = useI18nStore();
    const isDepositBonus = computed(()=>props.type === ProfileBonusesComplexType.DEFAULT);
    const depositDescriptionBtrParams = computed(()=>({
            count: props.deposit || ''
        }));
    const depositDescription = computed(()=>$translate('WEB2_BONUSES_COMPLEX_START_DEPOSIT', depositDescriptionBtrParams).value);
    const columnsProperties = computed(()=>({
            titleLeft: $translate('WEB2_BONUSES_COMPLEX_NOMINAL').value,
            valueLeft: props.freespin?.nominal?.toString() || '',
            titleRight: $translate('WEB2_BONUSES_COMPLEX_DEPOSIT_FROM').value,
            valueRight: props.deposit || ''
        }));
    const headerPropertiesTitleBtrParams = computed(()=>({
            count: props.bonusAmount || ''
        }));
    const countBtrParams = computed(()=>({
            count: String(props.freespin?.count || '')
        }));
    const headerProperties = computed(()=>{
        let title = $translate('WEB2_BONUSES_COMPLEX_DEPOSIT_TITLE', headerPropertiesTitleBtrParams).value;
        if (props.type === ProfileBonusesComplexType.FREESPIN) {
            const count = props.freespin?.count ?? 0;
            title = 0 === count ? '0' : LanguageMicroSettings.plural([
                $translate('WEB2_BONUSES_COMPLEX_FREESPIN_ONE_TITLE', countBtrParams).value,
                $translate('WEB2_BONUSES_COMPLEX_FREESPIN_TWO_TITLE', countBtrParams).value,
                $translate('WEB2_BONUSES_COMPLEX_FREESPIN_MANY_TITLE', countBtrParams).value
            ], count, i18nStore.locale);
        }
        return {
            title,
            badgeSelect: true,
            active: props.active === props.index
        };
    });
    return {
        isDepositBonus,
        depositDescription,
        columnsProperties,
        headerProperties
    };
}
