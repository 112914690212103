import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "data-test-key"
];
const _hoisted_2 = [
    "data-test-key"
];
const _hoisted_3 = [
    "data-test-key"
];
const _hoisted_4 = [
    "data-test-key"
];
const _hoisted_5 = [
    "data-test-key"
];
const _hoisted_6 = [
    "data-test-key"
];
const _hoisted_7 = [
    "data-test-key"
];
const _hoisted_8 = {
    key: 0
};
const _hoisted_9 = [
    "data-test-key"
];
const _hoisted_10 = [
    "data-test-key"
];
import { StatusColor } from '@leon-hub/api-sdk';
import CustomerHistoryListItemLayout from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemLayout/CustomerHistoryListItemLayout.vue';
import { TestKeysEnum } from 'web/src/modules/profile/submodules/customer-history/types';
import { useCustomerHistoryListItemCombiBet } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryListItemCombiBet',
    props: {
        item: {}
    },
    setup (__props) {
        const props = __props;
        const { league, isLive, status, eventDescription, isCoefficient, coefficient, banker, date, predictionLabel, predictionChoice, score, homeCompetitor, awayCompetitor, eventUrl, goToEvent } = useCustomerHistoryListItemCombiBet(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(CustomerHistoryListItemLayout, {
                class: _normalizeClass({
                    [_ctx.$style['combi-bet-list-item--hover']]: !!_unref(eventUrl)
                }),
                onClick: _unref(goToEvent)
            }, {
                metaLeft: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['combi-bet-list-item__league']),
                            "data-test-key": _unref(TestKeysEnum).LEAGUE
                        }, _toDisplayString(_unref(league)), 11, _hoisted_1)
                    ]),
                metaRight: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass({
                                [_ctx.$style['combi-bet-list-item__status']]: true,
                                [_ctx.$style['combi-bet-list-item__status--pending']]: _ctx.item.outcomeStateColor === _unref(StatusColor).PENDING,
                                [_ctx.$style['combi-bet-list-item__status--negative']]: _ctx.item.outcomeStateColor === _unref(StatusColor).NEGATIVE,
                                [_ctx.$style['combi-bet-list-item__status--positive']]: _ctx.item.outcomeStateColor === _unref(StatusColor).POSITIVE
                            }),
                            "data-test-key": _unref(TestKeysEnum).STATUS
                        }, _toDisplayString(_unref(status)), 11, _hoisted_2)
                    ]),
                descriptionLeft: _withCtx(()=>[
                        _unref(eventDescription)[0] ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['combi-bet-list-item__event-description']),
                            "data-test-key": _unref(TestKeysEnum).EVENT_DESCRIPTION
                        }, _toDisplayString(_unref(eventDescription)[0]), 11, _hoisted_3)) : _createCommentVNode("", true),
                        _unref(eventDescription)[1] ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['combi-bet-list-item__event-description']),
                            "data-test-key": _unref(TestKeysEnum).EVENT_DESCRIPTION
                        }, _toDisplayString(_unref(eventDescription)[1]), 11, _hoisted_4)) : _createCommentVNode("", true)
                    ]),
                descriptionRight: _withCtx(()=>[
                        _createElementVNode("div", null, [
                            _unref(isCoefficient) ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['combi-bet-list-item__coefficient']),
                                "data-test-key": _unref(TestKeysEnum).ODDS
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['combi-bet-list-item__coefficient-label'])
                                }, _toDisplayString(_unref(coefficient)), 3),
                                _unref(banker) ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['combi-bet-list-item__banker'])
                                }, _toDisplayString(_ctx.$t('WEB2_BET_DETAILS_BANKER')), 3)) : _createCommentVNode("", true)
                            ], 10, _hoisted_5)) : _createCommentVNode("", true)
                        ])
                    ]),
                date: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['combi-bet-list-item__date']),
                            "data-test-key": _unref(TestKeysEnum).DATE
                        }, _toDisplayString(_unref(date)), 11, _hoisted_6)
                    ]),
                footerLeft: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['combi-bet-list-item__prediction'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['combi-bet-list-item__prediction-label']),
                                "data-test-key": _unref(TestKeysEnum).MARKET_NAME
                            }, _toDisplayString(_unref(predictionLabel)), 11, _hoisted_7),
                            _unref(predictionChoice) ? (_openBlock(), _createElementBlock("span", _hoisted_8, " - ")) : _createCommentVNode("", true),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['combi-bet-list-item__prediction-choice']),
                                "data-test-key": _unref(TestKeysEnum).RUNNER_NAME
                            }, _toDisplayString(_unref(predictionChoice)), 11, _hoisted_9)
                        ], 2)
                    ]),
                footerRight: _withCtx(()=>[
                        _unref(score) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass({
                                [_ctx.$style['combi-bet-list-item__score']]: true,
                                [_ctx.$style['combi-bet-list-item__score--positive']]: _unref(isLive)
                            }),
                            "data-test-key": _unref(TestKeysEnum).SCORE
                        }, [
                            _createElementVNode("span", null, _toDisplayString(_unref(homeCompetitor)), 1),
                            _cache[0] || (_cache[0] = _createElementVNode("span", null, " : ", -1)),
                            _createElementVNode("span", null, _toDisplayString(_unref(awayCompetitor)), 1)
                        ], 10, _hoisted_10)) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "class",
                "onClick"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerHistoryListItemCombiBet'
                ]
            ]);
        };
    }
});
