import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
import VStatus from 'web/src/components/Status/VStatus/VStatus.vue';
import ProfileBonusesCardDescriptionCustom from '../../components/ProfileBonusesCardDescriptionCustom/ProfileBonusesCardDescriptionCustom.vue';
import ProfileBonusesCardButtonController from '../ProfileBonusesCardButtonController/ProfileBonusesCardButtonController.vue';
import ProfileBonusesCardLayout from '../../components/ProfileBonusesCardLayout/ProfileBonusesCardLayout.vue';
import ProfileBonusesCardDescriptionLine from '../../components/ProfileBonusesCardDescriptionLine/ProfileBonusesCardDescriptionLine.vue';
import ProfileBonusesCardTimer from '../../components/ProfileBonusesCardTimer/ProfileBonusesCardTimer.vue';
import ProfileBonusesCardDescriptionColumns from '../../components/ProfileBonusesCardDescriptionColumns/ProfileBonusesCardDescriptionColumns.vue';
import ProfileBonusesCardProgress from '../../components/ProfileBonusesCardProgress/ProfileBonusesCardProgress.vue';
import { useProfileBonusesGeneralController } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesGeneralController',
    props: {
        bonus: {},
        bonusState: {},
        activeButton: {
            default: null
        }
    },
    emits: [
        "play",
        "refuse",
        "complex",
        "confirmation",
        "timer-end"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { layoutProperties, isComplexBonusValue, hasTwoColumns, oneColumnProperties, twoColumnsProperties, isDescriptionVisible, descriptionText, hasProgress, progressProperties, closeActiveBonusRequirement, isFreebetInProgress, bonusesCardButton, status, statusType, handleButtonClick } = useProfileBonusesGeneralController(props, emit);
        return (_ctx, _cache)=>{
            const _directive_collect = _resolveDirective("collect");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ProfileBonusesCardLayout, _normalizeProps(_guardReactiveProps(_unref(layoutProperties))), {
                default: _withCtx(()=>[
                        _unref(isComplexBonusValue) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _unref(hasTwoColumns) && _unref(twoColumnsProperties) ? (_openBlock(), _createBlock(ProfileBonusesCardDescriptionColumns, _normalizeProps(_mergeProps({
                                key: 0
                            }, _unref(twoColumnsProperties))), null, 16)) : (_openBlock(), _createBlock(ProfileBonusesCardDescriptionCustom, _normalizeProps(_mergeProps({
                                key: 1
                            }, _unref(oneColumnProperties))), null, 16))
                        ], 64)),
                        _unref(isDescriptionVisible) && !_unref(isComplexBonusValue) ? (_openBlock(), _createBlock(ProfileBonusesCardDescriptionLine, {
                            key: 1,
                            "description-title": _unref(descriptionText)
                        }, null, 8, [
                            "description-title"
                        ])) : _createCommentVNode("", true),
                        _unref(hasProgress) && !_unref(isComplexBonusValue) ? (_openBlock(true), _createElementBlock(_Fragment, {
                            key: 2
                        }, _renderList(_unref(progressProperties), (progress, index)=>(_openBlock(), _createBlock(ProfileBonusesCardProgress, _mergeProps({
                                ref_for: true
                            }, progress, {
                                key: index,
                                onTimerEnd: _cache[0] || (_cache[0] = ($event)=>emit('timer-end'))
                            }), null, 16))), 128)) : _ctx.bonus.availableBefore ? (_openBlock(), _createBlock(ProfileBonusesCardTimer, {
                            key: 3,
                            "expiration-date": _ctx.bonus.availableBefore,
                            onTimerEnd: _cache[1] || (_cache[1] = ($event)=>emit('timer-end'))
                        }, null, 8, [
                            "expiration-date"
                        ])) : _createCommentVNode("", true),
                        _unref(status) ? (_openBlock(), _createElementBlock("div", {
                            key: 4,
                            class: _normalizeClass(_ctx.$style['status'])
                        }, [
                            _withDirectives(_createVNode(VStatus, _normalizeProps(_guardReactiveProps(_unref(status))), null, 16), [
                                [
                                    _directive_collect,
                                    {
                                        bonuses: {
                                            disableButtons: _unref(statusType)
                                        }
                                    },
                                    "click-counter"
                                ]
                            ])
                        ], 2)) : _createCommentVNode("", true),
                        _unref(closeActiveBonusRequirement) || _unref(isFreebetInProgress) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(ProfileBonusesCardButtonController, _mergeProps({
                            key: 5
                        }, _unref(bonusesCardButton), {
                            onClick: _unref(handleButtonClick)
                        }), null, 16, [
                            "onClick"
                        ]))
                    ]),
                _: 1
            }, 16)), [
                [
                    _directive_auto_id,
                    'ProfileBonusesGeneralController'
                ]
            ]);
        };
    }
});
