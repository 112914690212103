import { computed } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default function useCustomerHistoryDetailsSupport(props, emit) {
    const analytics = useAnalytics();
    const isShowText = computed(// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    ()=>false);
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    function supportClick() {
        analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
            supportDetails: {
                openHelp: 'betHistory'
            }
        });
        emit('support-click');
    }
    return {
        isShowText,
        supportClick
    };
}
