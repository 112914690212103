import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
export default function useProfileInfo(props) {
    const router = useRouter();
    function handleClick() {
        if (props.isLoyaltyEnabled) router.push({
            name: CasinoRouteName.CASINO_LOYALTY_PROGRAM
        });
        else router.push({
            name: RouteName.CUSTOMER_SETTINGS
        });
    }
    return {
        handleClick
    };
}
