import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 1
};
import VList from 'web/src/components/List/VList/VList.vue';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import SListItemAccordion from 'web/src/components/List/SListItemAccordion/SListItemAccordion.vue';
import SListItem from 'web/src/components/List/SListItem/SListItem.vue';
import { ListType } from 'web/src/components/List/enums';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PinCode',
    props: {
        iconName: {},
        title: {},
        hasPinCode: {
            type: Boolean
        },
        hasLoginPinCode: {
            type: Boolean
        },
        showLoginPinCode: {
            type: Boolean
        }
    },
    emits: [
        "switch-pin-code",
        "change-pin-code",
        "switch-login-pin-code"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return _openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(VListItemAccordion, {
                    id: "1",
                    "icon-name": _ctx.iconName,
                    title: _ctx.title ?? '',
                    "have-switcher": "",
                    open: _ctx.hasPinCode,
                    "is-dynamic-open": "",
                    onClick: _cache[5] || (_cache[5] = ($event)=>emit('switch-pin-code', true))
                }, {
                    content: _withCtx(()=>[
                            _createVNode(VList, {
                                "list-type": _unref(ListType).GROUP
                            }, {
                                default: _withCtx(()=>[
                                        _withDirectives(_createVNode(VListItem, {
                                            title: _ctx.$t('WEB2_CHANGE_PIN_CODE'),
                                            "have-expand": "",
                                            onClick: _cache[3] || (_cache[3] = ($event)=>emit('change-pin-code', true))
                                        }, null, 8, [
                                            "title"
                                        ]), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'navigation-item',
                                                    navigationId: 'change-pin-code'
                                                }
                                            ]
                                        ]),
                                        _ctx.showLoginPinCode ? _withDirectives((_openBlock(), _createBlock(VListItem, {
                                            key: 0,
                                            title: _ctx.$t('PIN_CODE_FOR_LOGIN'),
                                            "have-switcher": "",
                                            "is-switch-checked": _ctx.hasLoginPinCode,
                                            onClick: _cache[4] || (_cache[4] = ($event)=>emit('switch-login-pin-code', true))
                                        }, null, 8, [
                                            "title",
                                            "is-switch-checked"
                                        ])), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'navigation-item',
                                                    navigationId: 'switch-login-pin-code'
                                                }
                                            ]
                                        ]) : _createCommentVNode("", true)
                                    ]),
                                _: 1
                            }, 8, [
                                "list-type"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "icon-name",
                    "title",
                    "open"
                ])
            ]);
        };
    }
});
