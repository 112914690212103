import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, mergeProps as _mergeProps, renderList as _renderList, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue";
import { defineAsyncComponent } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import SubHeader from 'web/src/components/SubHeader/VSubHeader/VSubHeader.vue';
import NavList from 'web/src/modules/profile/components/NavList';
import CustomerHistoryListRouteComponent from 'web/src/modules/profile/submodules/customer-history/pages/CustomerHistoryListRouteComponent/CustomerHistoryListRouteComponent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import RequestedAmount from 'web/src/modules/profile/components/RequestedAmount/RequestedAmount.vue';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
import BalanceUser from 'web/src/modules/profile/components/BalanceUser/BalanceUser.vue';
import useVerification from 'web/src/modules/profile/submodules/verification/composables/useVerification';
import SList from 'web/src/components/List/SList/SList.vue';
import SListItem from 'web/src/components/List/SListItem/SListItem.vue';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import SCashbackBalance from 'web/src/modules/profile/components/SCashbackBalance/SCashbackBalance.vue';
import SPersonalInfo from 'web/src/modules/profile/components/PersonalInfo/SPersonalInfo.vue';
import SCashbackWagerBalance from 'web/src/modules/profile/components/SCashbackWagerBalance/SCashbackWagerBalance.vue';
import useUserProfilePage from 'web/src/modules/profile/pages/UserProfileRoutePage/composables/useUserProfilePage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'UserProfileRoutePage',
    setup (__props) {
        const IdentificationStatusPanel = // eslint-disable-next-line max-len
        VEmpty;
        ButtonKind.BASE;
        const { userPhoneProfileNavigationFiltered, customerHistoryContainerProperties, isAccountVerificationRequired, verificationLabel, verificationAction, notificationType, balanceMode, pendingBetsCount, inGameValueFormatted, isRefAvailable, isShowPendingBetsHint, logout, onVerificationClick, onClickBetsInGame } = useUserProfilePage();
        const { isShowHintVideoVerificationProfile } = useVerification();
        // TODO: remove when LEONAPI-1648 will be done
        return (_ctx, _cache)=>{
            _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['user-profile']]: true,
                    [_ctx.$style['user-profile-desktop']]: true,
                    [_ctx.$style['user-profile-phone']]: false
                })
            }, [
                _createCommentVNode("", true),
                _unref(isAccountVerificationRequired) || _unref(isShowHintVideoVerificationProfile) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 2
                }, [
                    (_openBlock(), _createBlock(VHintBlock, {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['profile-page__hint']),
                        icon: _unref(isShowHintVideoVerificationProfile) ? _unref(IconName).PERSON : _unref(IconName).INFO_OUTLINE,
                        label: _unref(verificationLabel),
                        "label-accept": _unref(verificationAction),
                        onClick: _unref(onVerificationClick)
                    }, null, 8, [
                        "class",
                        "icon",
                        "label",
                        "label-accept",
                        "onClick"
                    ]))
                ], 64)) : (_openBlock(), _createBlock(_unref(IdentificationStatusPanel), {
                    key: 1,
                    "notification-type": _unref(notificationType),
                    class: _normalizeClass(_ctx.$style['profile-page__identification-status'])
                }, null, 8, [
                    "notification-type",
                    "class"
                ])),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['profile-page__account-review'])
                }, [
                    _createVNode(BalanceUser, {
                        mode: _unref(balanceMode)
                    }, null, 8, [
                        "mode"
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['profile-page__request-item'])
                    }, [
                        _createVNode(RequestedAmount)
                    ], 2),
                    _createCommentVNode("", true),
                    _createCommentVNode("", true),
                    _unref(isShowPendingBetsHint) ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['profile-page__in-game-value']),
                        onClick: _cache[0] || (_cache[0] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onClickBetsInGame) && _unref(onClickBetsInGame)(...args);
                        })
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['profile-page__in-game-value-caption'])
                        }, _toDisplayString(_ctx.$t('JS_BETS_IN_GAME')), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['profile-page__in-game-value-value'])
                        }, _toDisplayString(_unref(inGameValueFormatted)), 3),
                        _createVNode(VBadge, {
                            label: `${_unref(pendingBetsCount)}`,
                            class: _normalizeClass(_ctx.$style['profile-page__in-game-value-badge'])
                        }, null, 8, [
                            "label",
                            "class"
                        ])
                    ], 2)) : _createCommentVNode("", true)
                ], 2),
                (_openBlock(), _createBlock(SubHeader, {
                    key: 3,
                    class: _normalizeClass(_ctx.$style['profile-page__subheader']),
                    text: _ctx.$t('WEB2_PROFILE_HISTORY_PERIOD')
                }, null, 8, [
                    "class",
                    "text"
                ])),
                (_openBlock(), _createBlock(CustomerHistoryListRouteComponent, _normalizeProps(_mergeProps({
                    key: 4
                }, _unref(customerHistoryContainerProperties))), null, 16)),
                _createCommentVNode("", true),
                _createCommentVNode("", true),
                _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'UserProfileRoutePage'
                ]
            ]);
        };
    }
});
