import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { onBeforeMount } from 'vue';
import { IconName, SystemIconName } from '@leon-hub/icons';
import { ButtonKind } from '@leon-hub/module-buttons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import CustomerHistoryDetailsSupport from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryDetailsSupport/CustomerHistoryDetailsSupport.vue';
import CustomerHistoryListItemCombiBet from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemCombiBet/CustomerHistoryListItemCombiBet.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import CustomerHistoryList from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryList/CustomerHistoryList.vue';
import CustomerHistoryDetailsBetDescription from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryDetailsBetDescription/CustomerHistoryDetailsBetDescription.vue';
import CustomerHistoryDetailsScoreBoard from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryDetailsScoreBoard/CustomerHistoryDetailsScoreBoard.vue';
import CustomerHistoryDetailsCardStatistics from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryDetailsCardStatistics/CustomerHistoryDetailsCardStatistics.vue';
import { defaultCurrency } from 'web/src/modules/money/consts';
import { useCustomerHistoryDetailsBet } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryDetailsBet',
    props: {
        transaction: {},
        betCashOut: {},
        currency: {
            default: defaultCurrency
        }
    },
    emits: [
        "support-click",
        "id-click",
        "cash-out",
        "cash-out-init"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isLive, matchResult, isEventUrl, headingText, isPushEnabled, isPushSupported, isWinBoost, shareEnabled, onPushSwitch, beforeMount, shareBet } = useCustomerHistoryDetailsBet(props);
        onBeforeMount(beforeMount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['bet-details'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bet-details__statistics-wrapper'])
                }, [
                    _createVNode(CustomerHistoryDetailsScoreBoard, {
                        class: _normalizeClass(_ctx.$style['bet-details__scoreboard']),
                        transaction: _ctx.transaction
                    }, null, 8, [
                        "class",
                        "transaction"
                    ]),
                    _unref(matchResult) && !_unref(isEventUrl) ? (_openBlock(), _createBlock(CustomerHistoryDetailsCardStatistics, {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['bet-details__statistics']),
                        "match-result": _unref(matchResult)
                    }, null, 8, [
                        "class",
                        "match-result"
                    ])) : _createCommentVNode("", true),
                    _unref(shareEnabled) ? (_openBlock(), _createBlock(VButton, {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['bet-details__share']),
                        "icon-name": _unref(SystemIconName).SHARE,
                        label: _ctx.$t('WEB2_SHARE'),
                        "full-width": "",
                        kind: _unref(ButtonKind).BASE,
                        onClick: _unref(shareBet)
                    }, null, 8, [
                        "class",
                        "icon-name",
                        "label",
                        "kind",
                        "onClick"
                    ])) : _createCommentVNode("", true)
                ], 2),
                _createVNode(CustomerHistoryDetailsBetDescription, _mergeProps({
                    class: _ctx.$style['bet-details__description']
                }, props, {
                    "is-win-boost": _unref(isWinBoost),
                    onIdClick: _cache[0] || (_cache[0] = ($event)=>emit('id-click')),
                    onCashOutInit: _cache[1] || (_cache[1] = ($event)=>emit('cash-out-init', $event)),
                    onCashOut: _cache[2] || (_cache[2] = ($event)=>emit('cash-out'))
                }), null, 16, [
                    "class",
                    "is-win-boost"
                ]),
                _ctx.transaction.betItems && _ctx.transaction.betItems.length > 0 ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['bet-details__bet-list-wrapper'])
                }, [
                    _unref(headingText) ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['bet-details__bet-list-heading'])
                    }, _toDisplayString(_unref(headingText)), 3)) : _createCommentVNode("", true),
                    _createVNode(CustomerHistoryList, {
                        separate: ""
                    }, {
                        default: _withCtx(()=>[
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transaction.betItems, (item, index)=>(_openBlock(), _createBlock(CustomerHistoryListItemCombiBet, {
                                        key: index,
                                        item: item
                                    }, null, 8, [
                                        "item"
                                    ]))), 128))
                            ]),
                        _: 1
                    })
                ], 2)) : _createCommentVNode("", true),
                _unref(isPushSupported) ? (_openBlock(), _createBlock(VList, {
                    key: 1
                }, {
                    default: _withCtx(()=>[
                            _createVNode(VListItem, {
                                "have-switcher": "",
                                "icon-name": _unref(IconName).BELL_ON,
                                "is-switch-checked": _unref(isPushEnabled),
                                title: _ctx.$t('WEB2_PUSH_NOTIFICATION_TITLE'),
                                onClick: _unref(onPushSwitch)
                            }, null, 8, [
                                "icon-name",
                                "is-switch-checked",
                                "title",
                                "onClick"
                            ])
                        ]),
                    _: 1
                })) : _createCommentVNode("", true),
                _createVNode(CustomerHistoryDetailsSupport, {
                    class: _normalizeClass(_ctx.$style['bet-details__support']),
                    "is-live": _unref(isLive),
                    onSupportClick: _cache[3] || (_cache[3] = ($event)=>emit('support-click'))
                }, null, 8, [
                    "class",
                    "is-live"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerHistoryDetailsBet'
                ]
            ]);
        };
    }
});
