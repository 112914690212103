import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps } from "vue";
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { VLoader } from '@components/loader';
import { useUserStore } from 'web/src/modules/user/store';
import { useCustomerVerificationVideo } from './composables';
import CustomerVerificationConstructor from './CustomerVerificationConstructor.vue';
import CustomerVerificationStartScreen from './CustomerVerificationStartScreen.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerVerificationVideo',
    setup (__props) {
        const router = useRouter();
        const { loadUserData } = useUserStore();
        const { onClickAction, onClickActionSecond, onHideStartScreen, beforeMount, isLoading, isShowStartScreen, customerVerificationStateProps } = useCustomerVerificationVideo();
        onBeforeMount(beforeMount);
        return (_ctx, _cache)=>_unref(isLoading) ? (_openBlock(), _createBlock(_unref(VLoader), {
                key: 0
            })) : _unref(isShowStartScreen) ? (_openBlock(), _createBlock(CustomerVerificationStartScreen, {
                key: 1,
                onClick: _unref(onHideStartScreen)
            }, null, 8, [
                "onClick"
            ])) : (_openBlock(), _createBlock(CustomerVerificationConstructor, _mergeProps({
                key: 2
            }, _unref(customerVerificationStateProps), {
                onClick: _unref(onClickAction),
                onClickSecond: _unref(onClickActionSecond),
                onChangeTime: _cache[0] || (_cache[0] = ($event)=>_unref(router).push({
                        name: _unref(RouteName).CUSTOMER_VERIFICATION_SELECT_TIME
                    })),
                onTimeEnd: _cache[1] || (_cache[1] = ($event)=>_unref(loadUserData)())
            }), null, 16, [
                "onClick",
                "onClickSecond"
            ]));
    }
});
