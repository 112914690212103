import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import { InGameStepEnum } from 'web/src/modules/profile/submodules/responsible-gambling/store/enums';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import SNavigationButton from 'web/src/components/Button/SNavigationButton/SNavigationButton.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useResponsibleGamblingTimeInGameTopBarRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingTimeInGameTopBarRoutePage',
    props: {
        info: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { step, title, suffixButtonProps, closeButtonProperties, handlePrefix, handleSuffix, onClose } = useResponsibleGamblingTimeInGameTopBarRoutePage(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: _unref(title)
            }, _createSlots({
                suffix: _withCtx(()=>[
                        _unref(step) !== _unref(InGameStepEnum).INITIAL || _ctx.info ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            (_openBlock(), _createBlock(LButton, _mergeProps({
                                key: 1
                            }, _unref(suffixButtonProps), {
                                onClick: _withModifiers(_unref(handleSuffix), [
                                    "prevent"
                                ])
                            }), null, 16, [
                                "onClick"
                            ])),
                            (_openBlock(), _createBlock(VButton, _mergeProps({
                                key: 2
                            }, _unref(closeButtonProperties), {
                                onClick: _unref(onClose)
                            }), null, 16, [
                                "onClick"
                            ]))
                        ], 64))
                    ]),
                _: 2
            }, [
                void 0
            ]), 1032, [
                "title"
            ])), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingTimeInGameTopBarRoutePage'
                ]
            ]);
        };
    }
});
