import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "vv-start-screen"
};
const _hoisted_2 = [
    "src"
];
import { IconName } from '@leon-hub/icons';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerVerificationStartScreen',
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("img", {
                    src: require('web/src/assets/images/video-verification.svg'),
                    alt: "",
                    class: "vv-start-screen__image"
                }, null, 8, _hoisted_2),
                _createVNode(VCmsContent, {
                    class: "vv-start-screen__text",
                    "cms-key": "WEB2_VV_START_SCREEN_TEXT"
                }),
                _createVNode(VButton, {
                    label: _ctx.$t('WEB2_VV_START_SCREEN_BUTTON'),
                    "icon-name": _unref(IconName).CHECK_FILLED,
                    "full-width": "",
                    onClick: _cache[0] || (_cache[0] = ($event)=>emit('click'))
                }, null, 8, [
                    "label",
                    "icon-name"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'CustomerVerificationStartScreen'
                ]
            ]);
        };
    }
});
