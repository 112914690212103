import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "bonus-card"
};
const _hoisted_2 = {
    class: "bonus-card__content"
};
import ProfileBonusesCardHeader from '../ProfileBonusesCardHeader/ProfileBonusesCardHeader.vue';
import ProfileBonusesCardFooter from '../ProfileBonusesCardFooter/ProfileBonusesCardFooter.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesCardLayout',
    props: {
        bonusTitle: {
            default: ''
        },
        bonusBadgeText: {
            default: ''
        },
        termsLink: {},
        stepNumber: {},
        stepCount: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(ProfileBonusesCardHeader, {
                    title: _ctx.bonusTitle,
                    "badge-text": _ctx.bonusBadgeText
                }, null, 8, [
                    "title",
                    "badge-text"
                ]),
                _createElementVNode("div", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _createVNode(ProfileBonusesCardFooter, {
                    "terms-link": _ctx.termsLink,
                    "step-number": _ctx.stepNumber,
                    "step-count": _ctx.stepCount
                }, null, 8, [
                    "terms-link",
                    "step-number",
                    "step-count"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'ProfileBonusesCardLayout'
                ]
            ]);
        };
    }
});
