import { defineStore } from 'pinia';
import { ref } from 'vue';
const useEventsSubscriptionsRequestStore = defineStore('sportline-events-subscriptions-request', ()=>{
    const showSubscriptionRequest = ref();
    function setShowSubscriptionRequest(value) {
        showSubscriptionRequest.value = value;
    }
    return {
        showSubscriptionRequest,
        setShowSubscriptionRequest
    };
});
export default useEventsSubscriptionsRequestStore;
