import { computed, toRef } from 'vue';
import { AccountHistoryStatus, BetType } from '@leon-hub/api-sdk';
import { defaultDateTimeFormat } from 'web/src/modules/profile/submodules/customer-history/utils';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useBetPrice } from 'web/src/modules/profile/submodules/customer-history/composables';
import { getWinBoostPercentageString, getWinBoostValue } from 'web/src/modules/slip/submodules/accumulator-boost/utils';
import { getTaxAmount, getNettWinning } from 'web/src/modules/slip/utils';
import { TestKeysEnum } from '../../../types';
// eslint-disable-next-line max-len
export default function useCustomerHistoryDetailsBetDescription(props) {
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const { betPrice } = useBetPrice(toRef(props, 'transaction'));
    const typeProperties = computed(()=>{
        const text = props.transaction?.betMarket || props.transaction?.combiStatusText;
        if (!text) return null;
        return text;
    });
    const placeBetScoreProperties = computed(()=>{
        if (props.transaction?.matchResult?.placeBetMatchScore) {
            const { away, home } = props.transaction.matchResult.placeBetMatchScore;
            return `${home || ''} : ${away || ''}`;
        }
        return null;
    });
    const statusProperties = computed(()=>props.transaction?.statusName || '');
    const acceptedProperties = computed(()=>defaultDateTimeFormat(props.transaction?.date));
    const choiceProperties = computed(()=>props.transaction?.betOutcome ?? null);
    const bonusProperties = computed(()=>{
        const { bonusPoints } = props.transaction;
        if (!bonusPoints) return null;
        return `${$formatMoney(bonusPoints, {
            currency: 'L'
        })}`;
    });
    const stakeProperties = computed(()=>{
        const { freebetId, debit } = props.transaction;
        if (!debit) return null;
        const sum = $formatMoney(debit);
        return freebetId ? `${$translate('WEB2_FREEBET').value} ${sum}` : sum;
    });
    const isCalculated = computed(()=>props.transaction.status === AccountHistoryStatus.BET_CALCULATED || props.transaction.status === AccountHistoryStatus.BET_CALCULATED_CASHOUT);
    const resultProperties = computed(()=>{
        const { credit } = props.transaction;
        if (!isCalculated.value) return null;
        return credit ? $formatMoney(credit) : $translate('JSPBET_BETDET_LOST').value;
    });
    const cashOutProperties = computed(()=>props.betCashOut?.price ? props.betCashOut : null);
    const isShowBackCoefficient = computed(()=>{
        if (props.transaction?.betType === BetType.EXPRESS && !props.isWinBoost && props.transaction?.betClientPriceWithoutAccumulatorBoost && props.transaction.betClientPrice) return !!props.transaction?.betClientPriceWithoutAccumulatorBoost && props.transaction?.betClientPriceWithoutAccumulatorBoost !== props.transaction.betClientPrice;
        return false;
    });
    const accumulatorBoostPercent = computed(()=>props.isWinBoost ? `+${getWinBoostPercentageString(props.transaction.accumulatorBoost ?? 0)}` : `+${Math.round((Number(props.transaction.accumulatorBoost) - 1) * 100)}%`);
    const maxWinWithoutAccumulatorBoost = computed(()=>{
        const { debit, betClientPriceWithoutAccumulatorBoost } = props.transaction;
        if (isShowBackCoefficient.value && betClientPriceWithoutAccumulatorBoost) return $formatMoney(Number(debit * betClientPriceWithoutAccumulatorBoost));
        return null;
    });
    const showWinBonus = computed(()=>!!(props.transaction?.betType === BetType.EXPRESS && props.isWinBoost));
    const winBoostBonus = computed(()=>{
        if (!showWinBonus.value) return null;
        if (props.transaction.credit > 0) return (props.transaction.creditBeforeTax ?? props.transaction.credit) - (props.transaction.maxWin ?? 0);
        return getWinBoostValue({
            winValue: props.transaction.maxWin ?? 0,
            stake: props.transaction.debit,
            boostPercent: props.transaction.accumulatorBoost ?? 0
        });
    });
    const boostedMaxWin = computed(()=>{
        if (props.transaction.credit) return props.transaction.credit;
        if (!showWinBonus.value || !winBoostBonus.value) return props.transaction.maxWin ?? null;
        return (props.transaction.maxWin ?? 0) + winBoostBonus.value;
    });
    const winBonusValue = computed(()=>winBoostBonus.value ? $formatMoney(winBoostBonus.value) : null);
    const taxAmount = computed(()=>{
        if (isCalculated.value && props.transaction.credit <= 0 || !props.transaction.taxPercent || !props.transaction.maxWin) return null;
        if (props.transaction.taxAmount) return props.transaction.taxAmount;
        return getTaxAmount({
            winValue: props.transaction.maxWin,
            stake: props.transaction.debit,
            taxPercent: props.transaction.taxPercent
        });
    });
    const maxPriceProperties = computed(()=>{
        const { maxWin } = props.transaction;
        if (maxWin && !isCalculated.value) {
            const winValue = Number(showWinBonus.value ? boostedMaxWin.value : maxWin);
            const nettValue = getNettWinning({
                winValue,
                stake: props.transaction.debit,
                taxPercent: props.transaction.taxPercent ?? 0
            });
            return $formatMoney(nettValue);
        }
        return null;
    });
    const formattedTaxAmount = computed(()=>taxAmount.value ? $formatMoney(-taxAmount.value) : null);
    const taxPercent = computed(()=>props.transaction.taxPercent ? `${props.transaction.taxPercent}%` : null);
    const taxPercentAmount = computed(()=>({
            amount: taxPercent.value ?? ''
        }));
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const dataRows = computed(()=>{
        const result = [
            {
                testLabel: TestKeysEnum.STATUS,
                label: $translate('WEB2_BET_DETAILS_STATUS').value,
                highlight: props.transaction.status === AccountHistoryStatus.BET_WAITING,
                value: statusProperties.value
            },
            {
                testLabel: TestKeysEnum.PLACED_DATE,
                label: $translate('WEB2_BET_DETAILS_ACCEPTED').value,
                value: acceptedProperties.value
            }
        ];
        if (typeProperties.value) result.push({
            testLabel: TestKeysEnum.MARKET_NAME,
            label: $translate('WEB2_BET_DETAILS_BET_TYPE').value,
            value: typeProperties.value,
            oddsBoost: isShowBackCoefficient.value ? accumulatorBoostPercent.value : void 0
        });
        if (placeBetScoreProperties.value) result.push({
            testLabel: TestKeysEnum.PLACE_BET_SCORE,
            label: $translate('WEB2_BET_DETAILS_PLACE_BET_SCORE').value,
            value: placeBetScoreProperties.value
        });
        if (choiceProperties.value) result.push({
            testLabel: TestKeysEnum.RUNNER_NAME,
            label: $translate('WEB2_BET_DETAILS_CHOICE').value,
            value: choiceProperties.value
        });
        if (props.transaction.betClientPrice) result.push({
            testLabel: TestKeysEnum.ODDS,
            label: $translate('WEB2_BET_DETAILS_COEFFICIENT').value,
            unBoostedOdds: isShowBackCoefficient.value ? props.transaction.betClientPriceStrWithoutAccumulatorBoost ?? void 0 : void 0,
            value: `${betPrice.value}`,
            highlight: isShowBackCoefficient.value
        });
        if (bonusProperties.value) result.push({
            testLabel: TestKeysEnum.BONUS,
            label: $translate('WEB2_BET_DETAILS_BONUS_POINTS').value,
            highlight: true,
            value: bonusProperties.value
        });
        if (stakeProperties.value) result.push({
            testLabel: TestKeysEnum.STAKE,
            label: $translate('WEB2_BET_DETAILS_SUMM').value,
            value: stakeProperties.value
        });
        if (formattedTaxAmount.value) result.push({
            testLabel: TestKeysEnum.TAX,
            label: $translate('WEB2_BET_DETAILS_TAX', taxPercentAmount).value,
            value: formattedTaxAmount.value
        });
        if (winBonusValue.value) result.push({
            testLabel: TestKeysEnum.WIN_BOOST,
            label: $translate('WEB2_WIN_BOOST_HISTORY_CAPTION').value,
            winBoost: accumulatorBoostPercent.value,
            highlight: true,
            value: winBonusValue.value
        });
        if (maxPriceProperties.value) result.push({
            testLabel: TestKeysEnum.MAX_WIN,
            label: $translate('WEB2_BET_DETAILS_MAX_PRIZE').value,
            unBoostedPrice: isShowBackCoefficient.value ? maxWinWithoutAccumulatorBoost.value ?? '' : void 0,
            highlight: isShowBackCoefficient.value,
            value: maxPriceProperties.value
        });
        if (resultProperties.value) result.push({
            testLabel: TestKeysEnum.WIN_AMOUNT,
            label: $translate('WEB2_BET_DETAILS_WIN').value,
            highlight: props.transaction.credit > 0,
            highlightRed: props.transaction.credit <= 0,
            value: resultProperties.value
        });
        return result;
    });
    return {
        typeProperties,
        placeBetScoreProperties,
        statusProperties,
        choiceProperties,
        bonusProperties,
        stakeProperties,
        maxPriceProperties,
        resultProperties,
        cashOutProperties,
        dataRows
    };
}
