import { computed, onBeforeMount, toRef } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useTooltipStore } from 'web/src/modules/dialogs/store';
import { PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useAchievementsStore } from 'web/src/modules/profile/submodules/achievements/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useUserStore } from 'web/src/modules/user/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { usePersonalDataInternational } from './usePersonalDataInternational';
export default function usePersonalDataPage() {
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const { showTooltip } = useTooltipStore();
    useUserStore();
    const achievementsStore = useAchievementsStore();
    const siteConfigStore = useSiteConfigStore();
    const customerStore = useCustomerDataStore();
    const { isSubmitPending, isFormLoaded, profileSchema, customErrors, updateCustomerData, resetPendingState } = usePersonalDataInternational();
    const customerProfile = toRef(customerStore, 'customerData');
    const customerAchievementProfile = toRef(achievementsStore, 'profile');
    const isAvatarsEnabled = toRef(siteConfigStore, 'isAvatarsEnabled');
    toRef(siteConfigStore, 'isAchievementEnabled');
    const profile = computed(()=>customerAchievementProfile.value ?? customerProfile.value);
    function resetLoadingButton() {
        resetPendingState();
    }
    function submitModalHandler(promise) {
        promise.then((hasTooltip)=>{
            if (!hasTooltip) return;
            showTooltip({
                notificationText: $translate('WEB2_DATA_UPDATED').value,
                isProfileModalTooltip: true
            });
        }).catch((error)=>{
            showDialog({
                presetName: PresetName.ALERT_WARNING,
                options: {
                    title: $translate('JSPDEP_UKASH_DECLINED').value,
                    confirmMessage: error.message,
                    isProfileLayout: true,
                    dataTestId: 'personal-data-update-error'
                }
            });
        }).finally(()=>resetLoadingButton());
    }
    function onSubmit(data) {
        if (!data.errors) submitModalHandler(updateCustomerData(data));
    }
    onBeforeMount(async ()=>{});
    return {
        isFormLoaded,
        profileSchema,
        customErrors,
        profile,
        isAvatarsEnabled,
        isSubmitPending,
        onSubmit
    };
}
