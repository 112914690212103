import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ButtonKind } from '@leon-hub/module-buttons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useCustomerHistoryDetailsSupport } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryDetailsSupport',
    props: {
        isLive: {
            type: Boolean,
            default: true
        },
        isHiddenText: {
            type: Boolean
        }
    },
    emits: [
        "support-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isShowText, supportClick } = useCustomerHistoryDetailsSupport(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['details-support'])
            }, [
                _createVNode(VButton, {
                    label: _ctx.$t('WEB2_HELP'),
                    "full-width": "",
                    kind: _unref(ButtonKind).BASE,
                    onClick: _unref(supportClick)
                }, null, 8, [
                    "label",
                    "kind",
                    "onClick"
                ]),
                _unref(isShowText) ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['details-support__label'])
                }, _toDisplayString(_ctx.$t('WEB2_TRANSACTION_HISTORY_BET_TERMS')), 3)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerHistoryDetailsSupport'
                ]
            ]);
        };
    }
});
