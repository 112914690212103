import { computed, ref, toRef } from 'vue';
import { usePaymentsDepositsNavigation } from 'web/src/modules/core/composables/navigation';
import { useProfileBonusesStore } from 'web/src/modules/profile/submodules/bonuses/store';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { getComplexType } from 'web/src/modules/profile/submodules/bonuses/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
// eslint-disable-next-line max-len
export default function useProfileBonusesComplexComponent(props) {
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const { pushToDepositsPage } = usePaymentsDepositsNavigation();
    const profileBonusesStore = useProfileBonusesStore();
    const complexData = toRef(profileBonusesStore, 'complexData');
    const active = ref(null);
    const list = ref([]);
    const buttonProps = computed(()=>({
            label: $translate('WEB2_BONUSES_COMPLEX_DEPOSIT').value,
            kind: ButtonKind.PRIMARY,
            fullWidth: true,
            disabled: null === active.value
        }));
    const isSubmitButtonVisible = computed(()=>{
        const types = [
            ...new Set(list.value.map((item)=>item.requirementType))
        ];
        return !(types.length > 1 && null === active.value);
    });
    async function beforeMount() {
        const { data } = await profileBonusesStore.getBonusMatrix({
            bonusId: props.bonusId,
            bonusCampaignType: props.bonusCampaignType
        });
        list.value = data;
    }
    function getBonusData(bonus) {
        return {
            active: active.value ?? void 0,
            name: bonus.name,
            deposit: $formatMoney(Number(bonus?.requiredValue || '')),
            bonusAmount: $formatMoney(Number(bonus?.bonusAmount)) || '',
            freespin: bonus.freespin ?? void 0,
            type: getComplexType(bonus)
        };
    }
    function handleChange(index) {
        active.value = index;
    }
    function handleSubmit() {
        if (complexData.value && null !== active.value) pushToDepositsPage({
            query: {
                campaignId: props.campaignId ? String(props.campaignId) : '',
                sum: String(list.value[active.value].requiredValue)
            }
        });
    }
    return {
        list,
        buttonProps,
        isSubmitButtonVisible,
        beforeMount,
        getBonusData,
        handleChange,
        handleSubmit
    };
}
