import { computed, ref } from 'vue';
import { ButtonKind } from '@leon-hub/module-buttons';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useResponsibleGamblingTimeInGameTimerStep(props, emit) {
    const { $translate } = useI18n();
    const result = ref('');
    const responsibleGamblingTimerProps = computed(()=>{
        let hoursInit;
        let minutesInit;
        if (props.timerData?.state) {
            const { hours, minutes } = props.timerData.state;
            hoursInit = String(hours);
            minutesInit = String(minutes);
            if (hoursInit?.length < 2) hoursInit = `0${hoursInit}`;
            if (minutesInit?.length < 2) minutesInit = `0${minutesInit}`;
        }
        return {
            hoursInit,
            minutesInit
        };
    });
    const buttonSetProps = computed(()=>({
            label: $translate('RG_TIME_IN_GAME_TIMER_STEP_BUTTON_SET').value,
            kind: ButtonKind.PRIMARY,
            fullWidth: true,
            disabled: !result.value || '0000' === result.value
        }));
    const buttonCancelProps = computed(()=>({
            label: $translate('RG_TIME_IN_GAME_TIMER_STEP_BUTTON_CANCEL').value,
            kind: ButtonKind.TRANSPARENT,
            fullWidth: true
        }));
    const handleChange = (value)=>{
        result.value = value;
    };
    const setTimer = ()=>{
        emit('set', result.value);
    };
    const closeTimer = ()=>{
        emit('close');
    };
    return {
        responsibleGamblingTimerProps,
        buttonSetProps,
        buttonCancelProps,
        handleChange,
        setTimer,
        closeTimer
    };
}
