import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ResponsibleGamblingQuizResultsKind } from '../../../enums';
export default function useResponsibleGamblingQuizFinalDescription(props) {
    const { $translate } = useI18n();
    const iconName = computed(()=>{
        switch(props.kind){
            case ResponsibleGamblingQuizResultsKind.MODERATELY:
                return IconName.EMOTICON_NEUTRAL_OUTLINE;
            case ResponsibleGamblingQuizResultsKind.ACCEPTABLE:
                return IconName.EMOTICON_HAPPY_OUTLINE;
            case ResponsibleGamblingQuizResultsKind.CRITICAL:
                return IconName.EMOTICON_CRY_OUTLINE;
            case ResponsibleGamblingQuizResultsKind.DEFAULT:
            default:
                return IconName.EMOTICON_OUTLINE;
        }
    });
    const iconProps = computed(()=>({
            size: IconSize.SIZE_28,
            name: iconName.value
        }));
    const finalTitle = computed(()=>{
        switch(props.kind){
            case ResponsibleGamblingQuizResultsKind.ACCEPTABLE:
                return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_TITLE_ACCEPTABLE').value;
            case ResponsibleGamblingQuizResultsKind.MODERATELY:
                return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_TITLE_MODERATELY').value;
            case ResponsibleGamblingQuizResultsKind.CRITICAL:
                return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_TITLE_CRITICAL').value;
            case ResponsibleGamblingQuizResultsKind.DEFAULT:
            default:
                return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_TITLE_NORMAL').value;
        }
    });
    const finalDescription = computed(()=>{
        switch(props.kind){
            case ResponsibleGamblingQuizResultsKind.ACCEPTABLE:
                return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_DESCRIPTION_ACCEPTABLE').value;
            case ResponsibleGamblingQuizResultsKind.MODERATELY:
                return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_DESCRIPTION_MODERATELY').value;
            case ResponsibleGamblingQuizResultsKind.CRITICAL:
                return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_DESCRIPTION_CRITICAL').value;
            case ResponsibleGamblingQuizResultsKind.DEFAULT:
            default:
                return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_DESCRIPTION_NORMAL').value;
        }
    });
    return {
        iconProps,
        finalTitle,
        finalDescription
    };
}
