import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { Timer } from '@leon-hub/utils';
import RouteName from '@leon-hub/routing-config-names';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import normalizeTime from 'web/src/modules/profile/utils/normalizeTime';
export default function useResponsibleGamblingTimeInGameInitialStep(props, emit) {
    const { $translate } = useI18n();
    const router = useRouter();
    const timeLeft = ref('');
    const percentLeft = ref(props.timerData?.state?.percentLeft || 0);
    let totalDurationSeconds = 0;
    let intervalId = null;
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function updateTimeLeft(initialTime) {
        if (null !== intervalId) clearInterval(intervalId);
        const [hours, minutes, seconds] = initialTime.split(':').map(Number);
        totalDurationSeconds = 3600 * hours + 60 * minutes + seconds;
        let remainingSeconds = totalDurationSeconds;
        const tick = ()=>{
            if (remainingSeconds > 0) {
                remainingSeconds -= 1;
                const h = Math.floor(remainingSeconds / 3600) < 10 ? `0${Math.floor(remainingSeconds / 3600)}` : Math.floor(remainingSeconds / 3600);
                const m = Math.floor(remainingSeconds % 3600 / 60) < 10 ? `0${Math.floor(remainingSeconds % 3600 / 60)}` : Math.floor(remainingSeconds % 3600 / 60);
                const s = remainingSeconds % 60 < 10 ? `0${remainingSeconds % 60}` : remainingSeconds % 60;
                timeLeft.value = `${h}:${m}:${s}`;
                percentLeft.value = Number((remainingSeconds / totalDurationSeconds * (props.timerData?.state?.percentLeft || 0)).toFixed(2));
            } else {
                if (intervalId) Timer.clearInterval(intervalId);
                timeLeft.value = '00:00:00';
                percentLeft.value = 0;
            }
        };
        return Timer.setInterval(tick, 1000);
    }
    function subHeadingFormat(hours, minutes) {
        const [nHours, nMinutes] = normalizeTime(hours, minutes);
        const finalHours = `${nHours}`;
        const finalMinutes = `${nMinutes}`;
        const formattedHours = finalHours.padStart(2, '0');
        const formattedMinutes = finalMinutes.padStart(2, '0');
        return `${formattedHours} ${$translate('WEB2_RESPONSIBLE_GAMBLING_TIMER_HOURS').value} ${formattedMinutes} ${$translate('WEB2_RESPONSIBLE_GAMBLING_TIMER_MINUTES').value}`;
    }
    const limitStatusCardProps = computed(()=>{
        let result = {
            subHeading: '',
            progressText: '',
            isLimitActive: false,
            isTimer: true
        };
        if (props.timerData?.state?.timeLeft) {
            const { state } = props.timerData;
            result = {
                subHeading: subHeadingFormat(state.hours, state.minutes),
                progressText: timeLeft.value,
                percentage: 100 - percentLeft.value,
                isLimitActive: true,
                isTimer: true,
                startDt: props.timerData?.state?.startDt,
                hours: props.timerData?.state?.hours,
                minutes: props.timerData?.state?.minutes
            };
        }
        return result;
    });
    const title = computed(()=>{
        if (limitStatusCardProps.value.isLimitActive) return $translate('RG_TIME_IN_GAME_INIT_STEP_TITLE_YES').value;
        return $translate('RG_TIME_IN_GAME_INIT_STEP_TITLE_NO').value;
    });
    const buttonBackToTheGameProps = computed(()=>({
            label: $translate('RG_TIME_IN_GAME_INIT_STEP_BACK_TO_THE_GAME').value,
            kind: ButtonKind.BASE,
            fullWidth: true
        }));
    const handleBackToGame = ()=>{
        router.replace({
            name: RouteName.HOME
        });
    };
    watch(()=>props.timerData?.state, (newState)=>{
        if (newState?.timeLeft) intervalId = updateTimeLeft(newState.timeLeft);
    }, {
        immediate: true
    });
    onMounted(()=>{
        if (props.timerData?.state?.timeLeft) intervalId = updateTimeLeft(props.timerData.state.timeLeft);
    });
    const setTimer = ()=>{
        emit('set');
    };
    const resetTimer = ()=>{
        emit('reset');
    };
    return {
        title,
        buttonBackToTheGameProps,
        limitStatusCardProps,
        setTimer,
        resetTimer,
        handleBackToGame
    };
}
