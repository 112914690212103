import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { VIcon } from '@components/v-icon';
import { ResponsibleGamblingOptionType } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
import useResponsibleGamblingOption from './composables/useResponsibleGamblingOption';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingOption',
    props: {
        type: {
            default: ResponsibleGamblingOptionType.TEST
        },
        routeName: {}
    },
    setup (__props) {
        const props = __props;
        const { iconProps, optionTitle, onClick } = useResponsibleGamblingOption(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("button", {
                class: _normalizeClass(_ctx.$style['gambling-option']),
                type: "button",
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onClick) && _unref(onClick)(...args);
                })
            }, [
                _createElementVNode("span", {
                    class: _normalizeClass({
                        [_ctx.$style['gambling-option__icon']]: true,
                        [_ctx.$style[`gambling-option__icon--${_ctx.type}`]]: true
                    })
                }, [
                    _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(iconProps))), null, 16)
                ], 2),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['gambling-option__title'])
                }, _toDisplayString(_unref(optionTitle)), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingOption'
                ]
            ]);
        };
    }
});
