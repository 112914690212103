import { isBoolean, isObject } from '@leon-hub/guards';
export var ConsentsTextEnum;
var ConsentsTextEnum1;
(ConsentsTextEnum1 = ConsentsTextEnum || (ConsentsTextEnum = {}))["EMAIL"] = "email", ConsentsTextEnum1["SMS"] = "sms", ConsentsTextEnum1["TELEPHONE"] = "telephone", ConsentsTextEnum1["SITE_NOTIFICATION"] = "siteNotification", ConsentsTextEnum1["PUSH_NOTIFICATION"] = "pushNotification";
export const isUpdateNotificationConsentsRequest = (value)=>{
    if (!isObject(value)) return false;
    const { email, sms, telephone, postMail, siteNotification, pushNotification } = value;
    return isBoolean(email) && isBoolean(sms) && isBoolean(telephone) && (!postMail || isBoolean(postMail)) && isBoolean(siteNotification) && isBoolean(pushNotification);
};
const consentTexts = Object.values(ConsentsTextEnum);
export function isConsentsTextEnum(value) {
    return consentTexts.includes(value);
}
