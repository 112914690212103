import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getBonusButtons, getLayoutProperties, getTermsLink, getTransferAmount } from 'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesTransferController/composables/utils';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default function useProfileBonusesTransferController(props) {
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const termsLink = computed(()=>getTermsLink(props));
    const layoutProperties = computed(()=>getLayoutProperties(props));
    const transferAmount = computed(()=>getTransferAmount(props, $formatMoney));
    const bonusButtons = computed(()=>getBonusButtons(props, $translate));
    const descriptionTitle = computed(()=>`${$translate('WEB2_BONUS_BALANCE_AVAILABLE_BONUS_TITLE').value}:`);
    return {
        layoutProperties,
        termsLink,
        transferAmount,
        bonusButtons,
        descriptionTitle
    };
}
