import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { onBeforeMount, onBeforeUnmount } from 'vue';
import useResponsibleGamblingBetLimitRoutePage from 'web/src/modules/profile/submodules/responsible-gambling/pages/ResponsibleGamblingBetLimitRoutePage/composables/useResponsibleGamblingBetLimitRoutePage';
import ResponsibleGamblingInfoStep from 'web/src/modules/profile/submodules/responsible-gambling/steps/ResponsibleGamblingInfoStep/ResponsibleGamblingInfoStep.vue';
import { StepName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
import ResponsibleGamblingEditStep from 'web/src/modules/profile/submodules/responsible-gambling/steps/ResponsibleGamblingEditStep/ResponsibleGamblingEditStep.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingBetLimitRoutePage',
    setup (__props) {
        const { isInitialized, step, limit, switcherOptions, balance, minAmount, loading, circularProgressData, placeholder, description, setActiveTab, setActiveStep, submitLimit, resetLimit, handleBack, beforeMounted, beforeUnmounted } = useResponsibleGamblingBetLimitRoutePage();
        onBeforeMount(()=>{
            beforeMounted();
        });
        onBeforeUnmount(()=>{
            beforeUnmounted();
        });
        return (_ctx, _cache)=>_unref(isInitialized) ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 1
            }, [
                _unref(step) === _unref(StepName).INFO ? (_openBlock(), _createBlock(ResponsibleGamblingInfoStep, {
                    key: 0,
                    limit: _unref(limit),
                    "switcher-options": _unref(switcherOptions),
                    data: _unref(circularProgressData),
                    loading: _unref(loading),
                    onTabChange: _unref(setActiveTab),
                    onStepChange: _unref(setActiveStep),
                    onReset: _unref(resetLimit),
                    onBack: _unref(handleBack)
                }, null, 8, [
                    "limit",
                    "switcher-options",
                    "data",
                    "loading",
                    "onTabChange",
                    "onStepChange",
                    "onReset",
                    "onBack"
                ])) : _unref(step) === _unref(StepName).EDIT ? (_openBlock(), _createBlock(ResponsibleGamblingEditStep, {
                    key: 1,
                    description: _unref(description),
                    balance: _unref(balance),
                    "min-amount": _unref(minAmount),
                    placeholder: _unref(placeholder),
                    loading: _unref(loading),
                    onStepChange: _unref(setActiveStep),
                    onSubmit: _unref(submitLimit),
                    onBack: _unref(handleBack)
                }, null, 8, [
                    "description",
                    "balance",
                    "min-amount",
                    "placeholder",
                    "loading",
                    "onStepChange",
                    "onSubmit",
                    "onBack"
                ])) : _createCommentVNode("", true)
            ], 64)) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                key: 0
            }));
    }
});
