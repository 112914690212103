import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import OddsList from 'web/src/modules/sportline/submodules/odds/components/OddsList/OddsList.vue';
import useOddsStore from 'web/src/modules/sportline/submodules/odds/store/useOddsStore';
export default /*@__PURE__*/ _defineComponent({
    __name: 'OddsSelectorItem',
    props: {
        hasBackground: {
            type: Boolean
        },
        iconName: {}
    },
    setup (__props) {
        const oddStore = useOddsStore();
        const oddsList = toRef(oddStore, 'oddsList');
        const currentOdd = toRef(oddStore, 'userOddTypeLocal');
        function selectOdd(oddType) {
            oddStore.setCustomerOdd(oddType);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VListItemAccordion, {
                id: "1",
                title: _ctx.$t('WEB2_ODDS'),
                "icon-name": _ctx.iconName,
                "no-background": !_ctx.hasBackground
            }, {
                content: _withCtx(()=>[
                        _createVNode(OddsList, {
                            odd: currentOdd.value,
                            odds: oddsList.value,
                            onClick: _cache[0] || (_cache[0] = ($event)=>selectOdd($event))
                        }, null, 8, [
                            "odd",
                            "odds"
                        ])
                    ]),
                _: 1
            }, 8, [
                "title",
                "icon-name",
                "no-background"
            ])), [
                [
                    _directive_auto_id,
                    'OddsSelectorItem'
                ]
            ]);
        };
    }
});
