import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeProps as _normalizeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 1
};
import { onMounted } from 'vue';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import VForm from 'web/src/components/Form/components/VForm/VForm.vue';
import { useBonusCodeInputRouteComponent } from './composables';
import schema from '../../schemas/bonusCode.json';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusCodeInputRouteComponent',
    setup (__props) {
        const { formRef, jumbotronSuccessProperties, isLink, isSuccessState, isLoading, uiSchema, bonusCodeInputFormStatus, buttonLinkProperties, buttonConfirmProperties, buttonCloseProperties, customErrors, actionUrlClick, onCloseModal, formSubmit, onMountedComponent } = useBonusCodeInputRouteComponent();
        onMounted(onMountedComponent);
        return (_ctx, _cache)=>{
            _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(bonusCodeInputFormStatus) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([
                    "bonus-code-enter",
                    {
                        [_ctx.$style['bonus-code-enter']]: true,
                        [_ctx.$style['bonus-code-enter--success']]: _unref(isSuccessState)
                    }
                ])
            }, [
                _unref(isSuccessState) ? (_openBlock(), _createBlock(_unref(VJumbotron), _normalizeProps(_mergeProps({
                    key: 0
                }, _unref(jumbotronSuccessProperties))), {
                    footer: _withCtx(()=>[
                            _createCommentVNode("", true),
                            _createCommentVNode("", true),
                            _createVNode(_unref(JumbotronFooterButton), _mergeProps(_unref(buttonCloseProperties), {
                                onClick: _unref(onCloseModal)
                            }), null, 16, [
                                "onClick"
                            ])
                        ]),
                    _: 1
                }, 16)) : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("p", {
                        class: _normalizeClass(_ctx.$style['bonus-code-enter__description'])
                    }, _toDisplayString(_ctx.$t('WEB2_ACTIVATE_BONUS_CODE_DESCRIPTION')), 3),
                    _createVNode(VForm, {
                        ref_key: "formRef",
                        ref: formRef,
                        "custom-errors": _unref(customErrors),
                        "ui-schema": _unref(uiSchema),
                        schema: _unref(schema),
                        "is-pending": _unref(isLoading),
                        onSubmit: _unref(formSubmit)
                    }, null, 8, [
                        "custom-errors",
                        "ui-schema",
                        "schema",
                        "is-pending",
                        "onSubmit"
                    ])
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusCodeInputRouteComponent'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
