import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default function useProfileBonusesCardFooter(props) {
    const analytics = useAnalytics();
    const iconProps = {
        name: IconName.QUESTION_OUTLINE,
        size: IconSize.SIZE_16
    };
    const stepNumberString = computed(()=>props.stepNumber ? props.stepNumber.toString() : '');
    const stepCountString = computed(()=>props.stepCount ? props.stepCount.toString() : '');
    function handleTermsLinkClick() {
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                bonuses: 'bonusConditions'
            }
        });
    }
    return {
        iconProps,
        stepNumberString,
        stepCountString,
        handleTermsLinkClick
    };
}
