import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
export default function useResponsibleGamblingTestRoutePage() {
    const router = useRouter();
    function onClick() {
        router.push({
            name: RouteName.RESPONSIBLE_GAMBLING_QUIZ
        });
    }
    return {
        onClick
    };
}
