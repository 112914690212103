import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { IconName, IconSize } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useBonusStore } from 'web/src/modules/bonuses/store';
export default function useBonusCodeInputRouteComponent() {
    const { $translate } = useI18n();
    const router = useRouter();
    const bonusStore = useBonusStore();
    const activateBonusCodeSuccess = toRef(bonusStore, 'activateBonusCodeSuccess');
    const activateBonusCodeError = toRef(bonusStore, 'activateBonusCodeError');
    const isSuccessState = ref(false);
    const isLoading = ref(false);
    const formRef = ref();
    // eslint-disable-next-line max-len
    const campaign = computed(()=>activateBonusCodeSuccess.value?.campaign);
    const isLink = computed(()=>!!campaign.value?.categoryId && !!campaign.value?.actionUrl);
    const actionName = computed(()=>campaign.value?.name || '');
    const bCode = computed(()=>router.getQuery('bcode')?.toString() || '');
    const bonusCodeInputFormStatus = toRef(useSiteConfigStore(), 'bonusCodeInputFormStatus');
    const uiSchema = computed(()=>({
            submitButton: {
                label: $translate('WEB2_ACTIVATE_BUTTON').value
            },
            fields: {
                bonusCode: {
                    title: $translate('WEB2_BONUS_CODE_INPUT_LABEL_MODAL').value,
                    default: bCode.value,
                    options: {
                        autocomplete: 'off'
                    }
                }
            }
        }));
    const customErrors = computed(()=>{
        if (!activateBonusCodeError.value) return {};
        return {
            bonusCode: [
                activateBonusCodeError.value
            ]
        };
    });
    const jumbotronSuccessProperties = computed(()=>({
            heading: $translate('WEB2_BONUS_CODE_ACTIVATED').value,
            text: $translate('WEB2_BONUS_CODE_ACTIVATED_MESSAGE').value,
            icon: IconName.CHECK_OUTLINE,
            iconSize: IconSize.SIZE_80,
            iconKind: JumbotronIconKind.SUCCESS
        }));
    const buttonLinkProperties = computed(()=>({
            label: actionName.value,
            kind: ButtonKind.TRANSPARENT,
            fullWidth: true
        }));
    const buttonConfirmProperties = computed(()=>({
            label: $translate('WEB2_BONUS_PARTICIPATION_ACCEPTED').value,
            kind: ButtonKind.PRIMARY,
            fullWidth: true
        }));
    const buttonCloseProperties = computed(()=>({
            label: $translate('WEB2_CLOSE').value,
            kind: ButtonKind.BASE,
            fullWidth: true
        }));
    function actionUrlClick() {
        const options = {
            params: {
                categoryId: campaign.value?.categoryId,
                actionUrl: campaign.value?.actionUrl
            }
        };
        if (isLink.value) router.push({
            name: RouteName.PROMOTION_DETAILS,
            ...options
        });
    }
    function onCloseModal() {
        isSuccessState.value = false;
        router.replace({
            name: RouteName.PROFILE
        });
    }
    async function formSubmit(data) {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        const value = data.formData.bonusCode.toString();
        isLoading.value = true;
        await bonusStore.activateBonusCode(value);
        if (activateBonusCodeSuccess.value?.result === 'OK') isSuccessState.value = true;
        isLoading.value = false;
    }
    function onMountedComponent() {
        formRef.value?.focusByName('bonusCode');
    }
    return {
        formRef,
        jumbotronSuccessProperties,
        isLink,
        isSuccessState,
        isLoading,
        uiSchema,
        bonusCodeInputFormStatus,
        buttonLinkProperties,
        buttonConfirmProperties,
        buttonCloseProperties,
        customErrors,
        actionUrlClick,
        onCloseModal,
        formSubmit,
        onMountedComponent
    };
}
