import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx } from "vue";
import VCircularProgress from 'web/src/components/CircularProgress/VCircularProgress/VCircularProgress.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useLimitStatusCard } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LimitStatusCard',
    props: {
        subHeading: {},
        percentage: {},
        progressText: {},
        isLimitActive: {
            type: Boolean,
            default: false
        },
        isTimer: {
            type: Boolean,
            default: false
        },
        startDt: {},
        hours: {},
        minutes: {}
    },
    emits: [
        "set",
        "change",
        "reset"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { heading, progressText, subHeading, progressProperties, buttonPrimaryProperties, buttonSecondaryProperties } = useLimitStatusCard(props);
        function setLimit() {
            emit('set');
        }
        function changeLimit() {
            emit('change');
        }
        function resetLimit() {
            emit('reset');
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['limit-status-card'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['limit-status-card__content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['limit-status-card__heading'])
                    }, _toDisplayString(_unref(heading)), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['limit-status-card__sub-heading'])
                    }, _toDisplayString(_unref(subHeading)), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['limit-status-card__buttons'])
                    }, [
                        _ctx.isLimitActive ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _withDirectives(_createVNode(VButton, _mergeProps(_unref(buttonPrimaryProperties), {
                                label: _ctx.$t('WEB2_RESPONSIBLE_GAMING_LIMIT_CHANGE_LABEL'),
                                onClick: changeLimit
                            }), null, 16, [
                                "label"
                            ]), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'button-change-limit'
                                    }
                                ]
                            ]),
                            _withDirectives(_createVNode(VButton, _mergeProps(_unref(buttonSecondaryProperties), {
                                label: _ctx.$t('WEB2_RESPONSIBLE_GAMING_LIMIT_RESET_LABEL'),
                                onClick: resetLimit
                            }), null, 16, [
                                "label"
                            ]), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'button-reset-limit'
                                    }
                                ]
                            ])
                        ], 64)) : _withDirectives((_openBlock(), _createBlock(VButton, _mergeProps({
                            key: 0
                        }, _unref(buttonPrimaryProperties), {
                            label: _ctx.$t('WEB2_RESPONSIBLE_GAMING_LIMIT_SET'),
                            onClick: setLimit
                        }), null, 16, [
                            "label"
                        ])), [
                            [
                                _directive_data_test,
                                {
                                    el: 'button-set-limit'
                                }
                            ]
                        ])
                    ], 2)
                ], 2),
                _createVNode(VCircularProgress, _normalizeProps(_guardReactiveProps(_unref(progressProperties))), {
                    default: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['limit-status-card__progress-content'])
                            }, [
                                _ctx.isLimitActive && !_ctx.isTimer ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['limit-status-card__progress-content-top-text'])
                                }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_RESPONSIBLE_GAMING_LIMIT_LEFT_FROM_LIMIT')), 1)
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'div-with-progress-content-top-text'
                                        }
                                    ]
                                ]) : _createCommentVNode("", true),
                                _withDirectives((_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['limit-status-card__progress-content-main-text']]: true,
                                        [_ctx.$style['limit-status-card__progress-content-main-text--disabled']]: !_ctx.isLimitActive
                                    })
                                }, [
                                    _createTextVNode(_toDisplayString(_unref(progressText)), 1)
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'div-with-progress-text'
                                        }
                                    ]
                                ])
                            ], 2)
                        ]),
                    _: 1
                }, 16)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LimitStatusCard'
                ]
            ]);
        };
    }
});
