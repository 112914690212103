import { watchEffect, ref, computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useResponsibleGamblingTimerTime(props) {
    const { $translate } = useI18n();
    const isActive = ref(false);
    const valueWatched = computed(()=>props.value ?? '');
    const label = computed(()=>props.isMinutes ? $translate('WEB2_RESPONSIBLE_GAMBLING_TIMER_MINUTES').value : $translate('WEB2_RESPONSIBLE_GAMBLING_TIMER_HOURS').value);
    watchEffect(()=>{
        if (valueWatched.value) isActive.value = '00' !== valueWatched.value;
    });
    return {
        label,
        valueWatched,
        isActive
    };
}
