import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
import { IconName } from '@leon-hub/icons';
import VRadio from 'web/src/components/Radio/VRadio/VRadio.vue';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import SList from 'web/src/components/List/SList/SList.vue';
import SListItemAccordion from 'web/src/components/List/SListItemAccordion/SListItemAccordion.vue';
import SListItem from 'web/src/components/List/SListItem/SListItem.vue';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import CustomerSubscriptionsConfirmEmailModal from 'web/src/modules/profile/pages/CustomerSubscriptionsRoutePage/components/CustomerSubscriptionsConfirmEmailModal/CustomerSubscriptionsConfirmEmailModal.vue';
import useCustomerSubscriptionsPage from './composables/useCustomerSubscriptionsPage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerSubscriptionsRoutePage',
    setup (__props) {
        const { consents, showConfirmEmailModal, isBetPushesAllowed, isBetOutcomePushEnabled, isEmailFormReady, pushSettings, email, onConsentItemClick, toggleBetOutcomePush, showDisabledBetOutcomePushTooltip, onPushSettingChanged, onConfirmEmailClose, haveValidEmailValue, confirmEmailStage, sendingConfirmEmailLink, onConfirmEmail } = useCustomerSubscriptionsPage();
        const stakePushIcon = IconName.BELL_ON;
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return _openBlock(), _createBlock(VList, {
                key: 1,
                "independent-items": "",
                class: _normalizeClass(_ctx.$style['subscriptions-list'])
            }, {
                default: _withCtx(()=>[
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(consents), (consent)=>(_openBlock(), _createElementBlock(_Fragment, {
                                key: consent.id
                            }, [
                                _withDirectives(_createVNode(VListItem, {
                                    title: consent.name,
                                    "have-switcher": "",
                                    "icon-name": consent.iconName,
                                    "is-switch-checked": consent.isEnabled,
                                    "is-big-margin": "",
                                    onClick: ($event)=>_unref(onConsentItemClick)($event, consent)
                                }, null, 8, [
                                    "title",
                                    "icon-name",
                                    "is-switch-checked",
                                    "onClick"
                                ]), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'subscriptions-list-item',
                                            name: consent.id
                                        }
                                    ]
                                ]),
                                'pushNotification' === consent.id && _unref(isBetPushesAllowed) ? (_openBlock(), _createBlock(VListItemAccordion, {
                                    key: 0,
                                    id: "betPushToggle",
                                    "icon-name": _unref(stakePushIcon),
                                    title: _ctx.$t('WEB2_PUSH_NOTIFICATION_TITLE'),
                                    "is-big-margin": "",
                                    "have-switcher": "",
                                    open: _unref(isBetOutcomePushEnabled),
                                    "is-switch-disabled": !consent.isEnabled,
                                    onClick: _unref(toggleBetOutcomePush),
                                    onClickDisabled: _unref(showDisabledBetOutcomePushTooltip)
                                }, {
                                    content: _withCtx(()=>[
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['subscriptions-list__content'])
                                            }, [
                                                _createElementVNode("div", {
                                                    class: _normalizeClass({
                                                        [_ctx.$style['subscriptions-list__text']]: true,
                                                        [_ctx.$style['subscriptions-list__text--disabled']]: !consent.isEnabled
                                                    })
                                                }, _toDisplayString(_ctx.$t('WEB2_PUSH_NOTIFICATION_TEXT')), 3),
                                                _createElementVNode("form", null, [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pushSettings), (setting)=>(_openBlock(), _createBlock(VRadio, {
                                                            key: setting.id,
                                                            value: setting.id,
                                                            label: setting.name,
                                                            checked: setting.isEnabled,
                                                            disabled: !consent.isEnabled,
                                                            name: "push-settings",
                                                            onChange: ($event)=>_unref(onPushSettingChanged)($event, setting.id)
                                                        }, null, 8, [
                                                            "value",
                                                            "label",
                                                            "checked",
                                                            "disabled",
                                                            "onChange"
                                                        ]))), 128))
                                                ])
                                            ], 2)
                                        ]),
                                    _: 2
                                }, 1032, [
                                    "icon-name",
                                    "title",
                                    "open",
                                    "is-switch-disabled",
                                    "onClick",
                                    "onClickDisabled"
                                ])) : _createCommentVNode("", true)
                            ], 64))), 128)),
                        _createVNode(CustomerSubscriptionsConfirmEmailModal, {
                            "show-confirm-email-modal": _unref(showConfirmEmailModal),
                            "confirm-email-stage": _unref(confirmEmailStage),
                            email: _unref(email),
                            "sending-confirm-email-link": _unref(sendingConfirmEmailLink),
                            "confirm-disabled": !_unref(haveValidEmailValue) && !_unref(isEmailFormReady),
                            onOnConfirmEmailClose: _unref(onConfirmEmailClose),
                            onOnConfirmEmail: _unref(onConfirmEmail)
                        }, null, 8, [
                            "show-confirm-email-modal",
                            "confirm-email-stage",
                            "email",
                            "sending-confirm-email-link",
                            "confirm-disabled",
                            "onOnConfirmEmailClose",
                            "onOnConfirmEmail"
                        ])
                    ]),
                _: 1
            }, 8, [
                "class"
            ]);
        };
    }
});
