import { ref, computed } from 'vue';
import { useTheme } from 'web/src/modules/theme/composables';
// eslint-disable-next-line max-len
export default function useCustomerHistoryDetailsCardStatistics() {
    const { isDark } = useTheme();
    const isOpen = ref(false);
    function toggleStatistics() {
        isOpen.value = !isOpen.value;
    }
    const folder = computed(()=>isDark.value ? 'dark' : 'light');
    return {
        isOpen,
        folder,
        toggleStatistics
    };
}
