import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { isOptionalString } from '@leon-hub/guards';
import { BetLine, OutcomeState, TransactionGroup } from '@leon-hub/api-sdk';
import { useCustomerHistoryStore } from 'web/src/modules/profile/submodules/customer-history/store';
import { useI18n } from 'web/src/modules/i18n/composables';
function isExtendedAccountBetHistoryItem(item) {
    return !!item && item.objectType === TransactionGroup.BET;
}
export default function useCustomerHistoryTopBarRouteComponent() {
    const { $translate } = useI18n();
    const router = useRouter();
    const selectedHistoryItem = toRef(useCustomerHistoryStore(), 'selectedHistoryItem');
    const group = computed(()=>{
        const groupParam = router.getParam('group');
        isOptionalString(groupParam);
        return groupParam?.toUpperCase();
    });
    const title = computed(()=>{
        switch(group.value){
            case TransactionGroup.BET:
                return $translate('WEB2_BET_DETAILS').value;
            case TransactionGroup.PAYMENT:
            case TransactionGroup.EGS:
            case TransactionGroup.EGSOW:
            case TransactionGroup.MG:
            default:
                return $translate('WEB2_TRANSACTION_DETAILS').value;
        }
    });
    const betHistoryItem = computed(()=>isExtendedAccountBetHistoryItem(selectedHistoryItem.value) ? selectedHistoryItem.value : void 0);
    const sportEventId = computed(()=>String(betHistoryItem.value?.eventUrl?.eventId));
    const showButtonSubscription = computed(()=>!!betHistoryItem.value && !!sportEventId.value && betHistoryItem.value?.betLine === BetLine.PREMATCH && betHistoryItem.value?.outcomeState === OutcomeState.PRE_GAME);
    const showSuffix = computed(()=>group.value === TransactionGroup.BET && showButtonSubscription.value);
    return {
        title,
        sportEventId,
        showSuffix
    };
}
