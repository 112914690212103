import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "bonus-card-progress"
};
const _hoisted_2 = {
    class: "bonus-card-progress__step-name"
};
const _hoisted_3 = {
    class: "bonus-card-progress__current"
};
const _hoisted_4 = {
    class: "bonus-card-progress__total"
};
import ProfileBonusesCardTimer from 'web/src/modules/profile/submodules/bonuses/components/ProfileBonusesCardTimer/ProfileBonusesCardTimer.vue';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import { ProgressKind } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import { useProfileBonusesCardProgress } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesCardProgress',
    props: {
        stepName: {},
        value: {
            default: 0
        },
        kind: {
            default: ProgressKind.DEFAULT
        },
        expirationDate: {},
        current: {
            default: '0'
        },
        total: {
            default: '0'
        }
    },
    emits: [
        "timer-end"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { progressProps } = useProfileBonusesCardProgress(props);
        function emitTimerEnd() {
            emit('timer-end');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.stepName), 1),
                _createVNode(VLinearProgress, _mergeProps({
                    class: "bonus-card-progress__progress"
                }, _unref(progressProps)), null, 16),
                _createVNode(ProfileBonusesCardTimer, {
                    class: "bonus-card-progress__timer",
                    "expiration-date": _ctx.expirationDate,
                    onTimerEnd: emitTimerEnd
                }, {
                    progress: _withCtx(()=>[
                            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.current), 1),
                            _cache[0] || (_cache[0] = _createElementVNode("span", {
                                class: "bonus-card-progress__separator"
                            }, "/", -1)),
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.total), 1)
                        ]),
                    _: 1
                }, 8, [
                    "expiration-date"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'ProfileBonusesCardProgress'
                ]
            ]);
        };
    }
});
