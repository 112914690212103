import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, mergeProps as _mergeProps, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import { useResponsibleGamblingQuizProgress } from './composables';
import { ResponsibleGamblingQuizResultsKind } from '../../enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingQuizProgress',
    props: {
        kind: {
            default: ResponsibleGamblingQuizResultsKind.DEFAULT
        },
        value: {},
        of: {},
        noShadow: {
            type: Boolean
        },
        hasBottomLabel: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { progressProps, bottomLabel } = useResponsibleGamblingQuizProgress(props);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['responsible-gambling-quiz-progress'])
            }, [
                _ctx.$slots.topLabel ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['responsible-gambling-quiz-progress__top-label'])
                }, [
                    _renderSlot(_ctx.$slots, "topLabel")
                ], 2)) : _createCommentVNode("", true),
                (_openBlock(), _createBlock(VLinearProgress, _mergeProps({
                    key: 1
                }, _unref(progressProps), {
                    class: _ctx.$style['responsible-gambling-quiz-progress__progress']
                }), null, 16, [
                    "class"
                ])),
                _ctx.hasBottomLabel ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass({
                        [_ctx.$style['responsible-gambling-quiz-progress__bottom-label']]: true,
                        // eslint-disable-next-line max-len
                        [_ctx.$style[`responsible-gambling-quiz-progress__bottom-label--${_ctx.kind}`]]: _unref(ResponsibleGamblingQuizResultsKind).DEFAULT
                    })
                }, [
                    _createTextVNode(_toDisplayString(_unref(bottomLabel)), 1)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'responsible-gambling-quiz-progress__bottom-label'
                        }
                    ]
                ]) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingQuizProgress'
                ]
            ]);
        };
    }
});
