import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "profile-bonuses-controller__freebet-status"
};
import ProfileBonusesCardLayout from 'web/src/modules/profile/submodules/bonuses/components/ProfileBonusesCardLayout/ProfileBonusesCardLayout.vue';
import ProfileBonusesCardTimer from 'web/src/modules/profile/submodules/bonuses/components/ProfileBonusesCardTimer/ProfileBonusesCardTimer.vue';
import VStatus from 'web/src/components/Status/VStatus/VStatus.vue';
import ProfileBonusesCardButtonController from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesCardButtonController/ProfileBonusesCardButtonController.vue';
import { useProfileBonusesFreebetController } from 'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesFreebetController/composables';
import ProfileBonusesCardDescriptionColumns from '../../components/ProfileBonusesCardDescriptionColumns/ProfileBonusesCardDescriptionColumns.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesFreebetController',
    props: {
        bonus: {},
        activeButton: {}
    },
    emits: [
        "refuse",
        "collect",
        "timer-end"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { availableBefore, balanceProperties, bonusButtons, isFreebetInProgress, layoutProperties, infoStatusType, emitTimerEnd, handleButtonClick } = useProfileBonusesFreebetController(props, emit);
        return (_ctx, _cache)=>{
            const _directive_collect = _resolveDirective("collect");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ProfileBonusesCardLayout, _normalizeProps(_guardReactiveProps(_unref(layoutProperties))), {
                default: _withCtx(()=>[
                        _createVNode(ProfileBonusesCardDescriptionColumns, _normalizeProps(_guardReactiveProps(_unref(balanceProperties))), null, 16),
                        _createVNode(ProfileBonusesCardTimer, {
                            "expiration-date": _unref(availableBefore),
                            onTimerEnd: _unref(emitTimerEnd)
                        }, null, 8, [
                            "expiration-date",
                            "onTimerEnd"
                        ]),
                        _unref(isFreebetInProgress) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _withDirectives(_createVNode(VStatus, {
                                text: _ctx.$t('WEB2_BONUSES_FREEBET_IN_PROGRESS')
                            }, null, 8, [
                                "text"
                            ]), [
                                [
                                    _directive_collect,
                                    {
                                        bonuses: {
                                            disableButtons: _unref(infoStatusType)
                                        }
                                    },
                                    "click-counter"
                                ]
                            ])
                        ])) : _createCommentVNode("", true),
                        _createVNode(ProfileBonusesCardButtonController, {
                            buttons: _unref(bonusButtons),
                            "has-columns": _unref(bonusButtons).length > 1,
                            onClick: _unref(handleButtonClick)
                        }, null, 8, [
                            "buttons",
                            "has-columns",
                            "onClick"
                        ])
                    ]),
                _: 1
            }, 16)), [
                [
                    _directive_auto_id,
                    'ProfileBonusesFreebetController'
                ]
            ]);
        };
    }
});
