import { toRef, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import useOnboarding from 'web/src/modules/onboarding/pages/composables/useOnboading';
import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';
import useResponsibleGamblingStoreV2 from 'web/src/modules/profile/submodules/responsible-gambling/store/useResponsibleGamblingStoreV2';
import { useNavigationItemsStore } from 'web/src/modules/core/store';
export default function useResponsibleGamblingOptionsRoutePage() {
    const router = useRouter();
    const responsibleGamblingItems = toRef(useNavigationItemsStore(), 'responsibleGamblingItems');
    const responsibleGamblingStoreV2 = useResponsibleGamblingStoreV2();
    const { getOnboardingById } = useOnboarding();
    const customerDataStore = useCustomerDataStore();
    const customerData = useCustomerDataStore();
    const isResponsibleGamblingOnboardingShown = toRef(customerDataStore, 'isResponsibleGamblingOnboardingShown');
    const fullName = toRef(customerData, 'fullName');
    const isOnboardingOpened = ref(false);
    const hasOnboarding = computed(()=>!!getOnboardingById(OnboardingIdsEnum.RESPONSIBLE_GAMBLING));
    function autoOpenOnboarding() {
        const { hideResponsibleGamblingOnBoardingPage } = responsibleGamblingStoreV2;
        if (!isResponsibleGamblingOnboardingShown.value && !hideResponsibleGamblingOnBoardingPage && hasOnboarding && !isOnboardingOpened.value) {
            router.push({
                name: RouteName.ONBOARDING,
                params: {
                    id: OnboardingIdsEnum.RESPONSIBLE_GAMBLING
                }
            });
            responsibleGamblingStoreV2.setResponsibleGamblingHideOnBoardingPageState(true);
            isOnboardingOpened.value = true;
        }
    }
    function beforeMount() {
        autoOpenOnboarding();
    }
    function onButtonClick() {
        router.push({
            name: RouteName.HOME
        });
    }
    return {
        fullName,
        responsibleGamblingItems,
        onButtonClick,
        beforeMount
    };
}
