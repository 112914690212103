import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "rg-bet-limit-info"
};
const _hoisted_2 = {
    class: "rg-bet-limit-info_content"
};
const _hoisted_3 = {
    class: "rg-bet-limit-info_buttons"
};
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingDepositLimitInfoRoutePage',
    setup (__props) {
        const router = useRouter();
        const handleBack = ()=>{
            router.push({
                name: RouteName.RESPONSIBLE_GAMBLING_DEPOSIT_LIMIT
            });
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(VCmsContent, {
                        "cms-key": "WEB2_RG_DEPOSIT_INFO"
                    })
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(VButton, {
                        label: _ctx.$t('WEB2_RG_INFO_BUTTON'),
                        "full-width": "",
                        onClick: handleBack
                    }, null, 8, [
                        "label"
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingDepositLimitInfoRoutePage'
                ]
            ]);
        };
    }
});
