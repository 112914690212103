import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import ResponsibleGamblingTimer from 'web/src/modules/profile/submodules/responsible-gambling/components/ResponsibleGamblingTimer/ResponsibleGamblingTimer.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import { useResponsibleGamblingTimeInGameTimerStep } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingTimeInGameTimerStep',
    props: {
        timerData: {}
    },
    emits: [
        "set",
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { responsibleGamblingTimerProps, buttonSetProps, buttonCancelProps, handleChange, setTimer, closeTimer } = useResponsibleGamblingTimeInGameTimerStep(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['timer-step'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['timer-step-title'])
                }, _toDisplayString(_ctx.$t('RG_TIME_IN_GAME_TIMER_STEP_DESCRIPTION')), 3),
                _createVNode(ResponsibleGamblingTimer, _mergeProps({
                    class: _ctx.$style['timer-step-container']
                }, _unref(responsibleGamblingTimerProps), {
                    onChange: _unref(handleChange)
                }), null, 16, [
                    "class",
                    "onChange"
                ]),
                _createVNode(VButton, _mergeProps({
                    class: _ctx.$style['timer-step-button__set']
                }, _unref(buttonSetProps), {
                    onClick: _unref(setTimer)
                }), null, 16, [
                    "class",
                    "onClick"
                ]),
                (_openBlock(), _createBlock(LButton, _mergeProps({
                    key: 1,
                    class: _ctx.$style['timer-step-button__cancel']
                }, _unref(buttonCancelProps), {
                    onClick: _unref(closeTimer)
                }), null, 16, [
                    "class",
                    "onClick"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingTimeInGameTimerStep'
                ]
            ]);
        };
    }
});
