import RouteName from '@leon-hub/routing-config';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSiteConfigStore } from 'web/src/modules/core/store';
let ResponsibleGamblingTimeInGameRoutePagePrefetch = class ResponsibleGamblingTimeInGameRoutePagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        if (!useSiteConfigStore().isPlayTimeControlEnabled) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return Promise.resolve();
        }
        next();
        return Promise.resolve();
    }
};
export { ResponsibleGamblingTimeInGameRoutePagePrefetch as default };
