import { useI18n } from 'web/src/modules/i18n/composables';
export default function getStepQuestion(stepNumber) {
    const { $translate } = useI18n();
    switch(stepNumber){
        case 2:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_QUESTION_2').value;
        case 3:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_QUESTION_3').value;
        case 4:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_QUESTION_4').value;
        case 5:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_QUESTION_5').value;
        case 6:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_QUESTION_6').value;
        case 7:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_QUESTION_7').value;
        case 8:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_QUESTION_8').value;
        case 9:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_QUESTION_9').value;
        case 1:
        default:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_QUIZ_QUESTION_1').value;
    }
}
