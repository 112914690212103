import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { IconSize, IconName } from '@leon-hub/icons';
import { VideoVerificationStatus } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config';
import { VIcon } from '@components/v-icon';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useVideoVerificationStore } from 'web/src/modules/verification/submodules/video-verification/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerVerificationTopBarRouteComponent',
    setup (__props) {
        const { $translate } = useI18n();
        const router = useRouter();
        const customerDataStore = useCustomerDataStore();
        const videoVerificationStatus = toRef(customerDataStore, 'videoVerificationStatus');
        const isVideoVerificationOnboardingShown = toRef(customerDataStore, 'isVideoVerificationOnboardingShown');
        const isShowHintVideoVerificationProfile = toRef(useVideoVerificationStore(), 'isShowHintVideoVerificationProfile');
        const isShowTitle = computed(()=>!(videoVerificationStatus.value === VideoVerificationStatus.SCHEDULED || videoVerificationStatus.value === VideoVerificationStatus.ON_REVIEW || videoVerificationStatus.value === VideoVerificationStatus.PASSED || videoVerificationStatus.value === VideoVerificationStatus.NOT_PASSED || videoVerificationStatus.value === VideoVerificationStatus.REFUSED || videoVerificationStatus.value === VideoVerificationStatus.ACCEPT_REFUSAL));
        const title = computed(()=>isShowTitle.value ? $translate('WEB2_VERIFY_PAGE_TITLE').value : '');
        function onClickPrefix() {
            router.back();
        }
        function onClickSuffix() {
            router.push({
                name: RouteName.SUPPORT
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: title.value
            }, {
                prefix: _withCtx(()=>[
                        isShowTitle.value ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            (_openBlock(), _createBlock(VButton, {
                                key: 1,
                                "icon-name": _unref(IconName).ARROW_LEFT,
                                "icon-size": _unref(IconSize).SIZE_24,
                                kind: _unref(ButtonKind).TRANSPARENT,
                                rounded: "",
                                onClick: onClickPrefix
                            }, null, 8, [
                                "icon-name",
                                "icon-size",
                                "kind"
                            ]))
                        ], 64)) : _createCommentVNode("", true)
                    ]),
                suffix: _withCtx(()=>[
                        !isVideoVerificationOnboardingShown.value && isShowHintVideoVerificationProfile.value ? (_openBlock(), _createBlock(VButton, {
                            key: 0,
                            "icon-name": _unref(IconName).QUESTION_OUTLINE,
                            "icon-size": _unref(IconSize).SIZE_24,
                            kind: _unref(ButtonKind).TRANSPARENT,
                            rounded: "",
                            onClick: onClickSuffix
                        }, null, 8, [
                            "icon-name",
                            "icon-size",
                            "kind"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "title"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerVerificationTopBarRouteComponent'
                ]
            ]);
        };
    }
});
