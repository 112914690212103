import { ref, computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { BonusCampaignType } from '@leon-hub/api-sdk/src/sdk/sdk';
import useOnboarding from 'web/src/modules/onboarding/pages/composables/useOnboading';
import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useProfileBonusesStore } from 'web/src/modules/profile/submodules/bonuses/store';
import { ProfileButtonsTab } from 'web/src/modules/profile/submodules/bonuses/enums';
// eslint-disable-next-line max-len
export default function useProfileBonusesTopBarRouteComponent(props) {
    const { $translate } = useI18n();
    const router = useRouter();
    const onboarding = useOnboarding();
    const profileBonusesStore = useProfileBonusesStore();
    const onboardingOpened = ref(false);
    const complexData = toRef(profileBonusesStore, 'complexData');
    const isOnboardingIconShown = toRef(profileBonusesStore, 'isOnboardingIconShown');
    const activeTab = toRef(profileBonusesStore, 'activeTab');
    const waitingRefreshDelay = toRef(profileBonusesStore, 'waitingRefreshDelay');
    const topBarTitle = computed(()=>{
        if (complexData.value) return $translate('WEB2_BONUSES_COMPLEX_CHOOSE_BONUS').value;
        return props.title || '';
    });
    const hasBonusesOnboarding = computed(()=>{
        const hasSlides = onboarding.getOnboardingById(OnboardingIdsEnum.BONUSES);
        return !complexData.value && !!hasSlides && isOnboardingIconShown.value;
    });
    function handleOnboardingButtonClick() {
        router.push({
            name: RouteName.ONBOARDING,
            params: {
                id: OnboardingIdsEnum.BONUSES
            }
        });
        onboardingOpened.value = true;
    }
    function handleComplexDataButtonClick() {
        profileBonusesStore.setComplexData(null);
    }
    function handleBackTitle() {
        onboardingOpened.value = false;
    }
    function handleCloseButtonClick() {
        if (!isOnboardingIconShown.value) profileBonusesStore.switchOnboardingTopBarIcon(true);
        router.closeModal();
    }
    const switcherOptions = computed(()=>{
        const options = [];
        "1";
        options.push({
            id: ProfileButtonsTab.SPORTS,
            label: $translate('BONUS_CAMPAIGN_TYPE_SPORT_KEY').value
        });
        "1";
        options.push({
            id: ProfileButtonsTab.EGS,
            label: $translate('BONUS_CAMPAIGN_TYPE_CASINO_KEY').value
        });
        return options;
    });
    async function fetchCurrentBonuses() {
        if (!waitingRefreshDelay.value) {
            let type;
            switch(activeTab.value){
                case ProfileButtonsTab.SPORTS:
                    type = BonusCampaignType.SPORT;
                    break;
                case ProfileButtonsTab.EGS:
                    type = BonusCampaignType.EGS;
                    break;
                default:
                    type = void 0;
                    break;
            }
            await profileBonusesStore.fetchBonuses(type);
        }
    }
    function switchTab(index) {
        profileBonusesStore.setActiveTab(index);
        fetchCurrentBonuses();
    }
    return {
        onboardingOpened,
        topBarTitle,
        hasBonusesOnboarding,
        complexData,
        handleOnboardingButtonClick,
        handleComplexDataButtonClick,
        handleBackTitle,
        handleCloseButtonClick,
        switcherOptions,
        activeTab,
        switchTab
    };
}
