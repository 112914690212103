import { useI18n } from 'web/src/modules/i18n/composables';
import { ResponsibleGamblingQuizResultCategory } from '../../types';
export default function getCategoryTitle(category) {
    const { $translate } = useI18n();
    switch(category){
        case ResponsibleGamblingQuizResultCategory.TIME:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_CATEGORY_TITLE_TIME').value;
        case ResponsibleGamblingQuizResultCategory.FINANCE:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_CATEGORY_TITLE_FINANCE').value;
        case ResponsibleGamblingQuizResultCategory.RELATIONSHIPS:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_CATEGORY_TITLE_RELATIONSHIP').value;
        case ResponsibleGamblingQuizResultCategory.HEALTH:
        default:
            return $translate('WEB2_RESPONSIBLE_GAMBLING_RESULT_CATEGORY_TITLE_HEALTH').value;
    }
}
