import { ref } from 'vue';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { getRandomGameId } from 'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesFreespinController/composables/utils';
import useEgsNavigation from 'web/src/modules/egs/submodules/navigation/composable/useEgsNavigation';
export default function useNavigationToGameDetails(router) {
    const { navigateToGamePageById } = useEgsNavigation(router);
    const isGameDetailsLoading = ref(false);
    const routeToGameDetails = (bonus)=>{
        if (bonus.freespin?.gameIds) {
            const { gameIds } = bonus.freespin;
            const gameId = getRandomGameId(gameIds);
            isGameDetailsLoading.value = true;
            navigateToGamePageById(gameId).catch(()=>{
                router.push({
                    name: CasinoRouteName.CASINO_LOBBY
                });
            }).finally(()=>{
                isGameDetailsLoading.value = false;
            });
        }
    };
    return {
        routeToGameDetails,
        isGameDetailsLoading
    };
}
